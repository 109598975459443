<template>
  <div>
    <div
      v-if="component === null"
      class="columns is-multiline is-mobile"
    >
      <div :class="[$style.head, 'column is-12 is-flex is-align-items-center']">
        <a
          :class="$style.btn_skip"
          @click="skipCreation"
        >
          Skip delivery creation
        </a>
        <b-input
          ref="control"
          v-model="searchString"
          :class="$style.search"
          icon="magnifying-glass"
          placeholder="Search"
        />
      </div>

      <div
        v-for="method in componentsBySearchString"
        :key="method.title"
        class="column is-12-mobile is-6-tablet is-6-desktop is-4-fullhd"
        @click="selectDeliveryMethod(method.settings)"
      >
        <div :class="[$style.card, 'card content']">
          <a
            v-if="method.settings.propsData.beta === true"
            class="card-header-icon is-pulled-right"
          >
            <span class="tag is-danger">Beta</span>
          </a>
          <div class="card-content">
            <p class="has-text-centered is-size-4 has-text-weight-bold">
              {{ method.title }}
            </p>
            <div class="level is-mobile">
              <span class="icon is-large level-item">
                <Icon
                  :name="method.settings.propsData.icon"
                  :width="iconWidth(method.settings.propsData.icon)"
                />
              </span>
            </div>
            <div :class="[$style.description, 'has-text-centered']">
              {{ method.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <component
      :is="component.type"
      v-else
      ref="delivery"
      :process-id="processId"
      v-bind="component.propsData"
      @cancel="selectDeliveryMethod"
      @save="createStep"
      @submit="createAndNext"
    />
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useProcessesStore, useProcessStepsStore } from '@/stores/index.js';
import Icon from '@/components/icon.vue';
import { searchWith } from '@/utils/index.js';
// import { component as emailComponent } from './email/email-delivery-step-options.js';
import { component as oneDriveComponent } from './onedrive/onedrive-delivery-step-options.js';
import { component as dropboxComponent } from './dropbox/dropbox-delivery-step-options.js';
import { component as sharePointComponent } from './sharepoint/sharepoint-delivery-step-options.js';
import { component as webhookComponent } from './webhook/webhook-delivery-step-options.js';
import { component as googleDriveComponent } from './googledrive/googledrive-delivery-step-options.js';
import { component as docuSignComponent } from './docusign/docusign-delivery-step-options.js';
import { component as xodoSignComponent } from './xodosign/xodosign-delivery-step-options.js';
import { component as signNowComponent } from './signnow/signnow-delivery-step-options.js';
import { component as dropboxSignComponent } from './dropboxsign/dropboxsign-delivery-step-options.js';
import { component as adobeSignComponent } from './adobesign/adobesign-delivery-step-options.js';
import { component as gmailComponent } from './gmail/gmail-delivery-step-options.js';
import { component as outlookComponent } from './outlook/outlook-delivery-step-options.js';
import { component as boxComponent } from './box/box-delivery-step-options.js';
import { component as slackComponent } from './slack/slack-delivery-step-options.js';
import { component as teamsComponent } from './MicrosoftTeams/teams-delivery-step-options';
import { component as zohoSignComponent } from './zohosign/zohosign-delivery-step-options.js';
import { component as emailSmtpComponent } from './email-smtp/email-smtp-delivery-step-options.js';
import { component as zohoMailComponent } from './zohomail/zohomail-delivery-step-options.js';
import { component as amazonEmailComponent } from './amazon-email/amazon-email-delivery-step-options.js';

import EmailDeliveryStep from './email/email-delivery-step.vue';
import OnedriveDeliveryStep from './onedrive/onedrive-delivery-step.vue';
import DropboxDeliveryStep from './dropbox/dropbox-delivery-step.vue';
import SharepointDeliveryStep from './sharepoint/sharepoint-delivery-step.vue';
import WebhookDeliveryStep from './webhook/webhook-delivery-step.vue';
import GoogledriveDeliveryStep from './googledrive/googledrive-delivery-step.vue';
import DocusignDeliveryStep from './docusign/docusign-delivery-step.vue';
import XodoSignDeliveryStep from './xodosign/xodosign-delivery-step.vue';
import SignNowDeliveryStep from './signnow/signnow-delivery-step.vue';
import DropboxSignDeliveryStep from './dropboxsign/dropboxsign-delivery-step.vue';
import AdobeSignDeliveryStep from './adobesign/adobesign-delivety-step.vue';
import GmailDeliveryStep from './gmail/gmail-delivery-step.vue';
import OutlookDeliveryStep from './outlook/outlook-delivery-step.vue';
import BoxDeliveryStep from './box/box-delivery-step.vue';
import SlackDeliveryStep from './slack/slack-delivery-step.vue';
import TeamsDeliveryStep from './MicrosoftTeams/teams-delivery-step.vue';
import ZohoSignDeliveryStep from './zohosign/zohosign-delivery-step.vue';
import EmailSmtpDeliveryStep from './email-smtp/email-smtp-delivery-step.vue';
import ZohoMailDeliveryStep from './zohomail/zohomail-delivery-step.vue';
import AmazonEmailDeliveryStep from './amazon-email/amazon-email-delivery-step.vue';

export default {
  name: 'CreateDelivery',
  components: {
    'email-delivery-step': EmailDeliveryStep,
    'email-smtp-delivery-step': EmailSmtpDeliveryStep,
    'amazon-email-delivery-step': AmazonEmailDeliveryStep,
    'onedrive-delivery-step': OnedriveDeliveryStep,
    'dropbox-delivery-step': DropboxDeliveryStep,
    'sharepoint-delivery-step': SharepointDeliveryStep,
    'webhook-delivery-step': WebhookDeliveryStep,
    'googledrive-delivery-step': GoogledriveDeliveryStep,
    'docusign-delivery-step': DocusignDeliveryStep,
    'xodosign-delivery-step': XodoSignDeliveryStep,
    'signnow-delivery-step': SignNowDeliveryStep,
    'dropboxsign-delivery-step': DropboxSignDeliveryStep,
    'adobesign-delivery-step': AdobeSignDeliveryStep,
    'gmail-delivery-step': GmailDeliveryStep,
    'outlook-delivery-step': OutlookDeliveryStep,
    'box-delivery-step': BoxDeliveryStep,
    'slack-delivery-step': SlackDeliveryStep,
    'teams-delivery-step': TeamsDeliveryStep,
    'zohosign-delivery-step': ZohoSignDeliveryStep,
    'zohomail-delivery-step': ZohoMailDeliveryStep,
    Icon,
  },

  data() {
    return {
      component: null,
      components: [
        /* emailComponent, */
        emailSmtpComponent, gmailComponent, outlookComponent, zohoMailComponent,
        amazonEmailComponent, sharePointComponent, dropboxComponent, googleDriveComponent,
        oneDriveComponent, boxComponent, docuSignComponent, signNowComponent,
        xodoSignComponent, dropboxSignComponent, adobeSignComponent, zohoSignComponent,
        slackComponent, teamsComponent, webhookComponent,
      ],
      searchString: '',
    };
  },

  computed: {
    ...mapState(useProcessesStore, ['processId']),
    ...mapWritableState(useProcessesStore, ['processIsEdited']),

    componentsBySearchString() {
      const searchByFields = ({
        settings: { propsData: { name } },
        title,
      }) => ({
        title,
        name,
      });

      return searchWith(this.searchString)(searchByFields)(this.components);
    },
  },

  methods: {
    ...mapActions(useProcessStepsStore, ['cancelCreation', 'navigateNext']),

    selectDeliveryMethod(settings = null) {
      this.processIsEdited = !!settings;
      this.component = settings;
    },

    createStep(event) {
      this.$emit('creationCompleted', { type: event.type, payload: event });
    },

    createAndNext(event) {
      this.createStep(event);
      this.navigateNext();
    },

    skipCreation() {
      this.cancelCreation();
    },
    iconWidth(iconName) {
      return iconName === 'service-slack' ? 108 : 48;
    },

    createBeforeLeave() {
      return this.$refs.delivery?.save();
    },
  },
};
</script>

<style lang="scss" module>
.card {
  height: 100%;
  cursor: pointer;
}

.description {
  overflow: hidden;
  line-height: 1.5;
  white-space: normal;
  text-overflow: ellipsis;
}

.btn_skip {
  margin-right: auto;
}

.search {
  margin-left: 10px;
}

@media (max-width: 576px) {
  .head {
    flex-wrap: wrap
  }
  .search {
    margin-left: 0;
    margin-top: 10px;
    flex-basis: 100%;
  }
}
</style>
