export const type = 'Microsoft Teams';
export const name = 'Send Teams message';
export const headerName = 'Send Microsoft Teams message';
export const icon = 'service-microsoft-teams';
export const description = 'Send the result document as an attachment by Microsoft Teams.';
export const fullDescription = 'Send the result document as an attachment by Microsoft Teams. Learn more in the <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/teams.html" target="_blank">the documentation.</a>';
export const name_width = 220;

export const options = {
  type, name, icon, description, fullDescription, name_width, headerName,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: 'teams-delivery-step',
    propsData: options,
  },
};
