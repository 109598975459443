export const componentName = 'webhook-delivery-step';
export const type = 'Webhook';
export const name = 'Save to Webhook';
export const icon = 'service-webhook';
export const description = 'Send the result document as POST request.';
export const fullDescription = 'Send the result document and input data as POST request. Learn request structure in the <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/webhook.html" target="_blank">documentation.</a>';

export const options = {
  type, name, icon, description, fullDescription,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: componentName,
    propsData: options,
  },
};
