export const type = 'Zoho Mail';
export const name = 'Zoho Mail';
export const headerName = 'Send using Zoho Mail';
export const icon = 'service-zohomail';
export const description = 'Send the resulting document as an email attachment using Zoho Mail.';
export const fullDescription = 'Send the resulting document as an email attachment using Zoho Mail. Learn more in <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/zoho-mail.html" target="_blank">the documentation.</a>';

export const options = {
  type, name, icon, description, fullDescription, headerName,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: 'zohomail-delivery-step',
    propsData: options,
  },
};
