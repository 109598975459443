const fillFields = (f, fk, fd) => {
  fk.filter((k) => f[k].type === 'field').forEach((key) => {
    if (fd.field(key)) {
      fd.field(key).value = f[key].value;
    }
  });
};

const fillControls = (f, fk, fd) => {
  const controls = fk.filter((k) => f[k].type === 'control');

  fd.controls().forEach((control) => {
    if (controls.includes(control.internalName) || f[control.internalName]) {
      const savedControlValue = f[control.internalName].value;
      const rows = [];
      savedControlValue.forEach((item) => {
        const row = {};
        const keys = Object.keys(item);
        control.columns.forEach((column) => {
          keys.forEach((key) => {
            if (column.field === key) {
              row[column.field] = item[column.field]
            }
          });

        });
        rows.push(row)
      });

      if (rows.length > 0 && fd.control(control.internalName).value.length === 0) {
        fd.control(control.internalName).value = rows;
      }}
  });
};

export default {
  computed: {
    storageKey() {
      return `test_data_${this.fd.formId}`;
    },
  },
  methods: {
    fillFormWithTestData() {
      const form = JSON.parse(localStorage.getItem(this.storageKey));
      if (form) {
        const formKeys = Object.keys(form);

        fillFields(form, formKeys, this.fd);
        fillControls(form, formKeys, this.fd);
      }
    },
    saveFormTestData() {
      const form = {};
      if (!this.fd) return;
      this.fd.fields()?.forEach((f) => {
        form[f.internalName] = { type: 'field', value: f.value };
      });

      this.fd.controls()?.forEach((control) => {
        const { columns } = control;
        if (columns && columns.length > 0 && control.value.length > 0) {
          const controlsDto = [];

          control.value.forEach((val) => {
            const columnsDto = {};
            columns.forEach((column) => {
              if (column.field) {
                columnsDto[column.field] = val[column.field];
              }
            });
            controlsDto.push(columnsDto)
          });

          form[control.internalName] = { type: 'control', value: controlsDto };
        } else if (control.internalName) {
          delete form[control.internalName];
        }
      });

      localStorage.setItem(this.storageKey, JSON.stringify(form));
    },
  },
};
