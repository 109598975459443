export const componentName = 'googledrive-delivery-step';
export const type = 'Google Drive';
export const name = 'Save to Google Drive';
export const icon = 'service-googledrive';
export const description = 'Save the result document to Google Drive folder.';
export const fullDescription = 'Save the result document to Google Drive folder. Learn more in <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/google-drive.html" target="_blank">the documentation.</a>';

export const options = {
  type, name, icon, description, fullDescription,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: componentName,
    propsData: options,
  },
};
