<template>
  <div v-if="$v">
    <template-testing-panel
      :side-panel-name="processTestPanel"
      :file-name="fileName"
      :process-id="processId"
      :google-drive-id="form.googleDriveId"
      :html-source="form.htmlSource"
      :input-type="form.from"
      :need-update="async () => { await parseTokens() }"
      :tags-updated="(tags) => { $emit('set-validate-value', { tags }); }"
    />
    <settings-placeholder v-if="showPlaceholder" />

    <div
      v-if="processMode && !showPlaceholder"
      :class="$style.wrapper"
      style="padding-top: 8px"
    >
      <form class="content">
        <div>
          <bulma-errors
            additional-class="mb-3"
          >
            <bulma-switch
              v-model="processMode"
              :class="$style.mode_switch"
              :captions="['Production', 'Testing']"
              optional-switch-class="is-success"
            >
              Template mode
              <template #caption>
                <span
                  :class="$style.mode_caption"
                  class="has-text-grey"
                >
                  {{ processMode === 'Testing'
                    ? '(Free process runs with watermark inside document)'
                    : '(You will be charged for process runs)' }}
                </span>
              </template>
            </bulma-switch>
          </bulma-errors>

          <bulma-errors
            :enabled="$v.form.$dirty"
            :model="$v.form.outputName"
            :validators="new Map([
              ['required', 'The template\'s output filename cannot be empty'],
            ])"
          >
            <Tokens
              v-model="outputName"
              :top="41"
            >
              <bulma-text-input
                v-model="outputName"
                :error="$v.form.outputName.$error && $v.form.$dirty"
              >
                <span class="level-left">Output filename</span>
              </bulma-text-input>
            </Tokens>
          </bulma-errors>

          <bulma-errors>
            <bulma-select
              v-if="form.from !== 'PDF'"
              v-model="outputType"
              :options="destinationTypes"
            >
              Output type
            </bulma-select>
          </bulma-errors>

          <div
            v-if="form.outputType === 'PDF'"
            class="content"
          >
            <div class="field">
              <a @click="showWatermarkSettingsModal">Add watermark</a>
            </div>

            <div class="field">
              <a @click="showProtectPdfModal">Protect PDF</a>
            </div>
          </div>
        </div>
        <AdvancedSettings
          class="mt-5"
          :btn-color="'black'"
        >
          <div class="columns mb-4">
            <bulma-errors additional-class="column is-6 mb-0">
              <bulma-select
                v-model="timezone"
                :options="timezones"
                option-name="title"
                option-value="name"
              >
                Timezone
              </bulma-select>
            </bulma-errors>

            <bulma-errors additional-class="column is-6">
              <bulma-select
                v-model="currentLocaleTitle"
                :options="localeCodesTitles"
              >
                Locale
              </bulma-select>
            </bulma-errors>
          </div>

          <bulma-errors
            :enabled="$v.form.$dirty"
            :model="$v.form.documentNumber"
            :validators="new Map([
              ['isNumber', 'Enter a numeric value'],
            ])"
          >
            <bulma-text-input
              v-model="documentNumber"
              :error="$v.form.documentNumber.$error && $v.form.$dirty"
              type="number"
            >
              <span class="level-left">Document number index</span>
            </bulma-text-input>
          </bulma-errors>

          <div
            v-if="form.from === 'PDF'"
            class="field mt-5"
          >
            <bulma-switch
              v-model="lockPdfFormFields"
              :captions="[true, false]"
              :show-captions="false"
              :switch-id="'lockPdfFormFields'"
            >
              Lock PDF form fields
            </bulma-switch>
          </div>
        </AdvancedSettings>
      </form>

      <div class="buttons">
        <button
          type="button"
          class="button is-primary"
          :disabled="loading"
          @click="showTemplateTestingPanel"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fal', 'flask']" />
          </span>
          <span>Test template</span>
        </button>

        <save-button
          :optional-btn-class="$v.form.$pending && $v.form.$dirty ? 'is-loading' : null"
          @save-next="submit(true)"
          @save="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import {
  useProcessStepsStore,
  useProcessesStore,
  useSidePanelStore,
  useTokensStore,
} from '@/stores/index.js';

import { localeCodes, localeCodesTitles, getTimezones } from '@/utils/index.js';
import Tokens from '@/components/tokens.vue';
import AdvancedSettings from '@/components/processes/advanced-settings.vue';
import {
  BulmaSwitchComponent,
  BulmaTextInputComponent,
  BulmaSelectComponent,
  BulmaErrorsComponent,
} from '@/components/basic/index.js';
import ProtectPdfModalComponent from './protect-pdf-tooltip.vue';
import WatermarkSettingsModalComponent from './watermark-settings-tooltip.vue';
import SaveButton from '../../components/save-button.vue';
import Confirm from '@/components/modals/confirm.vue';
import SettingsPlaceholder from './settings-placeholder.vue';
import TemplateTestingPanel from './template-testing/template-testing-panel.vue';

export default {
  name: 'ConversionStepSettings',
  components: {
    'bulma-switch': BulmaSwitchComponent,
    'bulma-text-input': BulmaTextInputComponent,
    'bulma-select': BulmaSelectComponent,
    'bulma-errors': BulmaErrorsComponent,
    Tokens,
    AdvancedSettings,
    SaveButton,
    SettingsPlaceholder,
    TemplateTestingPanel,
  },

  props: {
    $v: {
      type: Object,
      required: true,
    },

    processId: {
      type: String,
      required: true,
    },

    form: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      localeCodesTitles,
      showPlaceholder: false,
      placeholderTimeout: null,
      timezones: getTimezones(),
      processTestPanel: 'TemplateTesting',
    };
  },

  computed: {
    ...mapWritableState(useProcessStepsStore, ['tagsBuffer']),
    ...mapWritableState(useProcessesStore, ['docNumber']),
    ...mapState(useTokensStore, ['tokens']),

    fileName() {
      const { outputName } = this.form;
      const outputType = this.form.outputType.toLowerCase();

      return `${outputName}.${outputType}`;
    },

    processMode: {
      get() { return this.form.processMode; },
      set(value) { this.$emit('set-validate-value', { processMode: value }); },
    },

    outputName: {
      get() { return this.form.outputName; },
      set(value) { this.$emit('set-validate-value', { outputName: value }); },
    },

    outputType: {
      get() { return this.form.outputType; },
      set(value) { this.$emit('set-validate-value', { outputType: value }); },
    },

    documentNumber: {
      get() { return this.form.documentNumber; },
      set(value) { this.$emit('set-validate-value', { documentNumber: value }); },
    },

    lockPdfFormFields: {
      get() { return this.form.lockPdfFormFields; },
      set(value) { this.$emit('set-validate-value', { lockPdfFormFields: value }); },
    },

    destinationTypes() {
      return ['PDF', this.form.from];
    },
    // TODO: check this logic
    currentLocaleTitle: {
      get() {
        return localeCodes.find((x) => x.tag === this.form.locale || x['iso639-1'] === this.form.locale)?.title;
      },
      set(value) {
        this.$emit('set-validate-value', { locale: localeCodes.find((x) => x.title === value)?.tag });
      },
    },
    timezone: {
      get() { return this.form.timezone || 'UTC'; },
      set(value) {
        const { name } = this.timezones.find((x) => x.name === value);
        this.$emit('set-validate-value', { timezone: name });
      },
    },
  },

  watch: {
    processMode() {
      if (this.placeholderTimeout) {
        clearTimeout(this.placeholderTimeout);
        this.placeholderTimeout = null;
        this.showPlaceholder = false;
      }
    },
  },

  mounted() {
    if (!this.processMode) {
      this.placeholderTimeout = setTimeout(() => {
        this.showPlaceholder = true;
      }, 700);
    }
    sessionStorage.removeItem(this.processId);
  },

  beforeDestroy() {
    this.resetPanels();
  },

  methods: {
    ...mapActions(useTokensStore, ['updateTokens']),
    ...mapActions(useSidePanelStore, ['resetPanels', 'setPanel']),

    submit(next = false) {
      if (this.$v.form.documentNumber.$dirty && !this.$v.form.documentNumber.$error) {
        // the value should only be sent if it has been manually redefined by the user.
        this.docNumber = this.form.documentNumber || 0;
      }

      this.loading = true;
      if (next) {
        this.$emit('submit-and-next');
        return;
      }
      this.$emit('submit');
      this.loading = false;
    },

    save() {
      this.$emit('save');
      this.$v.form.$reset();
    },

    async showTemplateTestingPanel() {
      if (this.$v.form.$anyDirty) {
        const isConfirm = await new Promise((resolve) => {
          this.$modal.show(
            Confirm,
            {
              title: 'Unsaved changes',
              message: 'We will save changes to the template. Are you sure you want to proceed?',
              onSubmit: () => { resolve(true); },
              submitBtnText: 'Save',
              showCloseBtn: true,
            },
            {
              draggable: '.modal-handler',
              clickToClose: true,
              width: '455px',
              height: 'auto',
              adaptive: true,
            },
            {
              closed: () => { resolve(false); },
            },
          );
        });
        if (!isConfirm) return;
        this.save();
      }
      this.setPanel(this.processTestPanel);
    },

    showProtectPdfModal() {
      this.showModalWindow(
        ProtectPdfModalComponent,
        { clickToClose: false, draggable: '.modal-card-head' },
      );
    },

    showWatermarkSettingsModal() {
      this.showModalWindow(
        WatermarkSettingsModalComponent,
        { width: '600px', clickToClose: false, draggable: '.modal-card-head' },
      );
    },

    showModalWindow(component, extraProps = { }, extraActions = { }) {
      const props = { ...extraProps, processId: this.processId };
      const options = {
        height: 'auto',
        clickToClose: false,
        draggable: '.modal-handler',
        styles: { overflow: 'visible' },
        adaptive: true,
      };
      const actions = { ...extraActions };

      this.$modal.show(component, props, options, actions);
    },

    async parseTokens() {
      const tokens = JSON.parse(sessionStorage.getItem(this.processId)) || this.tokens;

      await this.updateTokens({ processId: this.processId, tokensData: tokens });
    },
  },
};
</script>
<style lang="scss" module>
  .wrapper {
    max-width: 900px;
  }
  .mode_caption {
    font-size: 0.88rem;
  }
</style>
