<template>
  <div style="height: 100%">
    <div
      v-if="$v.form.templateId.$error"
      class="message is-danger"
    >
      Please select the same type template file ({{ form.from }})
    </div>
    <conversion-step-designer
      v-if="subStep === 0"
      ref="step"
      :$v="$v"
      :process-id="processId"
      :preview-url="previewUrl"
      :template-name="templateName"
      :form="form"
      @resetEditor="resetEditor"
      @save="submitForm"
      @submit="submit"
      @submit-and-next="submitAndNext"
      @fileUploaded="onFileUpload"
      @fileUpdated="onFileUpdate"
      @tagsUpdated="onTagsUpdate"
      @set-value="setFormValue"
      @set-validate-value="setFormValidateValue"
    />
    <conversion-step-settings
      v-if="subStep === 1"
      ref="step"
      :$v="$v"
      :process-id="processId"
      :form="form"
      @save="submitForm"
      @submit="submit"
      @submit-and-next="submitAndNext"
      @set-value="setFormValue"
      @set-validate-value="setFormValidateValue"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useProcessesStore, useTokensStore, useTemplatesStore } from '@/stores/index.js';
import { Step } from '@/components/wizard/index.js';

import { required } from '@/utils/validators.js';

import ConversionStepDesigner from './designer.vue';
import ConversionStepSettings from './settings.vue';

export default {
  name: 'ConversionSetupStep',

  components: {
    ConversionStepDesigner,
    ConversionStepSettings,
  },

  extends: Step,

  props: {
    processId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      previewUrl: '',
      connection: null,
      form: {
        from: '',
        processMode: '',
        outputName: '',
        outputType: '',
        locale: '',
        googleDriveId: null,
        htmlSource: null,
        templateId: null,
        tags: null,
        lockPdfFormFields: true,
        documentNumber: 0,
        timezone: '',
      },
      templateName: '',
    };
  },

  computed: {
    ...mapState(useTemplatesStore, ['htmlSource']),
  },

  watch: {
    'form.documentNumber': function (newVal, oldVal) {
      if (newVal === 0) {
        this.setFormValue({ documentNumber: oldVal });
      }
    },
  },

  created() {
    this.readTokens({ processId: this.processId });
  },

  async mounted() {
    await this.setData();
    this.$v.form.$reset();
  },

  validations: {
    form: {
      locale: {},
      timezone: {},
      processMode: { required },
      outputType: {},
      from: { required },
      htmlSource: { },
      googleDriveId: { },
      outputName: { required },
      tags: { },
      templateId: {
        async isValid(id) {
          if (id === null) return true;

          const { data } = await this.validateTemplateForProcess({ processId: this.processId, id });
          return data;
        },
      },
      lockPdfFormFields: { },
      documentNumber: {
        isNumber(val) {
          return ((val && Math.floor(val) >= 0) || val === '0')
            // for default value
            || !val;
        },
      },
    },
  },

  methods: {
    ...mapActions(useTokensStore, ['readTokens', 'updateTokens']),
    ...mapActions(useProcessesStore, ['updateProcess', 'readProcess', 'readDocumentNumber']),
    ...mapActions(useTemplatesStore, [
      'getHtmlContent',
      'readPreviewTemplateUrl',
      'validateTemplateForProcess',
      'readPreviewTemplateGoogleDriveId',
    ]),

    submitForm() {
      this.updateProcess({ id: this.processId, processData: this.form });
    },

    setFormValue(props) {
      Object.entries(props).forEach(([key, value]) => { this.form[key] = value; });
    },

    setFormValidateValue(props) {
      this.setFormValue(props);
      Object.keys(props).forEach((key) => {
        this.$v.form[key].$touch();
      });
    },

    onFileUpload(response) {
      if (this.form.from === 'HTML') {
        this.setFormValidateValue({ htmlSource: response });
      } else {
        this.setFormValidateValue({ googleDriveId: response });
      }
    },

    onFileUpdate(id) {
      this.setFormValidateValue({ googleDriveId: '' });
      this.setFormValidateValue({ googleDriveId: id });
    },

    onTagsUpdate(tags) {
      this.setFormValidateValue({ tags });

      this.updateTokens({
        processId: this.processId,
        tokensData: JSON.parse(JSON.stringify(tags)),
      });
    },

    async resetEditor() {
      const { data: link } = await this.readPreviewTemplateUrl({ processId: this.processId });

      this.previewUrl = link;

      const { data: id } = await this.readPreviewTemplateGoogleDriveId(
        { processId: this.processId },
      );

      this.setFormValue({ googleDriveId: id });
    },

    async setData() {
      this.setFormValidateValue({ templateId: null });

      const { data: item } = await this.readProcess({ id: this.processId });

      const template = ({
        ...item,
        name: item.processName,
        mode: item.processMode,
        from: item.inputType,
        to: item.outputType,
        locale: item.locale,
        lockPdfFormFields: item.lockPdfFormFields,
      });

      this.templateName = template.templateName;

      this.setFormValue({
        from: template.from,
        processMode: template.mode,
        outputName: template.outputFileName,
        outputType: template.to,
        locale: template.locale,
        lockPdfFormFields: template.lockPdfFormFields,
        timezone: template.timezone,
      });

      if (this.form.from === 'HTML') {
        await this.getHtmlContent(this.processId);
        this.setFormValue({ htmlSource: this.htmlSource });
      }

      await this.fillDocNumber();
    },

    async fillDocNumber() {
      const { number } = await this.readDocumentNumber(this.processId);

      if (number && Math.floor(number) > 0) {
        this.setFormValue({ documentNumber: number });
      }
    },
  },
};
</script>
