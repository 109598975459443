<template>
  <double-button
    :loading="loading"
    :optional-btn-class="optionalBtnClass"
    :optional-trigger-class="optionalTriggerClass"
    @primaryClick="() => clickAction(isSaveNextMode)"
    @secondaryClick="() => clickAction(!isSaveNextMode)"
  >
    <template #primary>
      <span
        v-if="!isSaveNextMode"
        class="icon"
      >
        <font-awesome-icon :icon="[ 'fal', 'floppy-disk' ]" />
      </span>
      <span clas="dropdown-btn__main">
        {{ primaryBtnName }}
      </span>
    </template>
    <template #secondary>
      <span
        v-if="isSaveNextMode"
        class="icon"
      >
        <font-awesome-icon :icon="[ 'fal', 'floppy-disk' ]" />
      </span>
      {{ secondaryBtnName }}
    </template>
  </double-button>
</template>

<script>
import DoubleButton from '@/components/doubleButton.vue';

export default {
  name: 'SaveButton',
  components: { DoubleButton },

  props: {
    optionalTriggerClass: { type: [String, null], default: null },
    optionalBtnClass: { type: [String, null], default: null },
    loading: { type: Boolean, default: false },
  },

  data() {
    return {
      activeMode: localStorage.getItem('save-button-mode') || 'SaveNext',
    };
  },

  computed: {
    primaryMode: {
      get() {
        return this.activeMode;
      },
      set(value) {
        this.activeMode = value;
        localStorage.setItem('save-button-mode', value);
      },
    },

    isSaveNextMode() {
      return this.activeMode === 'SaveNext';
    },

    primaryBtnName() {
      return this.isSaveNextMode ? 'Save & Next' : 'Save';
    },
    secondaryBtnName() {
      return !this.isSaveNextMode ? 'Save & Next' : 'Save';
    },
  },

  methods: {
    clickAction(isSaveNext = false) {
      if (isSaveNext) {
        this.primaryMode = 'SaveNext';
        this.$emit('save-next');
      } else {
        this.primaryMode = 'Save';
        this.$emit('save');
      }
    },
  },
};
</script>
