<template>
  <div>
    <slot name="title">
      <div :class="$style.header">
        <h1 class="is-size-5">
          {{ name }}
        </h1>
        <p v-html="fullDescription" />
      </div>
    </slot>

    <div :class="$style.body">
      <form
        :class="$style.body__form"
        @submit.prevent
      >
        <slot name="content" />

        <slot name="footer">
          <div :class="$style.buttons">
            <button
              type="button"
              :class="[$style.button, 'button is-light']"
              @click="$emit('cancel')"
            >
              Cancel
            </button>
            <save-button
              :class="$style.button"
              @save-next="$emit('submit')"
              @save="$emit('save')"
            />

            <button
              v-if="formId"
              type="button"
              :class="[$style.button, $style['button--right'], 'button', 'is-white']"
              @click="$emit('delete-step')"
            >
              <span :class="$style.button__icon">
                <font-awesome-icon :icon="['fal', 'trash-can']" />
              </span>
              Delete
            </button>
          </div>
        </slot>
      </form>

      <div :class="$style.column">
        <slot name="column" />
      </div>
    </div>
  </div>
</template>

<script>
import SaveButton from '../components/save-button.vue';

export default {
  components: { SaveButton },
  props: {
    name: {
      type: String,
      default: null,
    },

    fullDescription: {
      type: String,
      default: null,
    },

    formId: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>

<style lang="scss" module>
.header {
  margin-bottom: 24px;
}
.body {
  display: flex;
  gap: 24px;
    &__form {
      flex-grow: 1;
      max-width: 763px
    }

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 0;
  }
}

.column {
  flex: 0 1 auto;
}

.buttons {
  display: flex;
}

.button {
  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  &--right {
    margin-left: auto;
  }

  &__icon {
    margin-right: 10px;
  }
}
</style>
