export const type = 'Outlook';
export const name = 'Send Outlook email';
export const icon = 'service-outlook';
export const description = 'Send the result document as an attachment by Outlook.';
export const fullDescription = 'Send the result document as an attachment by Outlook. Learn more in <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/outlook.html" target="_blank">the documentation.</a>';

export const options = {
  type, name, icon, description, fullDescription,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: 'outlook-delivery-step',
    propsData: options,
  },
};
