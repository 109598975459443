<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div
        v-if="form.account"
        class="content"
      >
        <label class="label">Business</label>
        <div class="field is-grouped">
          <div class="control is-expanded">
            <div class="select is-fullwidth">
              <select v-model="form.account">
                <option
                  v-for="business in form.businessesList"
                  :key="business['id']"
                  :value="business['id']"
                >
                  {{ business['name'] }}
                </option>
              </select>
            </div>

            <div class="control">
              <a
                class="button is-white has-text-primary"
                @click="reconnect"
              >Reconnect</a>
            </div>
          </div>
        </div>

        <label class="label">Email subject</label>
        <div class="field">
          <tokens
            v-model="$v.form.emailSubject.$model"
            class="control"
          >
            <input
              v-model="$v.form.emailSubject.$model"
              class="input"
              :class="{ 'is-danger': isInvalid($v.form.emailSubject) }"
              type="text"
            >

            <p
              v-if="isInvalid($v.form.emailSubject)"
              class="help is-danger"
            >
              | Please fill email subject
            </p>
          </tokens>
        </div>

        <label class="label">Email message</label>
        <div class="field">
          <tokens
            v-model="$v.form.emailMessage.$model"
            class="control"
          >
            <textarea
              v-model="$v.form.emailMessage.$model"
              :class="[
                'input',
                $style.input,
                { 'is-danger': isInvalid($v.form.emailMessage) }
              ]"
              :rows="4"
            />

            <p
              v-if="isInvalid($v.form.emailMessage)"
              class="help is-danger"
            >
              | Please fill email message
            </p>
          </tokens>
        </div>

        <label class="label">Recipients</label>
        <div class="field recipients">
          <recipients
            :recipients="$v.form.recipients.$each.$iter"
            :types="recipientTypes"
            @updateRecipients="updateRecipients"
            @deleteRecipient="deleteRecipient"
          >
            <template #default="{item, index, recipientTypes}">
              <recipient-item
                :item="item"
                :index="index"
                :recipient-types="recipientTypes"
                @showPINModal="showPINModal"
              />
            </template>
          </recipients>
        </div>

        <text-button
          title="Add recipient"
          icon="plus"
          @click="addRecipient"
        />

        <advanced-settings>
          <advanced-settings-item-empty
            id="sequentialSigning"
            :item="$v.form.sequentialSigning"
            :tooltip-msg="tooltipMsg"
            label-text="Sequential signing"
          />

          <advanced-settings-item-empty
            id="requireAllSigners"
            :item="$v.form.requireAllSigners"
            label-text="Require all signers to sign to complete document"
          />

          <advanced-settings-item-number
            id="specifyExpiration"
            :checked="$v.form.specifyExpiration"
            label-text="Specify expiration"
            :number="$v.form.specifyExpirationCount"
            number-label-text="days"
          />

          <advanced-settings-item-empty
            id="sendReminders"
            :item="$v.form.sendReminders"
            label-text="Send reminders"
          />

          <advanced-settings-item-empty
            id="isSandbox"
            :item="$v.form.isSandbox"
            label-text="Sandbox Xodo Sign mode"
          />
        </advanced-settings>
      </div>

      <div
        v-else
        class="content"
      >
        <a @click="signinPopup">Connect to Xodo Sign</a>
      </div>
    </template>
  </step-layout>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import { matchEmailOrTemplateOrListOfEmails } from '@/utils/validators.js';
import BaseStep from '@/components/deliveries/base-delivery-step.vue';
import Tokens from '@/components/tokens.vue';
import OAuthClient from '../../services/oauth-client';
import DeliveriesNames from '../deliveries-names';
import PinModalWindow from './xodosign-delivery-pin-modal';
import recipients from '../../components/delivery/recipients';
import recipientItem from '../../components/delivery/xodosign-recipient-item';
import advancedSettings from '../../components/delivery/advancedSettings/advanced-settings';
import advancedSettingsItemEmpty from '../../components/delivery/advancedSettings/advanced-settings-item-empty';
import advancedSettingsItemNumber from '../../components/delivery/advancedSettings/advanced-settings-item-number';
import textButton from '../../components/text-button.vue';
import Confirm from '@/components/modals/confirm.vue';
import { apiEndpoint } from '@/utils/url-manager.js';

export default {
  name: 'XodoSignDeliveryStep',
  components: {
    recipients,
    recipientItem,
    advancedSettingsItemNumber,
    advancedSettingsItemEmpty,
    advancedSettings,
    textButton,
    Tokens,
  },
  extends: BaseStep,
  params: ['processId'],

  data() {
    return {
      form: {
        id: null,
        account: null,
        businessesList: null,
        folder: null,
        accessToken: null,
        emailSubject: null,
        emailMessage: null,
        recipients: [
          {
            name: '',
            email: '',
            type: 1,
            pin: '',
            order: 1,
          },
        ],
        sequentialSigning: false,
        requireAllSigners: false,
        specifyExpiration: false,
        specifyExpirationCount: 7,
        sendReminders: false,
        isSandbox: false,
      },
      tooltipMsg:
                    'By default recipients sign documents parallelly. Enable this option to use sequential signing. Then drag and drop recipients above to change their order',
      cacheId: null,
      oauthClient: null,

      authorize: null,
      clientId: null,
      scope: null,
      redirectUrl: null,
      logout: null,
      recipientTypes: [
        { id: 1, name: 'Needs to sign' },
        { id: 2, name: 'Receives a copy' },
      ],
      eventBus: new Vue(),
    };
  },

  watch: {
    'form.recipients': function (newVal) {
      if (newVal.length === 0) {
        this.form.recipients.push({
          name: '', email: '', type: 1, pin: '', order: 1,
        });
      } else {
        for (let index = 0; index < this.form.recipients.length; index += 1) {
          this.form.recipients[index].order = index + 1;
        }
      }
    },
  },

  created() {
    this.oauthClient = new OAuthClient();
    this.bindEvents();
  },

  async mounted() {
    const settings = await this.getSettings(DeliveriesNames.XodoSign);
    this.authorize = settings.authorizeEndpoint;
    this.clientId = settings.clientId;
    this.scope = settings.scope;
    this.redirectUrl = settings.redirectUrl;
    this.logout = settings.logoutEndpoint;
  },

  methods: {
    async signinPopup() {
      await this.signoutPopup();

      const params = {
        prompt: 'login', client_id: this.clientId, scope: this.scope, redirect_uri: this.redirectUrl,
      };
      const accessData = await this.getAccessData(
        this.authorize, params, DeliveriesNames.XodoSign, this.oauthClient, true,
      );

      if (accessData) {
        this.cacheId = accessData.cacheId;
        await axios
          .get(`${apiEndpoint}documents/processes/${this.processId}/delivery/xodosign/businesses/${accessData.cacheId}`)
          .then((response) => {
            this.form.businessesList = response.data;
            this.form.account = this.form.businessesList[0].id;
          });
      }
    },

    async signoutPopup() {
      if (!this.form.account) {
        return;
      }

      this.form.account = null;
      this.form.businessesList = null;
    },

    async reconnect() {
      await this.signinPopup();
    },

    async submitForm() {
      return (await this.updateDeliverySettings(
        this.processId, { ...this.form, cacheId: this.cacheId }, DeliveriesNames.XodoSign,
      )).data;
    },

    setData() {
      this.clearRecipients();
      if (this.payload) {
        this.form = {
          ...this.payload,
          recipients: this.payload.recipients.map((value) => ({ ...value })),
        };
      }
    },

    clearRecipients() {
      if (this.$v.form.recipients.$invalid && this.form.recipients.length > 0) {
        Object.keys(this.$v.form.recipients.$each).forEach((i) => {
          if (
            this.$v.form.recipients.$each.$iter[i]
            && this.$v.form.recipients.$each.$iter[i].$invalid
          ) {
            this.form.recipients.splice(i, 1);
          }
        });
        this.$v.form.$reset();
      }
    },

    addRecipient() {
      this.form.recipients.push({
        name: '', email: '', type: '1', pin: '',
      });
    },

    deleteRecipient(index) {
      this.$modal.show(
        Confirm,
        {
          title: 'Delete recipient',
          message: 'Are you sure you want to delete this the recipient?',
          onSubmit: () => {
            this.form.recipients.splice(index, 1);
          },
          submitBtnText: 'Delete',
          showCloseBtn: true,
        },
        {
          draggable: '.modal-handler',
          clickToClose: true,
          width: '455px',
          height: 'auto',
          adaptive: true,
        },
      );
    },

    updateRecipients(newRecipients) {
      this.form.recipients = newRecipients;
    },

    showPINModal(recipientIndex) {
      this.showModal(recipientIndex);
    },

    showModal(index) {
      const { pin } = this.form.recipients[index];
      const props = { recipientIndex: index, processId: this.processId, pin };
      const options = { height: 'auto', draggable: '.modal-handler', adaptive: true };

      this.$modal.show(PinModalWindow, props, options);
    },

    bindEvents() {
      this.$root.$on('pin-modal-close-event', (value) => {
        this.form.recipients[value.recipientIndex].pin = value.pin;
      });
    },
  },

  validations() {
    const defaultValidation = {
      form: {
        emailSubject: { required },
        emailMessage: { required },
        recipients: {
          $each: {
            name: { required },
            email: { matchEmailOrTemplateOrListOfEmails },
            type: {},
            pin: {},
            order: {},
          },
        },
        sequentialSigning: {},
        requireAllSigners: {},
        specifyExpiration: {},
        specifyExpirationCount: {},
        sendReminders: {},
        isSandbox: {},
      },
      recipientTypes: [],
    };

    return defaultValidation;
  },
};
</script>

<style lang="scss" module>
textarea.input {
  position: relative;
  height: 150px;
}

svg[data-icon="lock"] {
  filter:
      invert(27%) sepia(51%) saturate(2878%) hue-rotate(146deg) brightness(104%) contrast(97%);
}
</style>