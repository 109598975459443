<template>
  <div>
    <div
      class="file is-right is-fullwidth"
      :class="{ 'is-small': isSmall, 'is-large': isLarge }"
    >
      <label class="file-label">
        <input
          id="fileselect"
          ref="file"
          class="file-input"
          type="file"
          :accept="accept"
          @change="selectedFileChanged"
        >
        <span class="file-cta">
          <span class="file-icon">
            <font-awesome-icon :icon="['fal', 'upload']" />
          </span>
          <span class="file-label">Select File</span>
        </span>
        <span class="file-name">{{ status.selectedFileName }}
          <span
            v-if="status.isUploading"
            style="float: right"
            class="has-text-info"
          >
            {{ status.currentPercent }}%
          </span>
          <span
            v-if="status.isFailed"
            style="float: right"
            class="has-text-danger"
          >
            <font-awesome-icon :icon="['fal', 'xmark']" />
          </span>
        </span>
      </label>
    </div>

    <div v-if="status.isExisting">
      <a @click="downloadSelectedFile">Download the file</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadFileSelect',

  props: {
    status: { type: Object, default: null },
    accept: { type: String, default: null },
    size: { type: String, default: '' },
  },

  computed: {
    isSmall() { return this.size === 's'; },
    isLarge() { return this.size === 'l'; },
  },

  methods: {
    selectedFileChanged(event) {
      this.$emit('change', event.target.files);
      this.$refs.file.value = '';
    },

    deleteSelectedFile() {
      this.$emit('delete');
    },

    downloadSelectedFile(event) {
      this.$emit('obtain', event);
    },
  },
};
</script>
