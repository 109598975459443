<template>
  <div class="field">
    <div class="control">
      <div
        class="dropdown"
        :class="{ 'is-active': active }"
      >
        <div class="dropdown-trigger">
          <button
            class="button is-small is-white has-text-primary"
            :disabled="node.meta.immutable"
            @blur="active = false"
            @click="active = true"
          >
            <span>{{ node.meta.type }}</span>
            <span class="icon is-small">
              <font-awesome-icon :icon="['fal', 'angle-down']" />
            </span>
          </button>
        </div>
        <div class="dropdown-menu">
          <div class="dropdown-content">
            <a
              v-for="label in possibleOptions"
              :key="label"
              class="dropdown-item is-size-7"
              @mousedown="typeChanged(node, label)"
            >
              {{ label }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  props: {
    node: { required: true, type: Object },
  },

  data: () => ({
    active: false,
  }),

  computed: {
    possibleOptions() {
      if (this.node.meta.type === 'object' || this.node.meta.type === 'collection') {
        return ['object', 'collection'];
      }
      return ['string', 'date', 'number', 'boolean'];
    },
  },

  methods: {
    typeChanged(node, type) {
      node.meta.type = type;

      Vue.nextTick(() => {
        this.$emit('typeChanged', {
          path: node.path.join('.'),
          type: node.meta.type,
        });
      });
    },
  },
};
</script>

<style scoped>
.dropdown-menu {
    min-width: 80px !important;
    max-width: 80px !important;
}
</style>
