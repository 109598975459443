export const type = 'Slack';
export const name = 'Send Slack message';
export const icon = 'service-slack';
export const description = 'Send the result document as an attachment by Slack.';
export const fullDescription = 'Send the result document as an attachment by Slack. Learn more in the <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/slack.html" target="_blank">the documentation.</a>';
export const icon_width = 44;
export const icon_height = 44;

export const options = {
  type, name, icon, description, fullDescription, icon_width, icon_height
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: 'slack-delivery-step',
    propsData: options,
  },
};
