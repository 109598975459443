export const type = 'Email';
export const name = 'Send Email';
export const headerName = 'Send email via SMTP';
export const icon = 'service-email';
export const description = 'Send the resulting document as an email attachment via an SMTP server.';
export const fullDescription = 'Send the resulting document as an email attachment via an SMTP server. Learn more in <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/email-smtp.html" target="_blank">the documentation.</a>';

export const options = {
  type, name, icon, description, fullDescription, headerName,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: 'email-smtp-delivery-step',
    propsData: options,
  },
};
