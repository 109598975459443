<template>
  <div
    :class="$style.modal"
    @keydown.enter="save"
    @keydown.esc="close"
  >
    <div :class="[$style.header, 'modal-handler']">
      <p :class="$style.title">
        Template tokens
      </p>
      <button
        :class="['button', 'is-ghost', 'p-0', $style['x-button']]"
        style="min-width: auto"
        type="button"
        @click="$emit('close')"
      >
        <font-awesome-icon
          :icon="['fal', 'xmark']"
        />
      </button>
    </div>
    <p :class="$style.description">
      You can change the types of tokens extracted from your template. It will help us
      to generate a more friendly form for you. Learn
      <a
        href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/custom-testing-form.html"
        target="_blank"
        rel="noopener noreferrer"
      >how it works</a>.
    </p>

    <div :class="[$style.content, $style.body]">
      <TagsTree
        :tags="tagsToRender"
        @typeChanged="onTypeChanged"
      />
      <p
        v-if="overnesting"
        class="help has-text-warning-dark"
      >
        We recommend reducing the nesting of tokens to
        <a
          class="has-text-warning-dark"
          :class="$style['is-underline']"
          href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/custom-testing-form.html#nesting"
          target="_blank"
          rel="noopener noreferrer"
        >get more friendly web forms</a>.
      </p>
    </div>
    <div :class="$style.footer">
      <div
        v-show="loading"
        :class="$style.footer__left"
      >
        <div :class="$style.spinner">
          <ring-loader />
        </div>
        <span>
          Looking for new tokens...
        </span>
      </div>
      <button
        type="button"
        :class="[
          $style.footer__btn,
          'button',
          'is-primary',
          { 'is-loading': saving },
        ]"
        @click="save"
      >
        OK
      </button>
      <button
        type="button"
        :class="[$style.footer__btn, 'button', 'is-light']"
        @click="close"
      >
        Close
      </button>
    </div>
  </div>
</template>

<script>
import { mapWritableState } from 'pinia';
import { useProcessStepsStore } from '@/stores/index.js';
import { accumulateTags } from '@/utils/index.js';
import RingLoader from '@/components/loaders/ring.vue';
import TagsTree from '@/views/documents/processes/components/tags-tree/tags-tree.vue';

export default {
  components: {
    TagsTree,
    RingLoader,
  },

  props: {
    fileId: { default: null, type: String },
    processId: { required: true, type: String },
    readyTags: { default: null, type: Array },
    documentType: { required: true, type: String },
    needUpdate: { required: true, type: Function },
    tagsUpdated: { required: true, type: Function },
    tagsTypeChanged: { required: true, type: Function },
  },

  data: () => ({
    saving: false,
    loading: true,
    tags: [],
    internalTags: [],

    objectTag: 'object',
    collectionTag: 'collection',
  }),

  computed: {
    ...mapWritableState(useProcessStepsStore, ['tagsBuffer']),

    tagsToRender() {
      return [
        {
          name: '@date',
          meta: { type: 'date', immutable: true, description: 'current date' },
        },
        {
          name: '@number',
          meta: { type: 'number', immutable: true, description: 'automatic document number' },
        },

        ...this.internalTags.filter((t) => t.name !== '@date' && t.name !== '@number'),
      ];
    },

    overnesting() {
      return this.tagsToRender.some((tag) => {
        if (!tag.children) return false;

        return (
          (tag.children.length > 0 && tag.meta.type !== 'collection')
          || tag.children.some((t) => t.children.length > 0)
        );
      });
    },
  },

  watch: {
    parsedTokens(newTokens) {
      if (newTokens) {
        this.tags = newTokens;
      }
    },

    tags(newTags) {
      this.collectNodes(newTags);
    },

    tagsBuffer(value) {
      if (value !== null) {
        this.tags = value;
        this.loading = false;
      }
    },
  },

  async mounted() {
    this.tags = JSON.parse(sessionStorage.getItem(this.processId)) || [];

    if (!this.readyTags) {
      await this.needUpdate();
      this.loading = false;
    } else {
      this.loading = false;
      this.tags = this.readyTags;
    }
  },

  methods: {
    close() {
      this.$emit('close');
    },
    async save() {
      const preparedTags = accumulateTags(this.internalTags);

      sessionStorage.setItem(this.processId, JSON.stringify(preparedTags));

      this.tagsUpdated(preparedTags);

      this.close();
    },

    onTypeChanged({ path, type }) {
      this.$nextTick(() => {
        const tag = this.tags.find((t) => t.label === path);

        if (tag) {
          tag.meta.type = type;
        } else {
          this.tags = [...this.tags, { label: path, meta: { type } }];
        }

        const preparedTags = accumulateTags(this.internalTags);

        sessionStorage.setItem(this.processId, JSON.stringify(preparedTags));

        this.tagsTypeChanged();
      });
    },

    isCompountTag(type) {
      return type === this.objectTag || type === this.collectionTag;
    },

    collectNodes(nodes, currentTree = []) {
      const tree = currentTree;

      if (this.documentType === 'PDF') {
        nodes
          .map((node) => ({ path: node.label.split('.'), meta: node.meta }))
          .forEach(({ path, meta }) => {
            let currentLevel = tree;
            path.forEach((part, index) => {
              const existingPath = currentLevel.find(
                (x) => x.name === part || (index > 0 && x.name === path.slice(index - 1).join('.')),
              );
              if (existingPath && !this.isCompountTag(existingPath.meta.type)) return;
              if (existingPath && this.isCompountTag(existingPath.meta.type)) {
                currentLevel = existingPath.children;
                return;
              }
              const newPart = {
                name: path.slice(index).join('.'), meta, children: [], path,
              };

              currentLevel.push(newPart);
              if (this.isCompountTag(meta.type)) {
                currentLevel = newPart.children;
              }
            });
          });
      } else {
        const tags = [];

        nodes
          .slice()
          .sort((left, right) => left.label.split('.').length - right.label.split('.').length)
          .forEach((node) => {
            const path = node.label.split('.');
            for (let i = 1; i < path.length; i += 1) {
              const parent = path.slice(0, i).join('.');
              const parentTag = tags.find((t) => t.label === parent);
              if (parentTag) {
                if (parentTag.meta.type !== this.objectTag) {
                  parentTag.meta.type = this.collectionTag;
                }
              } else {
                tags.push({
                  label: parent,
                  meta: {
                    type:
                    node.meta.type === this.objectTag
                      ? this.objectTag
                      : this.collectionTag,
                  },
                });
              }
            }
            tags.push({ label: path.join('.'), meta: node.meta });
          });

        tags
          .map((node) => ({ path: node.label.split('.'), meta: node.meta }))
          .forEach(({ path, meta }) => {
            let currentLevel = tree;
            path.forEach((part) => {
              const existingPath = currentLevel.find((x) => x.name === part);
              if (existingPath) currentLevel = existingPath.children;
              else {
                const newPart = {
                  name: part, meta, children: [], path,
                };
                currentLevel.push(newPart);
                currentLevel = newPart.children;
              }
            });
          });
      }

      this.internalTags = tree;
    },
  },
};
</script>

<style lang="scss" module>
.modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: calc(100vh - 40px);
  }
  .header {
    display: flex;
    padding: 10px 20px;
    border-bottom: none;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--color-white);

    .x-button {
      height: auto;
      margin-left: 10px;
      color: inherit;
    }
  }

  .title {
    color: hsl(0deg, 0%, 21%);
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: auto;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    word-break: break-all;
  }

  .body {
    padding: 10px 20px 20px;
    background-color: var(--color-white);
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
  }

  .footer {
    padding: 9px 8px 10px;
    display: flex;
    justify-content: flex-end;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    background-color: var(--color-white);
    border-top: 1px solid hsl(0deg, 0%, 86%);

    &__left {
      display: flex;
      margin-right: auto;

      .spinner {
        margin-right: 12px;
      }
    }

    .footer__btn {
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }

.content {
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: baseline;
  overflow: auto;
}

.description {
  padding: 10px 20px;
  border-bottom: 1px solid #ededed;
}

.is-underline {
  text-decoration: underline;
}
</style>
