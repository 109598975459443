<template>
  <p class="control has-icons-right">
    <input
      v-model="passwordModel"
      class="input is-small"
      :type="showPassword ? 'text' : 'password'"
      :class="{ 'is-danger': isInvalid() }"
    >
    <span
      class="icon is-clickable is-right"
      @mousedown="show(true)"
      @mouseup="show(false)"
      @mouseleave="show(false)"
    >
      <font-awesome-icon :icon="[ 'fal', 'eye' ]" />
    </span>
  </p>
</template>

<script>

export default {
  name: 'PasswordInput',
  props: {
    password: {
      type: Object,
      required: true,
    },
    minLenght: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },

  computed: {
    passwordModel: {
      get() {
        return this.password.$model;
      },

      set(value) {
        this.$emit('updatePassword', value);
      },
    },
  },

  methods: {
    show(val) {
      this.showPassword = val;
    },
    isInvalid() {
      return this.password.$model && this.password.$model.length < this.minLenght;
    },
  },
};
</script>

<style  lang="scss">
    .control .icon.is-clickable {
        pointer-events: initial;
    }
</style>
