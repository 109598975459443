<template>
  <div :class="$style.modal">
    <div :class="[$style.header, 'modal-handler']">
      <div :class="$style.title">
        Signer PIN
      </div>
      <button
        type="button"
        :class="['button', 'is-ghost', 'p-0', $style['x-button']]"
        style="min-width: auto"
        @click="$emit('close')"
      >
        <font-awesome-icon
          :icon="['fal', 'xmark']"
        />
      </button>
    </div>
    <div :class="$style.body">
      <div class="field">
        <label>
          Signer PINs provide an extra layer of signer authentication and security.
          Any signer with a Signer PIN assigned will be required to enter this PIN
          in order to be able to view and sign this document.
        </label>
      </div>
      <br>
      <div class="field">
        <label
          class="label"
          for="pin"
        >
          Signer PIN
        </label>

        <div class="control">
          <input
            id="pin"
            v-model="form.pin"
            class="input"
            type="text"
          >
        </div>
      </div>
    </div>

    <div :class="$style.footer">
      <div class="buttons">
        <button
          type="button"
          :class="[$style.footer__btn, 'button', 'is-primary']"
          @click="apply"
        >
          Apply
        </button>
        <button
          type="button"
          :class="[$style.footer__btn, 'button', 'is-light']"
          @click="close"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XodoSignPin',
  props: ['processId', 'recipientIndex', 'pin'],

  data() {
    return {
      form: {
        recipientId: null,
        pin: null,
      },
    };
  },

  async mounted() {
    this.form.pin = this.pin;
  },

  methods: {
    close() {
      this.$emit('close');
    },

    apply() {
      this.$root.$emit('pin-modal-close-event', { pin: this.form.pin, recipientIndex: this.recipientIndex });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module>
.modal {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;
  }
  .header {
    display: flex;
    padding: 10px 20px;
    border-bottom: none;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--color-white);

    .x-button {
      height: auto;
      margin-left: 10px;
      color: inherit;
    }
  }

  .title {
    color: hsl(0deg, 0%, 21%);
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: auto;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    word-break: break-all;
  }

  .body {
    padding: 10px 20px 20px;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
  }

  .footer {
    padding: 9px 8px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    background-color: var(--color-white);
    border-top: 1px solid hsl(0deg, 0%, 86%);

    .footer__btn {
      font-weight: 400;
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }

</style>
