/* eslint-disable camelcase */
export const type = 'Ready';
export const name = 'Start process';
export const icon = ['fas', 'play'];
export const icon_attrs = 'fa-lg';
export const icon_width = 23;
export const icon_height = 23;
export const translate = 2;

export const options = {
  type, name, icon, icon_attrs, icon_width, icon_height, translate,
};
