<template>
  <div
    ref="modal"
    :class="$style.modal"
    tabindex="0"
    @keydown.esc="$emit('close')"
  >
    <div :class="[$style.header, 'modal-handler']">
      <div :class="$style.title">
        {{ !onLoad ? 'Unsaved changes' : 'Saving...' }}
      </div>

      <button
        type="button"
        :class="['button', 'is-ghost', 'p-0', $style['x-button']]"
        style="min-width: auto"
        @click="$emit('close')"
      >
        <font-awesome-icon :icon="['fal', 'xmark']" />
      </button>
    </div>

    <div :class="$style.body">
      Your process has unsaved changes in this step.
      Do you want to save or discard changes before leaving?
    </div>

    <div :class="$style.footer">
      <b-button
        type="is-primary"
        :class="$style.footer__btn"
        :disabled="onLoad"
        @click="save"
      >
        Save
      </b-button>

      <b-button
        type="is-light"
        :class="$style.footer__btn"
        :disabled="onLoad"
        @click="leave"
      >
        Discard
      </b-button>

      <b-button
        type="is-light"
        :class="[$style.footer__btn, $style.footer__btn_thin]"
        :disabled="onLoad"
        @click="$emit('close')"
      >
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnsavedChanges',
  props: {
    onLeave: {
      type: Function,
      default: null,
    },
    onSave: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      onLoad: false,
    };
  },
  mounted() {
    this.$nextTick(() => this.$refs.modal?.focus());
  },
  methods: {
    leave() {
      if (this.onLeave) this.onLeave();
      this.$emit('submit');
      this.$emit('close');
    },
    async save() {
      if (this.onSave) {
        this.onLoad = true;
        await this.onSave();
        this.$emit('submit');
        this.onLoad = false;
      }
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module>
.modal {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;
    word-break: break-word;

    &--headless {
      .header {
        display: none;
      }
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: none;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--color-white);

    .x-button {
      height: auto;
      margin-left: 10px;
      color: inherit;
    }
  }

  .title {
    color: hsl(0deg, 0%, 21%);
    width: auto;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: auto;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    word-break: break-all;
  }

  .body {
    padding: 10px 20px 20px;
    background-color: var(--color-white);
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
  }

  .footer {
    padding: 7px 8px 8px;
    display: flex;
    justify-content: flex-end;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    background-color: var(--color-white);
    border-top: 1px solid hsl(0deg, 0%, 86%);

    .footer__btn {
      font-weight: 400;

      &_thin {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
      }

      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }
</style>
