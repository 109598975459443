export const type = 'Amazon SES';
export const name = 'Send Email';
export const headerName = 'Send email using Amazon Simple Email Service';
export const icon = 'service-amazon-email';
export const description = 'Send the resulting document as an email attachment using Amazon Simple Email Service (SES).';
export const fullDescription = 'Send the resulting document as an email attachment using Amazon Simple Email Service (SES). Learn more in <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/amazon-ses.html" target="_blank">the documentation.</a>';

export const options = {
  type, name, icon, description, fullDescription, headerName,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: 'amazon-email-delivery-step',
    propsData: options,
  },
};
