<template>
  <confirm
    title="Associate form to process"
    submit-btn-text="Bind"
    close-btn-text="Cancel"
    :message="true"
    :show-close-btn="true"
    :close-after-submit="false"
    :on-submit="bindForm"
    @close="close"
  >
    <div :class="[$style.field, 'field']">
      <label class="label">Form that will trigger the process</label>
      <div
        v-if="forms.length > 0"
        class="control is-expanded"
      >
        <div
          class="select is-fullwidth"
          :class="{ 'is-danger': $v.form.selected.$error }"
        >
          <select v-model="form.selected">
            <option
              :value="null"
              selected="selected"
              hidden="hidden"
            >
              Select the form
            </option>
            <option
              v-for="optionForm in forms"
              :key="optionForm.id"
              :value="optionForm.id"
            >
              {{ optionForm.name }}
            </option>
          </select>
        </div>
      </div>
      <p
        v-if="$v.form.selected.$error"
        class="help is-danger"
      >
        A form to bind must be selected
      </p>
      <div class="level">
        <div
          v-if="forms.length === 0"
          class="level-left"
        >
          You don't have created forms
        </div>
      </div>
    </div>
  </confirm>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Confirm from '@/components/modals/confirm.vue';
import { apiEndpoint, formsApiEndpoint } from '@/utils/url-manager.js';

const formsEndpoint = `${formsApiEndpoint}v2/designer/forms`;
const endpoint = `${apiEndpoint}documents/processes`;

export default {
  name: 'BindForm',
  components: { Confirm },
  props: { processId: { type: String, required: true } },

  data() {
    return {
      form: {
        selected: null,
      },
      forms: [],
    };
  },

  async mounted() {
    this.forms = (await this.$http.get(`${formsEndpoint}`)).data;
  },

  validations: {
    form: {
      selected: { required },
    },
  },

  methods: {
    close() {
      this.form.selected = null;
      this.$emit('close');
    },

    async bindForm() {
      this.$v.form.$touch();

      if (!this.$v.form.$error) {
        const config = { headers: { 'Content-Type': 'application/json' } };
        this.forms = (await this.$http.post(`${endpoint}/${this.processId}/forms`, JSON.stringify(this.form.selected), config)).data;
      } else return;

      this.close();
    },
  },
};
</script>

<style lang="scss" module>
.field {
  width: 100%;
}
</style>