<template>
  <font-awesome-icon
    v-if="isFontAwesome"
    :class="$style.step_icon"
    :style="{
      fontSize: height + 'px',
      transform: `translate(${translate}px)`,
    }"
    :icon="src"
  />

  <img
    v-else
    :src="src"
    :width="width"
    :height="height"
  >
</template>

<script>
export default {
  props: {
    name: {
      type: [String, Array],
      default: '',
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    translate: {
      type: Number,
      default: null,
    },
  },
  computed: {
    isFontAwesome() {
      return Array.isArray(this.name);
    },

    src() {
      if (this.isFontAwesome) {
        return this.name;
      }
      // eslint-disable-next-line
      return require(`@/assets/${this.name}.svg`);
    },
  },
};
</script>

<style lang="scss" module>
.step_icon {
  color: #7D4BCD;
}
</style>
