<template>
  <div
    :class="$style.modal"
    @keydown.enter="apply"
    @keydown.esc="$emit('close')"
  >
    <div :class="[$style.header, 'modal-handler']">
      <div :class="$style.title">
        Watermark settings
      </div>
      <button
        type="button"
        :class="['button', 'is-ghost', 'p-0', $style['x-button']]"
        style="min-width: auto"
        @click="$emit('close')"
      >
        <font-awesome-icon
          :icon="['fal', 'xmark']"
        />
      </button>
    </div>

    <div :class="$style.body">
      <div class="tile is-ancestor is-vertical">
        <div class="tile is-12 is-block">
          <div class="tile is-parent">
            <div class="tile is-child">
              <label class="label">Template</label>
              <upload-file-base
                ref="uploadFileComponent"
                :upload-url="uploadFileUrl"
                :delete-url="uploadFileUrl"
                :existing-file="existingFile"
                @fileUploaded="onFileUploaded"
                @fileDeleted="onFileDeleted"
              >
                <upload-file-select
                  slot-scope="{ status: status }"
                  :status="status"
                  accept=".jpg, .jpeg, .png"
                />
              </upload-file-base>
              <span
                v-show="$v.uploadedFile.$error && !$v.uploadedFile.isValid"
                class="help is-danger"
              >
                Please upload JPEG or a PNG file as watermark.
              </span>
            </div>
          </div>
          <div
            class="tile is-12 is-block"
            :class="{'is-hidden': !(uploadedFile || existingFile)}"
          >
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <div class="tile is-child">
                  <div class="field">
                    <label class="label">Position</label>
                    <div class="control is-expanded">
                      <div class="select is-fullwidth">
                        <select v-model="selectedPosition">
                          <option
                            v-for="position in positions"
                            :key="position.type"
                            :value="position.type"
                          >
                            | {{ position.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tile is-parent is-vertical">
                <div class="tile is-child">
                  <div class="field">
                    <label class="label">Opacity (%)</label>
                    <div class="control">
                      <input
                        v-model="selectedOpacity"
                        type="number"
                        class="input"
                      >
                    </div>
                    <p
                      v-if="$v.selectedOpacity.$invalid"
                      class="help is-danger"
                    >
                      | Please, provide a value between 0 and 100
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="$style.footer">
      <a
        :class="[
          {'is-invisible': !(uploadedFile || existingFile)},
          $style.footer__left,
        ]"
        @click="removeWatermark"
      >
        Remove watermark
      </a>
      <div class="buttons">
        <button
          type="button"
          :class="[
            $style.footer__btn,
            'button',
            'is-primary',
            { 'is-loading': uploading }
          ]"
          @click="apply"
        >
          Apply
        </button>
        <button
          type="button"
          :class="[$style.footer__btn, 'button', 'is-light']"
          @click="close"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import { UploadFileBase, UploadFileSelect } from '@/components/upload/index.js';
import { apiEndpoint } from '@/utils/url-manager.js';

const { CancelToken } = axios;
let cancel;

const endpoint = `${apiEndpoint}documents/processes`;

export default {
  name: 'WatermarkSettings',

  components: {
    'upload-file-base': UploadFileBase,
    'upload-file-select': UploadFileSelect,
  },
  props: {
    processId: { type: String, required: true },
  },

  data() {
    return {
      uploading: null,

      positions: [
        { type: 'TopLeft', label: 'TopLeft' },
        { type: 'TopMiddle', label: 'TopMiddle' },
        { type: 'TopRight', label: 'TopRight' },
        { type: 'MiddleLeft', label: 'MiddleLeft' },
        { type: 'Center', label: 'Center' },
        { type: 'MiddleRight', label: 'MiddleRight' },
        { type: 'BottomLeft', label: 'BottomLeft' },
        { type: 'BottomMiddle', label: 'BottomMiddle' },
        { type: 'BottomRight', label: 'BottomRight' },
      ],

      dimensions: [{ type: 'Auto', label: 'Auto' }],

      selectedWidth: 'Auto',
      selectedHeight: 'Auto',
      selectedOpacity: 100,
      selectedPosition: 'Center',
      existingFile: null,

      uploadedFile: null,
      uploadFileUrl: `${apiEndpoint}documents/processes/files`,
    };
  },

  async mounted() {
    const settings = (await axios.get(`${endpoint}/${this.processId}/watermark/settings`)).data;

    this.selectedWidth = settings.width;
    this.selectedHeight = settings.height;
    this.selectedOpacity = settings.opacity;
    this.selectedPosition = settings.position;
    this.existingFile = settings.watermarkName;
  },

  validations() {
    return {
      selectedOpacity: {
        required,
        minValue: (val) => val >= 0,
        maxValue: (val) => val <= 100,
      },
      uploadedFile: {
        required,
        async isValid(id) {
          if (!id) return false;

          return (await axios.get(`${endpoint}/validate/watermark/${id}`)).data;
        },
      },
    };
  },

  methods: {
    onFileUploaded(data, fileName) {
      this.$v.uploadedFile.$model = JSON.parse(data);
      this.existingFile = fileName;
    },

    onFileDeleted() {
      this.reset();
    },

    close() {
      if (!this.uploading) {
        this.$emit('close');
        return;
      }

      cancel();
      this.uploading = false;
    },

    async apply() {
      this.$v.selectedOpacity.$touch();
      this.$v.uploadedFile.$touch();

      if (this.$v.uploadedFile.$invalid || this.$v.selectedOpacity.$invalid) return;

      this.uploading = true;

      const form = {
        watermarkFileId: this.uploadedFile,
        position: this.selectedPosition,
        opacity: this.selectedOpacity,
        width: this.selectedWidth,
        height: this.selectedHeight,
      };

      await axios.post(`${endpoint}/${this.processId}/watermark/settings`, form, {
        cancelToken: new CancelToken((c) => { cancel = c; }),
      });

      this.uploading = false;

      this.$emit('close');
    },

    reset() {
      this.selectedWidth = 'Auto';
      this.selectedHeight = 'Auto';
      this.selectedOpacity = 100;
      this.selectedPosition = 'Center';
      this.uploadedFile = null;
      this.existingFile = null;
    },

    async removeWatermark() {
      this.uploading = true;

      if (this.existingFile && !this.uploadedFile) {
        await axios.delete(`${endpoint}/${this.processId}/watermark/settings`);
      } else {
        this.$refs.uploadFileComponent.deleteSelectedFile();
      }

      this.uploading = false;

      this.$v.uploadedFile.$reset();
      this.reset();
    },
  },
};
</script>
<style lang="scss" module>
  .modal {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;
  }
  .header {
    display: flex;
    padding: 10px 20px;
    border-bottom: none;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--color-white);

    .x-button {
      height: auto;
      margin-left: 10px;
      color: inherit;
    }
  }

  .title {
    color: hsl(0deg, 0%, 21%);
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: auto;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    word-break: break-all;
  }

  .body {
    padding: 10px 20px 20px;
    background-color: var(--color-white);
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
  }

  .footer {
    padding: 9px 8px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    background-color: var(--color-white);
    border-top: 1px solid hsl(0deg, 0%, 86%);

    &__left {
      margin-right: auto;
    }

    .footer__btn {
      font-weight: 400;
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }
</style>