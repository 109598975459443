<script>
import axios from 'axios';
import { mapActions } from 'pinia';
import { useProcessesStore } from '@/stores/index.js';
import DynamicStep from '@/components/wizard/dynamic-step.vue';
import { openNotification } from '@/components/notification-service.js';
import { randomString } from '@/utils/index.js';
import { apiEndpoint } from '@/utils/url-manager.js';

export default {
  name: 'BaseDeliveryStep',
  extends: DynamicStep,
  params: ['processId'],
  methods: {
    ...mapActions(useProcessesStore, ['createOrUpdateDelivery']),
    async getSettings(environmentType) {
      return (await this.$http.get(`${apiEndpoint}auth/providers/${environmentType}`)).data;
    },

    getAccessData(authPath, queryParams, environmentType, client, withState) {
      return new Promise((resolve) => {
        let url = `${authPath}?`;
        if (queryParams) {
          const params = ['response_type=code'];
          Object.keys(queryParams).forEach((key) => {
            params.push(`${key}=${queryParams[key]}`);
          });

          url += params.join('&');
        }

        const state = randomString(10);
        client.showPopup(withState ? `${url}&state=${state}` : url, async (event) => {
          if (event.data !== Object(event.data) && event.data && event.data.indexOf('code=') !== -1) {
            const url = new URL(`${event.origin}/${event.data}`);
            let allowed = true;
            if (withState) {
              allowed = state === url.searchParams.get('state');
            }

            if (allowed) {
              const response = await axios.get(`${apiEndpoint}auth/connect?type=${environmentType}&code=${url.searchParams.get('code')}&state=${url.searchParams.get('state')}`);
              resolve(response.data);
            }
            return true;
          }
          return false;
        });
      });
    },

    async updateDeliverySettings(processId, deliveryData, deliveryName) {
      return this.createOrUpdateDelivery({ processId, deliveryName, deliveryData })
        .catch((err) => {
          if (err.response.status === 404) {
            openNotification({
              message: err.response.data,
              type: 'danger',
              duration: 1000,
            });
          }
          console.error(err);
        });
    },
  },

};
</script>

<style scoped>

</style>
@/utils/url-manager.js