<template>
  <form-panel
    first-mode-name="Test & Preview"
    second-mode-name="Test & Download"
    storage-marker="test-process"
    :side-panel-name="sidePanelName"
    :loading="testing"
    :current-mode.sync="mode"
    :current-option.sync="currentOption"
    :is-pinned.sync="isPinned"
    :is-success="success"
    @start="create"
    @formatJson="actionIndicator = 'format'"
    @cancel="cancel"
    @closeMessage="processTesting = null"
  >
    <div v-if="blocked">
      <div class="level">
        <button
          type="button"
          class="button is-light level-item"
          @click="openLink(processTesting.link)"
        >
          Open the document
        </button>
      </div>

      <p class="has-text-centered">
        {{ processTesting && processTesting.message }}
      </p>
    </div>

    <json-tester
      v-else-if="currentOption === 'json'"
      :google-drive-id="googleDriveId"
      :html-source="htmlSource"
      :action-indicator.sync="actionIndicator"
      @startTesting="onStartTesting"
      @failedTesting="onFailedTesting"
      @succeedTesting="onSucceedTesting"
      @canceledTesting="onCanceledTesting"
    />

    <form-tester
      v-else-if="currentOption"
      :loading="loadingForm"
      :google-drive-id="googleDriveId"
      :form-id="currentOption"
      :action-indicator.sync="actionIndicator"
      @startTesting="onStartTesting"
      @succeedTesting="onSucceedTesting"
      @canceledTesting="onCanceledTesting"
      @failedTesting="onFailedTesting"
    />
  </form-panel>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import JsonTester from './json-template-testing.vue';
import FormTester from './form-template-testing.vue';
import FormPanel from '@/views/documents/processes/components/form-panel/index.vue';
import {
  useProcessStepsStore,
  useProcessesStore,
  useSidePanelStore,
  useTemplatesStore,
  useErrorsStore,
} from '@/stores/index.js';

export default {
  name: 'TemplateTestingPanel',

  components: {
    FormPanel,
    FormTester,
    JsonTester,
  },

  props: {
    googleDriveId: { default: null, type: String },
    htmlSource: { default: null, type: String },
    inputType: { default: null, type: String },
    fileName: { required: true, type: String },
    needUpdate: { required: true, type: Function },
    tagsUpdated: { required: true, type: Function },
    sidePanelName: { required: true, type: String },
  },

  data() {
    return {
      mode: window.localStorage.getItem('test-process-mode') || 'First',
      renderComponent: true,
      processTesting: null,
      currentOption: '',
      loading: false,
      tags: null,
      primaryBtnName: null,
      slaveBtnName: null,
      loadingForm: false,
      actionIndicator: null,
      isPinned: false,
      success: false,
      successOffTimer: null,
    };
  },

  computed: {
    ...mapState(useSidePanelStore, ['currentPanel']),
    ...mapState(useProcessesStore, ['processId']),
    ...mapState(useTemplatesStore, ['testTemplate']),
    ...mapWritableState(useProcessStepsStore, ['tagsBuffer']),
    ...mapWritableState(useProcessesStore, ['processTestingMode']),

    testing() {
      return this.processTesting && this.processTesting.status === 'Waiting';
    },
    blocked() {
      return this.processTesting && this.processTesting.status === 'Blocked';
    },
    isPreviewMode() {
      return this.mode === 'First';
    },
    isCurrentPanel() {
      return this.sidePanelName === this.currentPanel;
    },
  },

  watch: {
    async isCurrentPanel(value) {
      if (value) {
        this.loadingForm = true;
        await this.needUpdate();
        this.loadingForm = false;
      }
    },
    tagsBuffer: {
      handler(value) {
        if (value !== null) {
          this.loading = true;
          this.tags = value;
          sessionStorage.setItem(this.processId, JSON.stringify(value));
        }
        this.loading = false;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(useProcessesStore, ['updateTestProcess']),
    ...mapActions(useSidePanelStore, ['closePanel']),
    ...mapActions(useErrorsStore, ['setError']),

    afterCreate() {
      if (!this.isPinned) {
        this.success = false;
        if (this.successOffTimer) {
          clearTimeout(this.successOffTimer);
          this.successOffTimer = null;
        }
        this.cancel();
        this.closePanel();
      }
    },

    setSuccess() {
      this.success = true;
      this.successOffTimer = setTimeout(() => { this.success = false; }, 1500);
    },

    async create(isPrimaryClick) {
      if (!isPrimaryClick) {
        const createMode = this.isPreviewMode ? 'download' : 'preview';

        const processData = {
          json: this.testTemplate,
          createMode,
        };

        await this.updateTestProcess({ id: this.processId, processData });

        this.processTestingMode = createMode;
      }

      this.actionIndicator = 'create';
    },

    async openLink(link) {
      let content;
      let resultWindow;

      if (/\.html$/.test(this.fileName)) {
        const response = await fetch(link);
        content = await response.text();

        resultWindow = window.open();
      } else if (/\.pdf$/.test(this.fileName)) {
        resultWindow = window.open(link, '_blank');
      } else if (/\.(doc|xls|ppt)x$/.test(this.fileName)) {
        const url = encodeURIComponent(link);
        resultWindow = window.open(`http://view.officeapps.live.com/op/embed.aspx?src=${url}`, '_blank');
      }

      if (!resultWindow || resultWindow.closed || typeof resultWindow.closed === 'undefined') {
        this.processTesting = {
          status: 'Blocked',
          message: 'Allow popup dialogs for account.plumsail.com to open result documents instantly',
          link,
        };

        return;
      }

      if (content) {
        resultWindow.document.write(content);
      }
    },

    onStartTesting() {
      this.processTesting = {
        status: 'Waiting',
        message: 'Process is being tested',
      };
    },

    onCanceledTesting() {
      this.processTesting = {
        status: 'Canceled',
        message: 'The request has been canceled',
      };
    },

    onFailedTesting(message) {
      this.processTesting = {
        status: 'Error',
        message,
      };
      this.setError({ message });
      this.afterCreate();
    },

    async onSucceedTesting(link) {
      if (this.processTesting.status === 'Canceled') { return; }

      this.processTesting = {
        status: 'OK',
        message: 'Process is valid',
      };
      this.afterCreate();
      this.setSuccess();
      if (this.isPreviewMode) {
        this.openLink(link);
      } else {
        await this.download(link);
      }
    },

    async download(link) {
      const fileName = this.getFileNameFromLink(link);

      await fetch(link)
      .then((resp) => resp.blob())
      .then((blob) => {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(new Blob([blob]));
        elem.target = '_blank';
        elem.download = fileName;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      });
    },

    getFileNameFromLink(link) {
      const url = new URL(decodeURI(link));
      const segment = url.searchParams?.get('rscd')?.split(';')?.find((element) => element.startsWith('filename'));

      return segment?.split('"')[1] || this.fileName;
    },

    cancel() {
      if (!this.testing) return;

      this.actionIndicator = 'cancel';
    },
  },
};
</script>
