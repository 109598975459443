<template>
  <div class="field">
    <label class="label">Account</label>
    <div :class="$style.accountInfo">
      <span :class="$style.accountInfo__item">{{ name }}</span>

      <span :class="$style.accountInfo__right">
        <a
          :class="[$style.accountInfo__item, 'has-text-info']"
          @click="$emit('reconect')"
        >
          Reconnect
        </a>
        <a
          :class="[$style.accountInfo__item, 'has-text-danger']"
          @click="$emit('sign-out')"
        >
          Sign out
        </a>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AccountInfo',

  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" module>
.accountInfo {
  display: flex;
  &__right {
    display: flex;
    margin-left: auto;
  }
  &__item {
    &:not(:last-child) {
      margin-right: 0.75rem;
    }
  }
}
</style>
