export function createSkeleton(isActive = false) {
  return {
    isActive,
    isInvalid: false,
    isFinished: false,
  };
}

export function createPropsData(options, payload) {
  return {
    ...options,
    payload,
    stepId: `${Math.random().toString(36).substr(2, 9)}`,
  };
}
