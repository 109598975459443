<template>
  <div
    :class="$style.modal"
    @keydown.enter="apply"
    @keydown.esc="$emit('close')"
  >
    <div :class="[$style.header, 'modal-handler']">
      <div :class="$style.title">
        Protect PDF
      </div>
      <button
        type="button"
        :class="['button', 'is-ghost', 'p-0', $style['x-button']]"
        style="min-width: auto"
        @click="$emit('close')"
      >
        <font-awesome-icon
          :icon="['fal', 'xmark']"
        />
      </button>
    </div>

    <div
      :class="$style.body"
      style="border-bottom: 0"
    >
      <fieldset :class="$style['checkbox-group']">
        <div class="field">
          <b-checkbox
            v-model="form.disablePrinting"
          >
            <span :class="$style.label">
              Disable printing
            </span>
          </b-checkbox>
        </div>

        <div class="field">
          <b-checkbox
            v-model="form.disableModification"
          >
            <span :class="$style.label">
              Disable modification
            </span>
          </b-checkbox>
        </div>

        <div class="field">
          <b-checkbox
            v-model="form.disableDataExtraction"
          >
            <span :class="$style.label">
              Disable data extraction
            </span>
          </b-checkbox>
        </div>

        <div class="field">
          <b-checkbox
            v-model="form.disableAnnotations"
          >
            <span :class="$style.label">
              Disable annotations
            </span>
          </b-checkbox>
        </div>
      </fieldset>

      <div class="field">
        <label
          for="user-password"
          class="label"
        >
          PDF user password
        </label>
        <div class="control">
          <b-input
            v-model="form.userPassword"

            type="password"
          />
        </div>
      </div>

      <div class="field">
        <label
          for="owner-password"
          class="label"
        >
          PDF owner password
        </label>
        <div class="control">
          <b-input
            v-model="form.ownerPassword"

            type="password"
          />
        </div>
      </div>
    </div>

    <div :class="$style.footer">
      <div class="buttons">
        <button
          type="button"
          :class="[$style.footer__btn, 'button', 'is-primary']"
          @click="apply"
        >
          Apply
        </button>
        <button
          type="button"
          :class="[$style.footer__btn, 'button', 'is-light']"
          @click="close"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useProcessesStore } from '@/stores/index.js';

export default {
  name: 'ProtectPdf',
  props: {
    processId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {},
    };
  },
  async mounted() {
    const { data } = await this.readPdfSettings({ processId: this.processId });

    this.form = { ...data };
  },
  methods: {
    ...mapActions(useProcessesStore, ['readPdfSettings', 'updatePdfSettings']),

    close() {
      return this.$emit('close');
    },
    async apply() {
      this.updatePdfSettings({ processId: this.processId, settings: this.form });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module>
.modal {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;
  }
  .header {
    display: flex;
    padding: 10px 20px;
    border-bottom: none;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--color-white);

    .x-button {
      height: auto;
      margin-left: 10px;
      color: inherit;
    }
  }

  .title {
    color: hsl(0deg, 0%, 21%);
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: auto;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    word-break: break-all;
  }

  .body {
    padding: 10px 20px 20px;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
  }

  .footer {
    padding: 9px 8px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    background-color: var(--color-white);
    border-top: 1px solid hsl(0deg, 0%, 86%);

    &__left {
      margin-right: auto;
    }

    .footer__btn {
      font-weight: 400;
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }

.label {
  font-size: 16px;
}
.checkbox-group {
  padding: 12px 0;
}
</style>
