<template>
  <step-layout
    :name="headerName"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div
        class="content"
      >
        <b-button @click="openSettings">
          <font-awesome-icon
            :icon="['fal', 'gears']"
            style="margin-left: -5px;"
          />
          SMTP settings
        </b-button>
      </div>

      <div
        v-if="smtpConfig.account"
        class="content"
      >
        <div class="field">
          <label for="Recipient" class="label">To</label>
          <tokens
            v-model="$v.form.to.$model"
            class="control"
          >
            <tags-input
              v-model="$v.form.to.$model"
              input-id="Recipient"
              placeholder="Add new recipient"
              :class="{ 'is-danger': isInvalid($v.form.to) }"
              add-tags-on-comma="add-tags-on-comma"
              add-tags-on-blur="add-tags-on-blur"
            />
            <p
              v-if="isInvalid($v.form.to) && !$v.form.to.required"
              class="help is-danger"
            >
              This field must be filled
            </p>
            <p
              v-if="isInvalid($v.form.to) && $v.form.to.required && !$v.form.to.emails"
              class="help is-danger"
            >
              Please check that each entry is a valid email
            </p>
          </tokens>
        </div>

        <div class="field">
          <label for="Subject" class="label">Subject</label>
          <tokens
            v-model="$v.form.subject.$model"
            class="control"
          >
            <input
              id="Subject"
              v-model="$v.form.subject.$model"
              class="input"
              type="text"
              :class="{ 'is-danger': isInvalid($v.form.subject) }"
            >
            <p
              v-if="isInvalid($v.form.subject) && !$v.form.subject.required"
              class="help is-danger"
            >
              This field must be filled
            </p>
          </tokens>
        </div>
        <email-editor
          v-model="$v.form.content.$model"
          one-line="oneLine"
        />
        <p
          v-if="isInvalid($v.form.content) && !$v.form.content.required"
          class="help is-danger"
        >
          The content must be specified
        </p>
        <advanced-settings :is-visible="isAdvanceVisible">
          <div class="field">
            <label for="DisplayName" class="label">Display name</label>
            <tokens
              v-model="$v.form.displayName.$model"
              class="control"
              position="is-top-left"
            >
              <input
                id="DisplayName"
                v-model="$v.form.displayName.$model"
                class="input"
                type="text"
                :placeholder="smtpConfig.account"
              >
            </tokens>
          </div>
          <div class="field">
            <label for="Reply" class="label">Reply-to address</label>
            <tokens
              v-model="$v.form.replyTo.$model"
              class="control"
              position="is-top-left"
            >
              <input
                id="Reply"
                v-model="$v.form.replyTo.$model"
                class="input"
                type="text"
                :placeholder="userEmail"
              >
            </tokens>
          </div>
        </advanced-settings>
      </div>
    </template>
  </step-layout>
</template>

<script>
import { required, email, helpers } from 'vuelidate/lib/validators';
import { mapState } from 'pinia';
import { useSettingsStore } from '@/stores';
import TagsInput from '@voerro/vue-tagsinput';
import EmailEditor from '@/components/rich-text-editor/index.vue';
import Tokens from '@/components/tokens.vue';
import BaseStep from '@/components/deliveries/base-delivery-step.vue';
import advancedSettings from '../../components/delivery/advancedSettings/advanced-settings.vue';
import smtpSettingsModal from './smtp-settings-modal.vue';
import DeliveriesNames from '../deliveries-names';

const token = helpers.regex('token', /{{.*}}/);

export default {
  name: 'EmailSmtpDeliveryStep',
  components: {
    EmailEditor,
    TagsInput,
    advancedSettings,
    Tokens,
  },
  extends: BaseStep,
  params: ['processId'],
  data() {
    return {
      form: {
        id: null,
        to: [],
        subject: null,
        content: null,
        replyTo: null,
        displayName: null,
        encryptedSecret: null,
      },
      smtpConfig: {
        account: null,
        user: null,
        password: null,
        host: null,
        port: null,
        ssl: true,
        explicitTls: true,
      },
      isAdvanceVisible: false,
      isSmtpNeedsToUpdated: false,
      userEmail: null,
    };
  },
  validations() {
    const defaultValidation = {
      form: {
        to: {
          required,
          $each: {
            emails(entry) {
              return email(entry.value) || token(entry.value);
            },
          },
        },
        subject: { required },
        content: { required },
        replyTo: {},
        displayName: {},
      },
    };

    return defaultValidation;
  },

  computed: {
    ...mapState(useSettingsStore, ['getProfile']),
  },

  watch: {
    'form.displayName': {
      handler(value) {
        if (value && value.value !== '') {
          this.isAdvanceVisible = true;
        }
      },
    },

    '$v.form.to.$model': {
      handler(value, previos) {
        if (value.value === previos.value) {
          this.$v.form.to.$reset();
        }
      },
    },
  },

  async mounted() {
    this.userEmail = this.getProfile.email;
  },

  methods: {
    openSettings() {
      this.$modal.show(
        smtpSettingsModal,

        {
          account: this.userEmail,
          config: this.smtpConfig,
          updateConfig: (value) => {
            this.smtpConfig = value;
            this.isSmtpNeedsToUpdated = true;
          },
        },
        {
          clickToClose: false,
          adaptive: true,
          width: '440px',
          height: 'auto',
          draggable: '.modal-handler',
        },
      );
    },
    setData() {
      if (this.payload) {
        this.smtpConfig.account = this.payload.account || null;
        this.smtpConfig.user = this.payload.user || null;
        this.smtpConfig.host = this.payload.host || null;
        this.smtpConfig.port = this.payload.port || null;
        this.smtpConfig.ssl = (this.payload.ssl || this.payload.ssl === false) || true;
        this.smtpConfig.explicitTls = (
          this.payload.explicitTls || this.payload.explicitTls === false
        ) || true;

        this.form.id = this.payload.id || null;
        this.form.subject = this.payload.subject || null;
        this.form.content = this.payload.content || null;
        this.form.replyTo = this.payload.replyTo || null;
        this.form.displayName = this.payload.displayName || null;
        this.form.encryptedSecret = this.payload.password || null;
        this.form.to = this.payload.to?.split(',').map((value) => ({
          key: '',
          value,
        })) || [];
      }
    },

    async submitForm() {
      const dto = {
        ...this.form,
        ...this.smtpConfig,
        to: this.form.to.map((r) => r.value).join(','),
      };

      const { data } = await this.updateDeliverySettings(
        this.processId,
        dto,
        DeliveriesNames.EmailSmtp,
      );
      return data;
    },
  },
};
</script>
