<script>
import { mapWritableState, mapActions } from 'pinia';
import { useProcessesStore, useProcessStepsStore } from '@/stores/index.js';
import SaveButton from '@/views/documents/processes/components/save-button.vue';
import StepLayout from '@/views/documents/processes/steps/step-layout.vue';
import UnsavedChanges from '@/components/modals/UnsavedChanges.vue';

export default {
  components: { SaveButton, StepLayout },
  props: {
    fullDescription: { type: String, required: true },
    description: { type: String, required: true },
    headerName: { type: String, default: '' },
    processId: { type: [String, Number], required: true },
    payload: { type: Object, default: null },
    stepId: { type: [String, Number], default: null },
    name: { type: [String, Number], required: true },
    icon: { type: [String, Array], required: true },
  },

  computed: {
    ...mapWritableState(useProcessStepsStore, ['validateStep']),
    ...mapWritableState(useProcessesStore, ['processIsEdited']),
  },

  watch: {
    '$v.form.$anyDirty': {
      handler(dirty) {
        this.processIsEdited = dirty;
      },
    },

    validateStep(value) {
      if (value === this.stepId && value !== null) {
        this.checkValidity();
        this.validateStep = null;
      }
    },
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },

  mounted() {
    this.setData();

    this.$v.form.$reset();
  },

  methods: {
    ...mapActions(useProcessStepsStore, ['validityChecked']),

    onValidityChecked(status) {
      const isValid = !status.errors;

      this.validityChecked({
        isInvalid: !isValid,
        isFinished: isValid,
      });

      return isValid;
    },

    deleteStep() {
      if (!this.form.id) {
        throw new Error('delviery id is not specified');
      } else {
        this.$emit('deleteStep', this.form.id);
      }
    },

    preventNav(event) {
      if (!this.$v.form.$anyDirty) {
        return;
      }

      event.preventDefault();
      event.returnValue = '';
    },

    reset() {
      if (this.form.id) {
        this.setData();
        this.$v.form.$reset();
      }
    },

    cancel() {
      this.reset();

      if (!this.form.id) {
        this.$emit('cancel');
      } else {
        this.$emit('navigatePrevious');
      }
    },

    isInvalid(element) {
      return element.$error && this.$v.form.$dirty;
    },

    async checkValidity() {
      if (this.$v.form.$anyDirty) {
        const response = await this.showUnsavedChangesModal();
        if (!response) return;
        if (response === 'reset') {
          this.reset();
        } else {
          this.$v.form.$reset();
        }
      }
      this.onValidityChecked({ errors: this.$v.form.$error });
    },

    async submit() {
      const isSave = await this.save();
      if (!isSave) return;
      this.$emit('navigateNext');
    },

    async save() {
      this.$v.form.$touch();

      if (this.$v.form.$invalid) return false;

      this.$v.form.$reset();

      if (!this.submitForm) {
        throw new Error('The implementation has to have submitForm method');
      }

      const data = await this.submitForm();

      this.$emit('submit', data);
      return true;
    },

    showUnsavedChangesModal() {
      return new Promise((resolve) => {
        this.$modal.show(
          UnsavedChanges,
          {
            onLeave: () => { resolve('reset'); },
            onSave: async () => {
              const isSaved = await this.save();
              if (isSaved) {
                resolve('saved');
              } else {
                resolve(false);
              }
            },
          },
          {
            draggable: '.modal-handler',
            clickToClose: true,
            width: '440px',
            height: 'auto',
            adaptive: true,
          },
          { closed: () => { resolve(false); } },
        );
      });
    },
  },
};
</script>
