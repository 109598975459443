<template>
  <div :class="$style.fields">
    <tokens
      v-model="item.name.$model"
      class="control is-expanded"
      position="is-top-left"
    >
      <input
        v-model="item.name.$model"
        class="input"
        type="text"
        placeholder="Name"
        :class="{ 'is-danger': item.name.$error }"
      >
    </tokens>

    <tokens
      v-model="item.email.$model"
      :class="[$style.fields__email, 'control is-expanded']"
      position="is-top-left"
    >
      <input
        v-model="item.email.$model"
        type="text"
        placeholder="Email"
        :class="['input', { 'is-danger': item.email.$error }]"
      >
    </tokens>
    <div :class="$style.controls">
      <div :class="[$style.controls__select, 'control']">
        <div class="select is-fullwidth">
          <select v-model="item.type.$model">
            <option
              v-for="option in recipientTypes"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="control">
        <button
          class="button"
          type="button"
          title="Signer PIN"
          @click="showPINModal()"
        >
          <span class="icon pin">
            <font-awesome-icon
              v-if="!!item.pin.$model"
              :icon="[ 'fal', 'lock' ]"
            />
            <font-awesome-icon
              v-else
              :icon="[ 'fal', 'unlock' ]"
            />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Tokens from '@/components/tokens';

export default {
  name: 'XodoSignRecipientItem',
  components: {
    Tokens,
  },
  props: {
    index: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: null,
    },
    recipientTypes: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    showPINModal() {
      this.$emit('showPINModal', this.index);
    },
  },
};
</script>

<style lang="scss" module>
.fields {
  display: flex;
  gap: 8px;
  flex-grow: 1;

  &__email {
    flex: 1 1 auto;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    flex-direction: row;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.controls {
  display: flex;
  gap: 8px;

  &__select {
    flex: 1 1 auto;
  }
}

  svg[data-icon="lock"] {
      filter:
        invert(27%) sepia(51%) saturate(2878%) hue-rotate(146deg) brightness(104%) contrast(97%);
  }
</style>
