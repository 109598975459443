<template>
  <confirm
    title="signNow app settings"
    :message="true"
    :on-submit="submit"
    :show-close-btn="true"
    :close-after-submit="false"
    @close="$emit('close')"
  >
    <form
      :class="$style.form"
      @submit.prevent="submit"
    >
      <b-field
        :type="invalidId ? 'is-danger' : ''"
        label="Client ID"
      >
        <b-input
          ref="firstField"
          v-model.trim="form.id"
          :disabled="loading"
        />
      </b-field>

      <b-field
        label="Client secret"
        :type="'is-danger'"
      >
        <p class="control has-icons-right">
          <input
            v-model="form.secret"
            :type="viewingSecret ? 'text':'password'"
            :class="['input', 'control', 'has-icons-right', invalidSecret ? 'is-danger' : '']"
          >
          <span
            class="icon is-clickable is-right"
            @mousedown="showSecret(true)"
            @mouseup="showSecret(false)"
            @mouseleave="showSecret(false)"
          >
            <font-awesome-icon
              :icon="[ 'fal', 'eye' ]"
              style="color: #000000;"
            />
          </span>
        </p>
      </b-field>

      <b-field
        v-if="email"
        label="Account"
      >
        <span>{{ email }}</span>
      </b-field>

      <b-field
        v-if="serverError"
        class="info"
      >
        <div class="error">
          {{ serverError }}
        </div>
      </b-field>
    </form>
  </confirm>
</template>

<script>
import Confirm from '@/components/modals/confirm.vue';
import autoFocus from '@/views/settings/mixins/autoFocus.js';
import { required } from '@/utils/validators.js';

export default {
  name: 'AppSettings',
  components: { Confirm },
  mixins: [autoFocus],
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    clientId: {
      type: String,
      default: '',
    },
    clientSecret: {
      type: String,
      default: '',
    },
    account: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      serverError: null,
      viewingSecret: false,
      email: this.account,
      form: {
        id: this.clientId,
        secret: this.clientSecret,
      },
    };
  },
  validations: {
    form: {
      id: {
        required,
      },
      secret: {
        required,
      },
    },
  },
  computed: {
    invalidSecret() {
      return this.$v.form.secret.$error && this.$v.form.$dirty;
    },
    invalidId() {
      return this.$v.form.id.$error && this.$v.form.$dirty;
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return false;

      const resp = await this.onSubmit(this.form);

      if (resp && resp.error) {
        this.serverError = resp.error;
        return;
      }

      this.$emit('close');
    },
    showSecret(val) {
      this.viewingSecret = val;
    },
  },
};
</script>
<style lang="scss" module>
.form {
  width: 100%;
}
</style>
