export const componentName = 'box-delivery-step';
export const type = 'Box';
export const name = 'Save to Box';
export const icon = 'service-box';
export const description = 'Save the result document to Box folder.';
export const fullDescription = 'Save the result document to Box folder. Learn more in <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/box.html" target="_blank">the documentation.</a>';

export const options = {
  type, name, icon, description, fullDescription,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: componentName,
    propsData: options,
  },
};
