<template>
  <div class="tree">
    <RecursiveNode :nodes="tags">
      <template slot-scope="props">
        <Tag
          :node="props.node"
          v-on="$listeners"
        />
      </template>
    </RecursiveNode>
  </div>
</template>

<script>
import RecursiveNode from './recursive-node';
import Tag from './tag';

export default {

  components: {
    Tag,
    RecursiveNode,
  },
  props: {
    tags: { type: Array, required: true },
  },
};
</script>

<style lang="scss" scoped>
.select.is-primary {
    select {
        border: 0px;
        outline: 0px;

        &:focus {
            box-shadow: none !important;
        }
    }
}
</style>
