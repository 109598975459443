<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div class="content">
        <div v-if="form.account">
          <account-info
            :name="form.account"
            @reconect="reconnect"
            @sign-out="signoutPopup"
          />

          <div class="field">
            <bulma-select
              v-model="$v.form.team.$model"
              :options="teams.map(x => x.name)"
              :disabled="teamsLoading"
              :loading="teamsLoading"
              @input="setChannels"
            >
              Team
            </bulma-select>
          </div>

          <div
            class="field"
          >
            <bulma-select
              v-model="$v.form.channel.$model"
              :options="channels.map(x => x.name)"
              :disabled="channelsLoading"
              :loading="channelsLoading"
            >
              Channel
            </bulma-select>
          </div>

          <div class="field">
            <label class="label">Message</label>
            <Tokens
              v-model="$v.form.message.$model"
              class="control"
              position="is-top-left"
            >
              <textarea
                v-model="$v.form.message.$model"
                class="textarea"
              />
            </Tokens>
          </div>
        </div>
        <div
          v-else
          class="content"
        >
          <a @click="signinPopup">Connect to Microsoft Teams</a>
        </div>
      </div>
    </template>
  </step-layout>
</template>

<script>
import axios from 'axios';
import Tokens from '@/components/tokens';
import BaseStep from '@/components/deliveries/base-delivery-step';
import {
  BulmaSelectComponent,
} from '@/components/basic';
import OAuthClient from '../../services/oauth-client';
import DeliveriesNames from '../deliveries-names';
import AccountInfo from '../../components/delivery/account-info';
import { apiEndpoint } from '@/utils/url-manager.js';

export default {
  name: 'TeamsDeliveryStep',
  components: {
    Tokens,
    AccountInfo,
    'bulma-select': BulmaSelectComponent,
  },
  extends: BaseStep,
  params: ['processId'],
  data() {
    return {
      form: {
        id: null,
        team: null,
        account: null,
        channel: null,
        message: null,
      },
      cacheId: null,
      oauthClient: null,

      authorize: null,
      clientId: null,
      scope: null,
      redirectUrl: null,
      teams: [],
      channels: [],
      teamsLoading: true,
      channelsLoading: true,
    };
  },
  watch: {
    '$v.form.team.$model': function () {
      this.setId('team');
    },
    '$v.form.channel.$model': function () {
      this.setId('channel');
    },
  },

  created() {
    this.oauthClient = new OAuthClient();
  },

  validations() {
    return {
      form: {
        team: {},
        channel: {},
        message: {},
      },
    };
  },

  async mounted() {
    const endpoint = apiEndpoint;

    const settings = (await axios.get(`${endpoint}auth/providers/${DeliveriesNames.Teams}`)).data;

    this.authorize = settings.authorizeEndpoint;
    this.clientId = settings.clientId;
    this.scope = settings.scope;
    this.redirectUrl = settings.redirectUrl;

    await this.setTeams();
  },

  methods: {
    getRequestParams() {
      let params = this.cacheId ? `cacheId=${this.cacheId}` : '';
      if (params !== '' && this.form.id) {
        params = `${params}&deliveryId=${this.form.id}`;
      } else if (this.form.id) {
        params = `deliveryId=${this.form.id}`;
      }
      return params;
    },
    async setTeams() {
      if (this.form.account) {
        this.teamsLoading = true;
        this.teams = (await this.$http.get(`${apiEndpoint}documents/processes/microsoft-teams/teams/${this.processId}?${this.getRequestParams()}`)).data;
        this.teamsLoading = false;

        this.form.team = this.form.team ? this.form.team : this.teams[0].name;

        await this.setChannels();
      }
    },
    async setChannels() {
      const team = this.teams.find((t) => t.name === this.form.team);
      this.channelsLoading = true;
      this.channels = (await this.$http.get(`${apiEndpoint}documents/processes/microsoft-teams/channels/${this.processId}/${team.id}?${this.getRequestParams()}`)).data;

      this.channelsLoading = false;

      const channel = this.channels.find((c) => c.name === this.form.channel);
      if (!this.form.channelId || !channel) {
        this.form.channel = this.channels[0].name;
        this.form.channelId = this.channels[0].id;
      } else {
        this.form.channel = channel.name;
        this.form.channelId = channel.id;
      }
    },

    setId(propName) {
      const obj = this[`${propName}s`].find((p) => p.name === this.form[propName]);
      if (obj && obj.id) {
        this.form[`${propName}Id`] = obj.id;
      }
    },

    async signinPopup() {
      await this.signoutPopup();

      const params = {
        prompt: 'login', client_id: this.clientId, redirect_uri: this.redirectUrl, scope: this.scope,
      };
      const accessData = await this.getAccessData(this.authorize, params, DeliveriesNames.Teams, this.oauthClient, true);
      if (accessData) {
        this.form.account = accessData.account;
        this.cacheId = accessData.cacheId;
        await this.setTeams();
      }
    },

    async signoutPopup() {
      if (!this.form.account) {
        return;
      }
      this.form.account = null;
    },

    async reconnect() {
      await this.signinPopup();
    },

    async submitForm() {
      return (await this.updateDeliverySettings(this.processId, { ...this.form, cacheId: this.cacheId }, 'microsoft-teams')).data;
    },

    setData() {
      if (this.payload) {
        this.form = { ...this.payload };
      }
    },
  },
};
</script>