<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div
        v-if="form.account"
        class="content"
      >
        <account-info
          :name="form.account"
          @reconect="reconnect"
          @sign-out="signoutPopup"
        />

        <div class="field">
          <label class="label level is-mobile">
            <span class="level-left">To</span>
            <div class="level-right">
              <span
                :class="[ 'tag', 'level-item', 'is-clickable', { 'is-primary' : isCcVisible } ]"
                @click="switchCc"
              >
                Cc
              </span>
              <span
                :class="[ 'tag', 'level-item', 'is-clickable', { 'is-primary' : isBccVisible } ]"
                @click="switchBcc"
              >
                Bcc
              </span>
            </div>
          </label>

          <tokens
            v-model="$v.form.to.$model"
            class="control"
          >
            <tags-input
              v-model="$v.form.to.$model"
              placeholder="Add new recipient"
              :class="{ 'is-danger': isInvalid($v.form.to) }"
              add-tags-on-comma="add-tags-on-comma"
              add-tags-on-blur="add-tags-on-blur"
            />
            <p
              v-if="isInvalid($v.form.to) && !$v.form.to.required"
              class="help is-danger"
            >
              This field must be filled
            </p>
            <p
              v-if="isInvalid($v.form.to) && $v.form.to.required && !$v.form.to.emails"
              class="help is-danger"
            >
              Please check that each entry is a valid email
            </p>
          </tokens>
        </div>

        <div
          v-if="isCcVisible"
          class="field"
        >
          <label class="label">Cc</label>
          <tokens
            v-model="$v.form.cc.$model"
            class="control"
          >
            <tags-input
              v-model="$v.form.cc.$model"
              placeholder="Add new recipient"
              :class="{ 'is-danger': isInvalid($v.form.cc) }"
              add-tags-on-comma="add-tags-on-comma"
              add-tags-on-blur="add-tags-on-blur"
            />
            <p
              v-if="isInvalid($v.form.cc) && !$v.form.cc.emails"
              class="help is-danger"
            >
              This field should be a valid email address
            </p>
          </tokens>
        </div>
        <div
          v-if="isBccVisible"
          class="field"
        >
          <label class="label">Bcc</label>
          <tokens
            v-model="$v.form.bcc.$model"
            class="control"
          >
            <tags-input
              v-model="$v.form.bcc.$model"
              placeholder="Add new recipient"
              :class="{ 'is-danger': isInvalid($v.form.bcc) }"
              add-tags-on-comma="add-tags-on-comma"
              add-tags-on-blur="add-tags-on-blur"
            />
            <p
              v-if="isInvalid($v.form.bcc) && !$v.form.bcc.emails"
              class="help is-danger"
            >
              This field should be a valid email address
            </p>
          </tokens>
        </div>
        <div class="field">
          <label class="label">Subject</label>
          <tokens
            v-model="$v.form.subject.$model"
            class="control"
          >
            <input
              v-model="$v.form.subject.$model"
              class="input"
              type="text"
              :class="{ 'is-danger': isInvalid($v.form.subject) }"
            >
            <p
              v-if="isInvalid($v.form.subject) && !$v.form.subject.required"
              class="help is-danger"
            >
              This field must be filled
            </p>
          </tokens>
        </div>
        <email-editor
          v-if="initialized"
          v-model="$v.form.content.$model"
          one-line="oneLine"
        />
        <p
          v-if="isInvalid($v.form.content) && !$v.form.content.required"
          class="help is-danger"
        >
          The content must be specified
        </p>
        <advanced-settings :is-visible="isAdvanceVisible">
          <div class="field">
            <label class="label">Display name</label>
            <tokens
              v-model="$v.form.displayName.$model"
              class="control"
              position="is-top-left"
            >
              <input
                v-model="$v.form.displayName.$model"
                class="input"
                type="text"
                :placeholder="form.accountName"
              >
            </tokens>
          </div>
          <div class="field">
            <label class="label">Reply-to address</label>
            <tokens
              v-model="$v.form.replyTo.$model"
              class="control"
              position="is-top-left"
            >
              <input
                v-model="$v.form.replyTo.$model"
                class="input"
                type="text"
                :placeholder="form.account"
              >
            </tokens>
          </div>
        </advanced-settings>
      </div>

      <div
        v-else
        class="content"
      >
        <a @click="signinPopup">
          Connect to Gmail
        </a>
      </div>
    </template>
  </step-layout>
</template>

<script>
import { required, email, helpers } from 'vuelidate/lib/validators';
import axios from 'axios';
import TagsInput from '@voerro/vue-tagsinput';
import EmailEditor from '@/components/rich-text-editor/index.vue';
import Tokens from '@/components/tokens.vue';
import BaseStep from '@/components/deliveries/base-delivery-step.vue';
import advancedSettings from '../../components/delivery/advancedSettings/advanced-settings';
import AccountInfo from '../../components/delivery/account-info';
import DeliveriesNames from '../deliveries-names';
import OAuthClient from '../../services/oauth-client';
import { apiEndpoint } from '@/utils/url-manager.js';

const token = helpers.regex('token', /{{.*}}/);

export default {
  name: 'GmailDeliveryStep',
  components: {
    EmailEditor,
    TagsInput,
    advancedSettings,
    Tokens,
    AccountInfo,
  },
  extends: BaseStep,
  params: ['processId'],
  data() {
    return {
      form: {
        id: null,
        account: null,
        accessToken: null,
        to: [],
        cc: null,
        bcc: null,
        subject: null,
        content: null,
        replyTo: null,
        displayName: null,
        accountName: null,
      },
      cacheId: null,
      oauthClient: null,

      authorize: null,
      clientId: null,
      scope: null,
      redirectUrl: null,
      logout: null,
      isAdvanceVisible: false,
    };
  },
  computed: {
    isCcVisible() {
      return this.form.cc !== null;
    },

    isBccVisible() {
      return this.form.bcc !== null;
    },
  },
  validations() {
    const defaultValidation = {
      form: {
        to: {
          required,
          $each: {
            emails(entry) {
              return email(entry.value) || token(entry.value);
            },
          },
        },
        subject: { required },
        content: { required },
        replyTo: {},
        displayName: {},
      },
    };

    if (this.isCcVisible) {
      defaultValidation.form = {
        ...defaultValidation.form,
        cc: {
          $each: {
            emails(entry) {
              return email(entry.value) || token(entry.value);
            },
          },
        },
      };
    }

    if (this.isBccVisible) {
      defaultValidation.form = {
        ...defaultValidation.form,
        bcc: {
          $each: {
            emails(entry) {
              return email(entry.value) || token(entry.value);
            },
          },
        },
      };
    }

    return defaultValidation;
  },

  watch: {
    'form.displayName': {
      handler(value) {
        if (value && value.value !== '') {
          this.isAdvanceVisible = true;
        }
      },
    },

    '$v.form.to.$model': {
      handler(value, previos) {
        if (value.value === previos.value) {
          this.$v.form.to.$reset();
        }
      },
    },

    '$v.form.cc.$model': {
      handler(value, previos) {
        if (value && !previos) {
          this.$v.form.cc.$reset();
        }

        if (value && previos) {
          if (value.value === previos.value) {
            this.$v.form.cc.$reset();
          }
        }
      },
    },

    '$v.form.bcc.$model': {
      handler(value, previos) {
        if (value && !previos) {
          this.$v.form.bcc.$reset();
        }

        if (value && previos) {
          if (value.value === previos.value) {
            this.$v.form.bcc.$reset();
          }
        }
      },
    },
  },

  created() {
    this.oauthClient = new OAuthClient();
  },

  async mounted() {
    const settings = await this.getSettings(DeliveriesNames.Gmail);

    this.authorize = settings.authorizeEndpoint;
    this.clientId = settings.clientId;
    this.scope = settings.scope;
    this.redirectUrl = settings.redirectUrl;
    this.logout = settings.logoutEndpoint;
  },

  methods: {
    setData() {
      this.initialized = true;

      if (this.payload) {
        this.form.account = this.payload.account;
        this.form.id = this.payload.id;

        this.form.to = this.payload.to ? this.payload.to.split(',').map((value) => ({
          key: '',
          value,
        })) : [];

        this.form.cc = this.payload.cc
          ? this.payload.cc.split(',').map((value) => ({
            key: '',
            value,
          }))
          : null;

        this.form.bcc = this.payload.bcc
          ? this.payload.bcc.split(',').map((value) => ({
            key: '',
            value,
          }))
          : null;

        this.form.subject = this.payload.subject;
        this.form.content = this.payload.content;

        this.form.replyTo = this.payload.replyTo;
        this.form.displayName = this.payload.displayName;

        if (!!this.form.displayName || !!this.form.replyTo) {
          this.isAdvanceVisible = true;
        }
      }
    },

    async signinPopup() {
      await this.signoutPopup();
      const params = {
        prompt: 'consent', access_type: 'offline', client_id: this.clientId, scope: this.scope, redirect_uri: this.redirectUrl,
      };
      const accessData = await this.getAccessData(
        this.authorize, params, DeliveriesNames.Gmail, this.oauthClient, true,
      );
      if (accessData) {
        this.form.account = accessData.account;
        this.form.accountName = accessData.name;
        this.form.accessToken = accessData.accessToken;
        this.cacheId = accessData.cacheId;
      }
    },

    async signoutPopup() {
      const endpoint = apiEndpoint;

      if (!this.form.account) {
        return;
      }
      this.form.account = null;
      let { accessToken } = this.form;

      if (this.form.id) {
        accessToken = (await axios.get(`${endpoint}auth/access?processId=${this.processId}&deliveryId=${this.form.id}`)).data;
      }

      const instance = axios.create({
        headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      });

      await instance.post(`https://oauth2.googleapis.com/revoke?token=${accessToken}`);
    },

    switchCc() {
      this.form.cc = this.isCcVisible ? null : [];
    },

    switchBcc() {
      this.form.bcc = this.isBccVisible ? null : [];
    },

    async submitForm() {
      const dto = {
        ...this.form,
        to: this.form.to.map((r) => r.value).join(','),
        cc: this.form.cc ? this.form.cc.map((r) => r.value).join(',') : null,
        bcc: this.form.bcc ? this.form.bcc.map((r) => r.value).join(',') : null,
      };

      return (await this.updateDeliverySettings(
        this.processId, { ...dto, cacheId: this.cacheId }, DeliveriesNames.Gmail,
      )).data;
    },

    async reconnect() {
      await this.signinPopup();
    },
  },
};
</script>