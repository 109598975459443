<template>
  <confirm
    :message="true"
    submit-btn-text="Proceed"
    close-btn-text="Cancel"
    :on-submit="submit"
    @close="$emit('close')"
  >
    <div :class="$style.wrapper">
      <p
        v-if="show"
        :class="$style.text"
      >
        The default form is not customizable but
        you can create a copy of it and start customizing it.
      </p>
      <p
        v-if="nolicence"
        :class="$style.text"
      >
        <b>Note:</b> You are starting using <a
          href="https://plumsail.com/forms/"
          target="_blank"
        >Plumsail Forms</a> with a free <a
          href="https://plumsail.com/forms/store/public-forms/"
          target="_blank"
        >Scooter plan</a>. You can switch to a higher plan anytime.
      </p>
    </div>
  </confirm>
</template>

<script>
import Confirm from '@/components/modals/confirm.vue';

export default {
  components: { Confirm },
  props: {
    show: { type: Boolean, default: false },
    nolicence: { type: Boolean, default: false },
    onSubmit: { type: Function, required: true },
  },

  methods: {
    submit() {
      this.onSubmit();
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" module>
.wrapper {
  padding-top: 10px;
}
.text {
  &:not(:last-child) {
    margin-top: 0;
    margin-bottom: 10px;
  }
}
</style>
