export const powerShellScript = `$JSON = @'
{json}
'@
$Params = @{
  ContentType = 'application/json;charset=utf-8'
  Method = 'Post'
  URI = '{url}'
  Body = $JSON
}
Invoke-RestMethod @Params`

export const curlScript = `curl -X POST \\
  '{url}' \\
  -H 'Content-Type: application/json;charset=utf-8' \\
  -d '{json}'`
