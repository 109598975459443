export const componentName = 'dropbox-delivery-step';
export const type = 'Dropbox';
export const name = 'Save to Dropbox';
export const icon = 'service-dropbox';
export const description = 'Save the result document to Dropbox folder.';
export const fullDescription = 'Save the result document to Dropbox folder. Learn more in <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/dropbox.html" target="_blank">the documentation.</a>';

export const options = {
  type, name, icon, description, fullDescription,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: componentName,
    propsData: options,
  },
};
