<template>
    <Confirm
      :title="'App settings'"
      :close-after-submit="false"
      show-close-btn
      :message="true"
      :on-submit="submit"
      :submit-btn-type="!loading ? 'is-primary' : 'is-primary is-loading'"
      @close="$emit('close')"
    >
      <form
        :class="$style.form"
        @submit.prevent="submit"
      >
        <b-field
          :type="invalidKey ? 'is-danger' : ''"
          label="Key"
          label-for="accessKey"
        >
          <b-input
            id="accessKey"
            v-model.trim="form.accessKey"
            :disabled="loading"
          />
        </b-field>
        <b-field
          :type="invalidSecretKey ? 'is-danger' : ''"
          label="Secret key"
          label-for="secretKey"
        >
          <div class="control has-icons-right">
            <input
              id="secretKey"
              v-model="form.secretKey"
              :type="viewedSecretKey ? 'text' : 'password'"
              :class="['input', 'control', 'has-icons-right', invalidSecretKey ? 'is-danger' : '']"
            >
            <span
              type="button"
              class="icon is-clickable is-right"
              @mousedown="showSecretKey(true)"
              @mouseup="showSecretKey(false)"
              @mouseleave="showSecretKey(false)"
            >
              <font-awesome-icon
                :icon="['fal', 'eye']"
                style="color: #000000;"
              />
            </span>
          </div>
          <p
            v-if="invalidSecretKey"
            class="help is-danger"
          >
            This field must be filled
          </p>
        </b-field>

        <bulma-select
                v-model="form.region"
                :options="regions"
                option-name="displayName"
                option-value="systemName"
                :loading = "loading"
              >
                Region
        </bulma-select>
      </form>
    </Confirm>
  </template>
  <script>
  import { mapActions } from 'pinia';
  import { useProcessesStore } from '@/stores/index.js';
  import Confirm from '@/components/modals/confirm.vue';
  import { required } from '@/utils/validators.js';
  import { BulmaSelectComponent, } from '@/components/basic/index.js';

  export default {
    name: 'AmazonSettingsModal',
    components: {
        Confirm,
        'bulma-select': BulmaSelectComponent,
    },
    props: {
      settings: { type: Object },
      updateSettings: { type: Function, required: true },
    },

    data() {
      return {
        form: {
          accessKey: null,
          secretKey: null,
          region: null,
        },
        loading: true,
        viewedSecretKey: false,
        regions: [],
      };
    },
    validations: {
      form: {
        accessKey: {
          required,
        },
        secretKey: {
          required,
        },
      },
    },
    computed: {
      invalidKey() {
        return this.$v.form.accessKey.$error && this.$v.form.$dirty;
      },
      invalidSecretKey() {
        return this.$v.form.secretKey.$error && this.$v.form.$dirty;
      },
      currentRegion: {
        get() {
          return this.form.region
        },
        set(value) {
          if (this.regions) {
            this.form.region = this.regions.find(x => x.systemName === value)?.systemName
          }
        },
      },
    },
    async beforeMount() {
      this.loading = true
      this.regions = (await this.getRegions()).data

      if (this.settings && this.settings.accessKey){
        this.form.accessKey = this.settings.accessKey;
        this.form.secretKey = this.settings.secretKey;
        this.form.region = this.settings.region;
      } else {
        //us-east-1 by default
        this.form.region = this.regions.find(x => x.systemName === 'us-east-1')?.systemName ?? this.regions[0]?.systemName
      }
      this.loading = false
    },
    methods: {
      ...mapActions(useProcessesStore, { getRegions: 'getAmazonRegions' }),

      async submit() {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.updateSettings(this.form);
        this.$emit('close');
      },
      showSecretKey(val) {
        this.viewedSecretKey = val;
      },
    },
  };
  </script>
  <style lang="scss" module>
  .form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 auto;
  }
  </style>
