<template>
    <confirm
      title="Do you plan sending attachments larger than 4 MB?"
      :submitBtnText = "'Connect'"
      :message="true"
      :on-submit="submit"
      :show-close-btn="true"
      @close="$emit('close')"
    >
      <form
        @submit.prevent="submit"
      >
        <bulma-switch
          v-model="largeFilesRequired"
          :captions = "['Yes', 'No']"
        /> 
        <div v-show="largeFilesRequired === 'Yes'" :class="$style['warning-box']">
            <div :class="$style.verticalLine"></div>   
            <div :class="$style['text-box']"> 
                <span>Requires additional read/write permissions to mailbox</span>
            </div>
        </div>

      </form>
    </confirm>
  </template>
  
  <script>  
  import Confirm from '@/components/modals/confirm.vue';
  import { BulmaSwitchComponent } from '@/components/basic/index.js';

  export default {
    name: 'Consent',
    components: { 
        Confirm,
        'bulma-switch': BulmaSwitchComponent,
     },
     props: {
        onSubmit: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
          largeFilesRequired: 'No',
        };
    },
    methods: {
      async submit() {  
        await this.onSubmit(this.largeFilesRequired);  
        this.$emit('close');
      }
    },
  };
  </script>
  
  <style lang="scss" module>
    .warning-box {
        display: flex;
        max-height: 48px; 
        background-color: #F5F5F5;

        .text-box {
            width: 426px;
            padding: 12px 10px 12px 20px;

            span {
                font-weight: 400; 
                font-size:14px; 
                line-height: 24px;
            }
        }
    }
    .verticalLine {
        width: 4px; 
        background-color: var(--color-documents); 
        border-radius: 4px 0px 0px 4px;
    }
  </style>