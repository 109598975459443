<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div
        v-if="form.account"
        class="content"
      >
        <account-info
          :name="form.account"
          @reconect="reconnect"
          @sign-out="signoutPopup"
        />
        <div class="field">
          <label class="label">Folder</label>
          <tokens
            v-model="$v.form.folder.$model"
            class="control"
            position="is-top-left"
          >
            <input
              v-model="$v.form.folder.$model"
              class="input"
              type="text"
              :class="{ 'is-danger': isInvalid($v.form.folder) }"
            >
            <p
              v-if="isInvalid($v.form.folder)"
              class="help is-danger"
            >
              Please enter a name that doesn't include any special
              (<span class="has-background-light is-family-code">" * : &lt; > ? \ |</span>)
              characters or end with "."
            </p>
          </tokens>
        </div>
      </div>

      <div
        v-else
        class="content"
      >
        <a @click="signinPopup">Connect to OneDrive</a>
      </div>
    </template>
  </step-layout>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import { matchFolderName } from '@/utils/validators.js';
import Tokens from '@/components/tokens.vue';
import BaseStep from '@/components/deliveries/base-delivery-step.vue';
import DeliveriesNames from '../deliveries-names';
import OAuthClient from '../../services/oauth-client';
import AccountInfo from '../../components/delivery/account-info';
import { apiEndpoint } from '@/utils/url-manager.js';

export default {
  name: 'OnedriveDeliveryStep',
  components: {
    Tokens,
    AccountInfo,
  },
  extends: BaseStep,
  data() {
    return {
      form: {
        id: null,
        account: null,
        folder: null,
      },
      cacheId: null,
      oauthClient: null,
      authorize: null,
      clientId: null,
      scope: null,
      redirectUrl: null,
      logout: null,
    };
  },

  created() {
    this.oauthClient = new OAuthClient();
  },

  validations() {
    const defaultValidation = {
      form: {
        account: { required },
        folder: { matchFolderName },
      },
    };

    return defaultValidation;
  },

  async mounted() {
    const endpoint = apiEndpoint;

    const settings = (await axios.get(`${endpoint}auth/providers/${DeliveriesNames.OneDrive}`)).data;

    this.authorize = settings.authorizeEndpoint;
    this.clientId = settings.clientId;
    this.scope = settings.scope;
    this.redirectUrl = settings.redirectUrl;
    this.logout = settings.logoutEndpoint;
  },

  methods: {
    async signinPopup() {
      await this.signoutPopup();

      const params = {
        prompt: 'login', client_id: this.clientId, scope: this.scope, redirect_uri: this.redirectUrl,
      };
      const accessData = await this.getAccessData(
        this.authorize, params, DeliveriesNames.OneDrive, this.oauthClient, true,
      );
      if (accessData) {
        this.form.account = accessData.account;
        this.cacheId = accessData.cacheId;
      }
    },

    async signoutPopup() {
      const url = `${this.logout}?client_id=${this.clientId}&redirect_uri=${this.redirectUrl}`;

      await this.oauthClient.showPopup(url, async (event) => {
        if (event.data && event.data.indexOf('?lc=') !== -1) {
          this.form.account = null;

          return true;
        }

        return false;
      });
    },

    async reconnect() {
      await this.signoutPopup();
      await this.signinPopup();
    },
    async submitForm() {
      return (await this.updateDeliverySettings(
        this.processId, { ...this.form, cacheId: this.cacheId }, DeliveriesNames.OneDrive,
      )).data;
    },
    setData() {
      if (this.payload) {
        this.form.id = this.payload.id;
        this.form.account = this.payload.account;
        this.form.folder = this.payload.folder;
      }
    },
  },
};
</script>