<template>
  <div class="field is-grouped wrapper">
    <div class="contorol inner">
      <div class="control with-label">
        <input
          :id="id"
          v-model="item.$model"
          class="switch is-rounded"
          type="checkbox"
        >
        <label
          class="advanced-settings-switch"
          :for="id"
        >
          {{ labelText }}
        </label>
      </div>

      <div
        v-if="!!tooltipMsg"
        class="control"
      >
        <v-popover
          placement="right"
          offset="20"
          trigger="hover"
        >
          <font-awesome-icon :icon="[ 'fal', 'circle-info' ]" />
          <template slot="popover">
            <div class="box">
              {{ tooltipMsg }}
            </div>
          </template>
        </v-popover>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'AdvancedSettingsItemEmpty',
  props: {
    id: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    tooltipMsg: {
      type: String,
      default: '',
    },
    labelText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
    .wrapper {
      align-items: center;
    }

    .inner {
      margin-right: 10px;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        flex-basis: 230px;
      }
    }
    .box{
        max-width: 370px;
    }

    .field.is-grouped > .control {
      flex-shrink: 1;
    }

    .with-label {
      margin-right: 12px;
    }

    label.advanced-settings-switch {
      padding-top: 0;
    }
</style>
