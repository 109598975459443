<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div
        v-if="form.account"
        class="content"
      >
        <account-info
          :name="form.account"
          @reconect="reconnect"
          @sign-out="signoutPopup"
        />

        <div class="field">
          <label class="label level is-mobile"><span class="level-left">To</span>
            <div class="level-right">
              <span
                :class="[ 'tag', 'level-item', 'is-clickable', { 'is-primary' : isCcVisible } ]"
                @click="switchCc"
              >
                Cc
              </span>
              <span
                :class="[ 'tag', 'level-item', 'is-clickable', { 'is-primary' : isBccVisible } ]"
                @click="switchBcc"
              >
                Bcc
              </span>
            </div>
          </label>
          <tokens
            v-model="$v.form.to.$model"
            class="control"
          >
            <tags-input
              v-model="$v.form.to.$model"
              placeholder="Add new recipient"
              :class="{ 'is-danger': isInvalid($v.form.to) }"
              add-tags-on-comma="add-tags-on-comma"
              add-tags-on-blur="add-tags-on-blur"
            />
            <p
              v-if="isInvalid($v.form.to) && !$v.form.to.required"
              class="help is-danger"
            >
              This field must be filled
            </p>
            <p
              v-if="isInvalid($v.form.to) && $v.form.to.required && !$v.form.to.emails"
              class="help is-danger"
            >
              Please check that each entry is a valid email
            </p>
          </tokens>
        </div>

        <div
          v-if="isCcVisible"
          class="field"
        >
          <label class="label">Cc</label>
          <tokens
            v-model="$v.form.cc.$model"
            class="control"
          >
            <tags-input
              v-model="$v.form.cc.$model"
              placeholder="Add new recipient"
              :class="{ 'is-danger': isInvalid($v.form.cc) }"
              add-tags-on-comma="add-tags-on-comma"
              add-tags-on-blur="add-tags-on-blur"
            />
            <p
              v-if="isInvalid($v.form.cc) && !$v.form.cc.emails"
              class="help is-danger"
            >
              This field should be a valid email address
            </p>
          </tokens>
        </div>

        <div
          v-if="isBccVisible"
          class="field"
        >
          <label class="label">Bcc</label>
          <tokens
            v-model="$v.form.bcc.$model"
            class="control"
          >
            <tags-input
              v-model="$v.form.bcc.$model"
              placeholder="Add new recipient"
              :class="{ 'is-danger': isInvalid($v.form.bcc) }"
              add-tags-on-comma="add-tags-on-comma"
              add-tags-on-blur="add-tags-on-blur"
            />
            <p
              v-if="isInvalid($v.form.bcc) && !$v.form.bcc.emails"
              class="help is-danger"
            >
              This field should be a valid email address
            </p>
          </tokens>
        </div>

        <div class="field">
          <label class="label">Subject</label>
          <tokens
            v-model="$v.form.subject.$model"
            class="control"
          >
            <input
              v-model="$v.form.subject.$model"
              class="input"
              type="text"
              :class="{ 'is-danger': isInvalid($v.form.subject) }"
            >
            <p
              v-if="isInvalid($v.form.subject) && !$v.form.subject.required"
              class="help is-danger"
            >
              This field must be filled
            </p>
          </tokens>
        </div>

        <email-editor
          v-if="initialized"
          v-model="$v.form.content.$model"
          one-line="oneLine"
        />

        <p
          v-if="isInvalid($v.form.content) && !$v.form.content.required"
          class="help is-danger"
        >
          The content must be specified
        </p>

        <advanced-settings :is-visible="isAdvanceVisible">
          <div class="field">
            <label class="label">Reply-to address</label>
            <tokens
              v-model="$v.form.replyTo.$model"
              class="control"
              position="is-top-left"
            >
              <input
                v-model="$v.form.replyTo.$model"
                class="input"
                type="text"
                :placeholder="form.account"
              >
            </tokens>
          </div>
        </advanced-settings>
      </div>

      <div
        v-else
        class="content"
      >
        <a @click="getConsent">Connect to Outlook</a>
      </div>
    </template>
  </step-layout>
</template>

<script>
import { required, email, helpers } from 'vuelidate/lib/validators';
import TagsInput from '@voerro/vue-tagsinput';
import BaseStep from '@/components/deliveries/base-delivery-step.vue';
import EmailEditor from '@/components/rich-text-editor/index.vue';
import AdvancedSettings from '../../components/delivery/advancedSettings/advanced-settings.vue';
import AccountInfo from '../../components/delivery/account-info';
import OAuthClient from '../../services/oauth-client';
import DeliveriesNames from '../deliveries-names';
import Tokens from '@/components/tokens.vue';
import ConsentModal from './consent-modal.vue';
import { apiEndpoint } from '@/utils/url-manager.js';

const token = helpers.regex('token', /{{.*}}/);
export default {
  name: 'OutlookDeliveryStep',

  components: {
    EmailEditor,
    TagsInput,
    AdvancedSettings,
    Tokens,
    AccountInfo,
    ConsentModal
  },

  extends: BaseStep,

  params: ['processId'],

  data() {
    return {
      form: {
        id: null,
        account: null,
        accessToken: null,
        to: [],
        cc: null,
        bcc: null,
        subject: null,
        content: null,
        replyTo: null,
        accountName: null,
        largeFiles: false,
      },
      cacheId: null,
      oauthClient: null,

      isAdvanceVisible: false,
    };
  },
  computed: {
    isCcVisible() {
      return this.form.cc !== null;
    },

    isBccVisible() {
      return this.form.bcc !== null;
    },
  },
  validations() {
    const defaultValidation = {
      form: {
        to: {
          required,
          $each: {
            emails(entry) {
              return email(entry.value) || token(entry.value);
            },
          },
        },
        subject: { required },
        content: { required },
        replyTo: {},
      },
    };

    if (this.isCcVisible) {
      defaultValidation.form = {
        ...defaultValidation.form,
        cc: {
          $each: {
            emails(entry) {
              return email(entry.value) || token(entry.value);
            },
          },
        },
      };
    }

    if (this.isBccVisible) {
      defaultValidation.form = {
        ...defaultValidation.form,
        bcc: {
          $each: {
            emails(entry) {
              return email(entry.value) || token(entry.value);
            },
          },
        },
      };
    }

    return defaultValidation;
  },

  watch: {
    '$v.form.to.$model': {
      handler(obj, oldObj) {
        if (obj.value === oldObj.value) {
          this.$v.form.to.$reset();
        }
      },
    },

    '$v.form.cc.$model': {
      handler(obj, oldObj) {
        if (obj && !oldObj) {
          this.$v.form.cc.$reset();
        }

        if (obj && oldObj) {
          if (obj.value === oldObj.value) {
            this.$v.form.cc.$reset();
          }
        }
      },
    },

    '$v.form.bcc.$model': {
      handler(obj, oldObj) {
        if (obj && !oldObj) {
          this.$v.form.bcc.$reset();
        }

        if (obj && oldObj) {
          if (obj.value === oldObj.value) {
            this.$v.form.bcc.$reset();
          }
        }
      },
    },
  },

  created() {
    this.oauthClient = new OAuthClient();
  },

  methods: {
    setData() {
      this.initialized = true;

      if (this.payload) {
        this.form.account = this.payload.account;
        this.form.id = this.payload.id;

        this.form.to = this.payload.to ? this.payload.to.split(',').map((value) => ({
          key: '',
          value,
        })) : [];

        this.form.cc = this.payload.cc
          ? this.payload.cc.split(',').map((value) => ({
            key: '',
            value,
          }))
          : null;

        this.form.bcc = this.payload.bcc
          ? this.payload.bcc.split(',').map((value) => ({
            key: '',
            value,
          }))
          : null;

        this.form.subject = this.payload.subject;
        this.form.content = this.payload.content;

        this.form.replyTo = this.payload.replyTo;

        if (!!this.form.replyTo) {
          this.isAdvanceVisible = true;
        }

        this.form.largeFiles = this.payload.largeFiles;
      }
    },

    async getSettingsInternal(){
      return (await this.$http.get(`${apiEndpoint}documents/processes/outlook/auth/settings/?largeFiles=${this.form.largeFiles}`)).data;
    },

    getConsent(){
      this.modal = this.$modal.show(
        ConsentModal,
        {
          onSubmit: this.signinPopup,
        },
        {
          clickToClose: false,
          adaptive: true,
          width: '470px',
          height: 'auto',
          draggable: '.modal-handler',
        },
      );
    },

    async signinPopup(largeFiles) {
      this.form.largeFiles = largeFiles === 'Yes' ? true : false;

      await this.signoutPopup();

      const {
        authorizeEndpoint,
        clientId,
        scope,
        redirectUrl } = await this.getSettingsInternal();

      const params = {
        prompt: 'login', client_id: clientId, scope: scope, redirect_uri: redirectUrl,
      };
      const accessData = await this.getAccessData(
        authorizeEndpoint, params, DeliveriesNames.Outlook, this.oauthClient, true,
      );
      if (accessData) {
        this.form.account = accessData.account;
        this.form.accountName = accessData.name;
        this.form.accessToken = accessData.accessToken;
        this.cacheId = accessData.cacheId;
      }
    },

    async signoutPopup() {
      if (!this.form.account) {
        return;
      }
      this.form.account = null;
    },

    switchCc() {
      this.form.cc = this.isCcVisible ? null : [];
    },

    switchBcc() {
      this.form.bcc = this.isBccVisible ? null : [];
    },

    async submitForm() {
      return (await this.updateDeliverySettings(this.processId, {
        ...{
          ...this.form,
          to: this.form.to.map((r) => r.value).join(','),
          cc: this.form.cc ? this.form.cc.map((r) => r.value).join(',') : null,
          bcc: this.form.bcc ? this.form.bcc.map((r) => r.value).join(',') : null,
        },
        cacheId: this.cacheId,
      }, DeliveriesNames.Outlook)).data;
    },

    async reconnect() {
      await this.getConsent();
    },
  },
};
</script>
<style lang="scss" module>
.accountInfo {
  display: flex;
  &__right {
    display: flex;
    margin-left: auto;
  }
  &__item {
    &:not(:last-child) {
      margin-right: 0.75rem;
    }
  }
}
</style>
