<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div
        class="content"
      >
        <b-button
          v-if="isUserApp"
          @click="changeAppSettings"
        >
          <font-awesome-icon
            :icon="['fal', 'gears']"
            style="margin-left: -5px;"
          />
          App settings
        </b-button>
      </div>
      <div
        v-if="form.account"
        class="content"
      >
        <account-info
          v-if="!isUserApp"
          :name="form.account"
          @reconect="reconnect"
          @sign-out="signoutPopup"
        />
        <label class="label">Email subject</label>
        <div class="field">
          <tokens
            v-model="$v.form.emailSubject.$model"
            class="control"
          >
            <input
              v-model="$v.form.emailSubject.$model"
              class="input"
              type="text"
              :class="{ 'is-danger': isInvalid($v.form.emailSubject) }"
            >
            <p
              v-if="isInvalid($v.form.emailSubject)"
              class="help is-danger"
            >
              Please fill email subject
            </p>
          </tokens>
        </div>
        <label class="label">Email message</label>
        <div class="field">
          <tokens
            v-model="$v.form.emailMessage.$model"
            class="control"
          >
            <textarea
              v-model="$v.form.emailMessage.$model"
              :class="[
                'input',
                $style.input,
                { 'is-danger': isInvalid($v.form.emailMessage) }
              ]"
              :rows="4"
            />
            <p
              v-if="isInvalid($v.form.emailMessage)"
              class="help is-danger"
            >
              Please fill email message
            </p>
          </tokens>
        </div>

        <label class="label">Recipients</label>
        <div class="field recipients">
          <recipients
            :recipients="$v.form.recipients.$each.$iter"
            :types="recipientTypes"
            @updateRecipients="updateRecipients"
            @deleteRecipient="deleteRecipient"
          >
            <template #default="{item, index, recipientTypes}">
              <recipient-item
                :item="item"
                :index="index"
                :recipient-types="recipientTypes"
                :settings="{namePlaceholder:'Role'}"
              />
            </template>
          </recipients>
        </div>

        <text-button
          title="Add recipient"
          icon="plus"
          @click="addRecipient"
        />

        <advanced-settings>
          <advanced-settings-item-empty
            id="sequentialSigning"
            :item="$v.form.sequentialSigning"
            :tooltip-msg="tooltipMsg"
            label-text="Sequential signing"
          />
          <advanced-settings-item-number
            id="specifyExpiration"
            :checked="$v.form.specifyExpiration"
            label-text="Specify expiration"
            :number="$v.form.specifyExpirationCount"
            number-label-text="days"
          />
          <advanced-settings-item-number
            id="sendReminders"
            :checked="$v.form.sendReminders"
            :label-text="$v.form.sendReminders.$model ? 'Send reminders every' : 'Send reminders'"
            :number="$v.form.sendRemindersCount"
            number-label-text="days"
          />
        </advanced-settings>
      </div>
    </template>
  </step-layout>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import { matchEmailOrTemplateOrListOfEmails, validateNameBasedOnEmail } from '@/utils/validators.js';
import Tokens from '@/components/tokens.vue';
import BaseStep from '@/components/deliveries/base-delivery-step.vue';
import DeliveriesNames from '../deliveries-names';
import OAuthClient from '../../services/oauth-client';
import recipients from '../../components/delivery/recipients';
import recipientItem from '../../components/delivery/recipient-item';
import advancedSettings from '../../components/delivery/advancedSettings/advanced-settings';
import advancedSettingsItemEmpty from '../../components/delivery/advancedSettings/advanced-settings-item-empty';
import advancedSettingsItemNumber from '../../components/delivery/advancedSettings/advanced-settings-item-number';
import AccountInfo from '../../components/delivery/account-info';
import textButton from '../../components/text-button.vue';
import Confirm from '@/components/modals/confirm.vue';
import AppSettingsModal from './app-settings-modal.vue';
import { apiEndpoint } from '@/utils/url-manager.js';

export default {
  name: 'SignnowDeliveryStep',
  components: {
    recipients,
    recipientItem,
    advancedSettings,
    advancedSettingsItemEmpty,
    advancedSettingsItemNumber,
    Tokens,
    AccountInfo,
    textButton,
  },
  extends: BaseStep,
  params: ['processId'],
  data() {
    return {
      form: {
        id: null,
        account: null,
        accessToken: null,
        emailSubject: null,
        emailMessage: null,
        clientSecret: null,
        encryptedSecret: null,
        recipients: [
          {
            name: '',
            email: '',
            type: 1,
            order: 1,
          },
        ],
        sequentialSigning: false,
        sendReminders: false,
        sendRemindersCount: 1,
        specifyExpiration: false,
        specifyExpirationCount: 7,
      },
      tooltipMsg:
                    'By default recipients sign documents parallelly. Enable this option to use sequential signing. Then drag and drop recipients above to change their order',
      cacheId: null,
      oauthClient: null,
      authorize: null,
      redirectUrl: null,
      logout: null,
      recipientTypes: [
        { id: 1, name: 'Needs to sign' },
        { id: 2, name: 'Receives a copy' },
      ],
      isAdvanceVisible: false,
      isUserApp: false,
    };
  },

  watch: {
    'form.recipients': function (newVal) {
      if (newVal.length === 0) {
        this.form.recipients.push({
          name: '', email: '', type: 1, order: 1,
        });
      } else {
        for (let index = 0; index < this.form.recipients.length; index += 1) {
          this.form.recipients[index].order = index + 1;
        }
      }
    },
  },

  created() {
    this.oauthClient = new OAuthClient();
  },

  validations() {
    const defaultValidation = {
      form: {
        emailSubject: { required },
        emailMessage: { required },
        recipients: {
          $each: {
            name: { validateNameBasedOnEmail },
            email: { matchEmailOrTemplateOrListOfEmails },
            type: {},
            order: {},
          },
        },
        sequentialSigning: {},
        sendReminders: {},
        sendRemindersCount: {},
        specifyExpiration: {},
        specifyExpirationCount: {},
      },
      recipientTypes: [],
    };

    return defaultValidation;
  },

  async mounted() {
    const params = `processId=${this.processId}&deliveryId=${this.form.id || ''}`;
    const { data } = await axios.get(`${apiEndpoint}documents/processes/signnow/auth/settings/?${params}`);

    this.isUserApp = data.hasUserApp;
    this.form.clientId = data.clientId;
    this.redirectUrl = data.redirectUrl;
    this.authorize = data.authorizeEndpoint;
    this.form.encryptedSecret = data.clientSecret;
  },

  methods: {
    getAccessDataInternal() {
      return new Promise((resolve) => {
        const url = `${this.authorize}?response_type=code&client_id=${this.form.clientId}&redirect_uri=${this.redirectUrl}`;

        this.oauthClient.showPopup(url, async (event) => {
          if (event.data !== Object(event.data) && event.data) {
            if (event.data.indexOf('code=') !== -1) {
              const url = new URL(`${event.origin}/${event.data}`);

              const code = url.searchParams.get('code');
              const state = url.searchParams.get('state');
              const params = `code=${code}&state=${state}&clientId=${this.form.clientId}&clientSecret=${this.form.clientSecret}`;
              try {
                const { data } = await axios.get(`${apiEndpoint}documents/processes/signnow/auth/connect?${params}`);
                resolve(data);
              } catch (error) {
                const msg = error.response?.data?.error?.message ?? 'Unknown error. Please contact support to solve this issue';
                resolve({ error: msg });
              }

              return true;
            }

            if (event.data && event.data.indexOf('error=') !== -1) {
              const url = new URL(`${event.origin}/${event.data}`);
              resolve({ error: url.searchParams.get('error') });
              return true;
            }
          }

          return false;
        });
      });
    },
    async setAppSettings(form) {
      this.form.clientId = form.id;
      this.form.clientSecret = form.secret;
      return await this.signinPopup();
    },
    changeAppSettings() {
      this.modal = this.$modal.show(
        AppSettingsModal,
        {
          onSubmit: this.setAppSettings,
          clientId: this.form.clientId,
          clientSecret: this.form.clientSecret,
          account: this.form.account,
        },
        {
          clickToClose: false,
          adaptive: true,
          width: '440px',
          height: 'auto',
          draggable: '.modal-handler',
        },
      );
    },
    async signinPopup() {
      await this.signoutPopup();
      if (!this.redirectUrl) { this.mounted(); }

      const accessData = await this.getAccessDataInternal();

      if (accessData.error) {
        return accessData;
      }

      if (accessData) {
        this.cacheId = accessData.cacheId;
        this.form.account = accessData.account;
        this.form.accessToken = accessData.accessToken;
        this.cacheId = accessData.cacheId;
      }
    },

    async signoutPopup() {
      if (!this.form.account) {
        return;
      }

      this.form.account = null;
    },

    async reconnect() {
      this.form.clientId = null;
      await this.changeAppSettings();
    },
    async submitForm() {
      return (
        await this.updateDeliverySettings(
          this.processId, { ...this.form, cacheId: this.cacheId }, DeliveriesNames.SignNow,
        )).data;
    },
    setData() {
      this.clearRecipients();

      if (this.payload) {
        this.form.id = this.payload.id;
        this.form.account = this.payload.account;
        this.form.sequentialSigning = this.payload.sequentialSigning;
        this.form.emailSubject = this.payload.emailSubject;
        this.form.emailMessage = this.payload.emailMessage;
        this.form.recipients = this.payload.recipients.map((value) => ({ ...value }));
        this.form.sendReminders = this.payload.sendReminders;
        this.form.sendRemindersCount = this.payload.sendRemindersCount;
        this.form.specifyExpiration = this.payload.specifyExpiration;
        this.form.specifyExpirationCount = this.payload.specifyExpirationCount;

        if (this.form.recipients && this.form.recipients.length === 0) {
          this.form.recipients.push({
            name: '', email: '', type: 1, order: 1,
          });
        }
      }
    },

    clearRecipients() {
      if (this.$v.form.recipients.$invalid && this.form.recipients.length > 0) {
        Object.keys(this.$v.form.recipients.$each).forEach((i) => {
          if (
            this.$v.form.recipients.$each.$iter[i]
            && this.$v.form.recipients.$each.$iter[i].$invalid
          ) {
            this.form.recipients.splice(i, 1);
          }
        });
        this.$v.form.$reset();
      }
    },

    addRecipient() {
      this.form.recipients.push({
        name: '', email: '', type: 1, order: 1,
      });
    },

    updateRecipients(newRecipients) {
      this.form.recipients = newRecipients;
    },

    deleteRecipient(index) {
      this.$modal.show(
        Confirm,
        {
          title: 'Delete recipient',
          message: 'Are you sure you want to delete this the recipient?',
          onSubmit: () => {
            this.form.recipients.splice(index, 1);
          },
          submitBtnText: 'Delete',
          showCloseBtn: true,
        },
        {
          draggable: '.modal-handler',
          clickToClose: true,
          width: '455px',
          height: 'auto',
          adaptive: true,
        },
      );
    },

    toggleAdvanced() {
      this.isAdvanceVisible = !this.isAdvanceVisible;
    },
  },
};
</script>

<style lang="scss" module>
textarea.input {
  height: 150px;
}
</style>