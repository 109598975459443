<template>
  <draggable
    v-model="currentOrderedArray"
    handle=".drag-area"
    @start="drag = true"
    @end="drag = false"
  >
    <slot />
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'DraggableWrapper',
  components: { draggable },
  props: {
    orderedArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentOrderedArray: this.orderedArray,
    };
  },
  watch: {
    currentOrderedArray() {
      this.$emit('reorder', this.currentOrderedArray);
    },
    orderedArray() {
      if (JSON.stringify(this.orderedArray) !== JSON.stringify(this.currentOrderedArray)) {
        this.currentOrderedArray = this.orderedArray;
      }
    },
  },
};
</script>
