<template>
  <div :class="$style.wrapper">
    <p v-if="formId === 'default-form'">
      This form is generated based on your template tokens. You can adjust
      how it looks. Find more information in
      <a
        href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/custom-testing-form.html"
        target="_blank"
        rel="noopener noreferrer"
      >the documentation</a>.
    </p>

    <div :class="$style.form">
      <div
        v-if="loading || formLoading"
        class="fd-form-loading"
      />
      <div v-else>
        <slot name="messages" />
        <div
          id="plumsail-form"
          ref="forms"
          :class="$style.widget"
        >
          <div class="fd-form-loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useProcessesStore, useSettingsStore } from '@/stores/index.js';
import formTestDataMixin from './mixins/fom-test-data-mixin.js';
import { ensureFormsScript } from '@/utils/index.js';

export default {
  mixins: [formTestDataMixin],
  props: {
    actionIndicator: { type: [String, null], default: null },
    googleDriveId: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    formId: { type: String, default: 'default-form' },
  },
  data() {
    return {
      fd: null,
      formLoading: false,
    };
  },
  computed: {
    ...mapState(useSettingsStore, { profile: 'getProfile' }),
    ...mapState(useProcessesStore, ['forms', 'processTestingMode', 'processId']),
    currentForm() {
      return this.forms.find((form) => form.formId === this.formId);
    },
  },
  watch: {
    loading(value) {
      if (!value) {
        this.getForm();
      }
    },

    formId() {
      this.formLoading = true;
      this.fd = null;
      this.getForm();
    },

    actionIndicator(value) {
      if (value === 'create') this.submit();
      if (value === 'cancel') this.cancel();
      this.$emit('update:actionIndicator', null);
    },
  },
  async created() {
    if (!this.loading && this.formId) {
      this.getForm();
    }
  },

  beforeDestroy() {
    // Update the value of the form fields without sending the form
    this.saveFormTestData();
    this.$emit('canceledTesting');
  },
  methods: {
    ...mapActions(useProcessesStore, ['getDefaultFormIds', 'testProcess']),

    cancel() {
      this.fd.callback = () => null;
      this.$emit('canceledTesting');
    },

    async getFormUid() {
      if (this.currentForm) return this.formId;
      const { data: { testingGuid } } = await this.getDefaultFormIds({ processId: this.processId });
      return testingGuid;
    },

    async getForm() {
      const id = await this.getFormUid();
      await ensureFormsScript();

      this.fd = new Plumsail.Form('#plumsail-form', id, {
        language: 'en-US',
        culture: 'en-US',
        theme: 'Documents',
      });
      if (this.fd) {
        this.fd.beforeCreate((data) => {
          if (data?.components?.['fd-submit-button']) {
            data.components['fd-submit-button'] = () => null;
          }
          this.fd._layout.css = `
            ${this.fd._layout.css}
            [class*="##submit##"] { display: none !important; }
            .fd-datatable-wrapper { width: 100% !important; }
          `;
          this.fd._showAlert = () => null;
          // eslint-disable-next-line no-underscore-dangle
          this.fd._notifySubscribers = false;
        });
      }

      this.fd.rendered(() => {
        this.fillFormWithTestData();
      });

      this.fd.beforeRender(() => {
        this.formLoading = false;
      });

      if (this.currentForm) {
        this.fd.beforeSave(async (data) => {
          const payload = {
            json: JSON.stringify(data),
            googleDriveId: this.fd.googleDriveId,
          };
          const { link, error } = await this.testProcess({
            processId: this.processId,
            userId: this.profile.sub,
            payload,
          });
          if (error) {
            this.$emit('failedTesting', error);
            return;
          }
          this.fd.callback(link);
        });
      }
    },

    submit() {
      this.saveFormTestData();

      this.$emit('startTesting');
      this.fd.callback = (link) => {
        if (!link || typeof link !== 'string') {
          this.$emit('failedTesting', 'Failed to test process');
          return;
        }
        this.$emit('succeedTesting', link);
      };
      this.fd.googleDriveId = this.googleDriveId;
      this.fd.createMode = this.processTestingMode;
      this.fd.save().then(() => {
        this.fillFormWithTestData();
      });
    },
  },
};
</script>
<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.form {
  flex: 1 1 auto;
  overflow: auto;

  &::-webkit-scrollbar {
  width: 10px;
  height: 10px;;
  }

  &::-webkit-scrollbar-track {
  background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
  border: 3px solid #fff;
  border-radius: 10px;
  background-color: #C0C0C0;
  }
}
.widget {
  width: 100%;
}
</style>
