<template>
  <div
    class="columns"
    style="height: 100%"
  >
    <div
      class="column"
      style="width: 100%"
    >
      <tabs :tabs="['web-form', 'manually', 'power-automate', 'zapier', 'make', 'rest-api']">
        <template slot="tab-head-manually">
          JSON
        </template>

        <template slot="tab-panel-manually">
          <json-tab
            :process-id="processId"
            @executed="onExecuted"
          />
        </template>

        <template slot="tab-head-web-form">
          Web Form
        </template>

        <template slot="tab-panel-web-form">
          <web-form-tab :process-id="processId" />
        </template>

        <template slot="tab-head-power-automate">
          Power Automate
        </template>

        <template slot="tab-panel-power-automate">
          <power-automate-tab />
        </template>

        <template slot="tab-head-zapier">
          Zapier
        </template>

        <template slot="tab-panel-zapier">
          <zapier-tab />
        </template>

        <template slot="tab-head-make">
          Make
        </template>

        <template slot="tab-panel-make">
          <make-tab />
        </template>

        <template slot="tab-head-rest-api">
          REST API
        </template>

        <template slot="tab-panel-rest-api">
          <rest-tab
            :process-id="processId"
          />
        </template>
      </tabs>
    </div>
  </div>
</template>

<script>
import { mapWritableState } from 'pinia';
import processes from '@/stores/processes.js';
import Tabs from '@/components/tabs.vue';
import Step from '@/components/wizard/step.vue';
import JsonTab from './tabs/json.vue';
import PowerAutomateTab from './tabs/power-automate.vue';
import ZapierTab from './tabs/zapier.vue';
import RestTab from './tabs/rest.vue';
import WebFormTab from './tabs/new-web-form.vue';
import MakeTab from './tabs/make.vue';

export default {
  name: 'ReadyStep',
  components: {
    Tabs,
    JsonTab,
    PowerAutomateTab,
    RestTab,
    WebFormTab,
    ZapierTab,
    MakeTab,
  },
  extends: Step,
  props: {
    processId: { type: String, required: true },
    stepId: { type: [Number, String], default: null },
    name: { type: String, default: 'ready-step' },
    icon: { type: [String, Array], default: '' },
  },
  computed: {
    ...mapWritableState(processes, ['processIsEdited']),
  },

  created() {
    this.processIsEdited = false;
  },

  methods: {
    onExecuted() {
      this.$emit('executed');
    },

    checkValidity() {
      return this.onValidityChecked({ errors: false });
    },
  },
};
</script>
