export const componentName = 'onedrive-delivery-step';
export const type = 'OneDrive';
export const name = 'Save to OneDrive';
export const icon = 'service-onedrive';
export const description = 'Save the result document to OneDrive folder.';
export const fullDescription = 'Save the result document to OneDrive folder. Learn more in <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/one-drive.html" target="_blank">the documentation.</a>';

export const options = {
  type, name, icon, description, fullDescription,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: componentName,
    propsData: options,
  },
};
