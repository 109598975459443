export const componentName = 'docusign-delivery-step';
export const type = 'DocuSign';
export const name = 'Send using DocuSign';
export const icon = 'service-docusign';
export const description = 'Send the result document to DocuSign for signing.';
export const fullDescription = 'Send the result document to DocuSign for signing. Learn more in <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/docusign.html" target="_blank">the documentation.</a>';

export const options = {
  type, name, icon, description, fullDescription,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: componentName,
    propsData: options,
  },
};
