import { createSkeleton, createPropsData } from './step-skeleton-factory';
import { options } from '../zohomail/zohomail-delivery-step-options';

export function createZohoMail(payload) {
  return {
    ...createSkeleton(),
    settings: {
      type: 'zohomail-delivery-step',
      propsData: createPropsData(options, payload),
    },
  };
}
