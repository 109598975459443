<template>
  <div
    v-if="steps.length"
    class="columns is-marginless is-paddingless"
    style="flex: 1 0 auto;"
  >
    <div :class="[$style.stepper, 'column is-narrow']">
      <Stepper
        size="large"
        :confirm-callback="showUnsavedChangesModal"
        @delete-step="remove($event)"
      />
    </div>

    <div
      v-if="steps.length > 0"
      :class="[$style.content, 'column']"
      :style="styleForEditor"
    >
      <div v-if="isStepCreating">
        <create-delivery
          ref="createDeliveryStep"
          @creationCompleted="create($event)"
        />
      </div>

      <component
        :is="steps[0].settings.type"
        v-if="currentStep === 0 && !isStepCreating"
        ref="currentStep"
        v-bind="steps[0].settings.propsData"
        :sub-step="currentSubStep"
        :sub-steps="steps[0].settings.propsData.sub_steps"
        :process-id="processId"
        @navigateNext="navigateNext"
        @navigateNextSubstep="navigateNextSubstep"
        @navigatePrevious="navigatePrevious"
      />

      <keep-alive>
        <template v-for="(step, index) in steps.slice(1)">
          <component
            :is="step.settings.type"
            v-if="currentStep === index + 1 && !isStepCreating"
            ref="currentDynamicStep"
            v-bind="step.settings.propsData"
            :key="step.settings.propsData.stepId"
            :sub-step="currentSubStep"
            :sub-steps="step.settings.propsData.sub_steps"
            :process-id="processId"
            @deleteStep="remove({ deliveryId: $event, index: index + 1 })"
            @navigateNext="navigateNext"
            @navigateNextSubstep="navigateNextSubstep"
            @navigatePrevious="navigatePrevious"
          />
        </template>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useProcessesStore, useProcessStepsStore } from '@/stores/index.js';
import Stepper from '@/components/wizard/stepper.vue';
import CreateDeliveryScreen from './steps/create-delivery.vue';
import EmailDeliveryStep from './steps/email/email-delivery-step.vue';
import OneDriveDeliveryStep from './steps/onedrive/onedrive-delivery-step.vue';
import DropboxDeliveryStep from './steps/dropbox/dropbox-delivery-step.vue';
import SharePointDeliveryStep from './steps/sharepoint/sharepoint-delivery-step.vue';
import WebHookDeliveryStep from './steps/webhook/webhook-delivery-step.vue';
import GoogleDriveDeliveryStep from './steps/googledrive/googledrive-delivery-step.vue';
import DocuSignDeliveryStep from './steps/docusign/docusign-delivery-step.vue';
import XodoSignDeliveryStep from './steps/xodosign/xodosign-delivery-step.vue';
import SignNowDeliveryStep from './steps/signnow/signnow-delivery-step.vue';
import DropboxSignDeliveryStep from './steps/dropboxsign/dropboxsign-delivery-step.vue';
import AdobeSignDeliveryStep from './steps/adobesign/adobesign-delivety-step.vue';
import GmailDeliveryStep from './steps/gmail/gmail-delivery-step.vue';
import OutlookDeliveryStep from './steps/outlook/outlook-delivery-step.vue';
import BoxDeliveryStep from './steps/box/box-delivery-step.vue';
import SlackDeliveryStep from './steps/slack/slack-delivery-step.vue';
import TeamsDeliveryStep from './steps/MicrosoftTeams/teams-delivery-step.vue';
import ZohoSignDeliveryStep from './steps/zohosign/zohosign-delivery-step.vue';
import ZohoMailDeliveryStep from './steps/zohomail/zohomail-delivery-step.vue';
import ConversionSetupStep from './steps/conversion/conversion-setup-step.vue';
import EmailSmtpDeliveryStep from './steps/email-smtp/email-smtp-delivery-step.vue';
import AmazonEmailDeliveryStep from './steps/amazon-email/amazon-email-delivery-step.vue';
import ReadyStep from './steps/ready/ready-step.vue';
import UnsavedChanges from '@/components/modals/UnsavedChanges.vue';
import Confirm from '@/components/modals/confirm.vue';
import { createDelivery } from './steps/factories/step-factory.js';

export default {
  name: 'ProcessSettings',
  components: {
    Stepper,
    'create-delivery': CreateDeliveryScreen,
    'email-delivery-step': EmailDeliveryStep,
    'onedrive-delivery-step': OneDriveDeliveryStep,
    'dropbox-delivery-step': DropboxDeliveryStep,
    'sharepoint-delivery-step': SharePointDeliveryStep,
    'webhook-delivery-step': WebHookDeliveryStep,
    'googledrive-delivery-step': GoogleDriveDeliveryStep,
    'docusign-delivery-step': DocuSignDeliveryStep,
    'xodosign-delivery-step': XodoSignDeliveryStep,
    'signnow-delivery-step': SignNowDeliveryStep,
    'dropboxsign-delivery-step': DropboxSignDeliveryStep,
    'adobesign-delivery-step': AdobeSignDeliveryStep,
    'gmail-delivery-step': GmailDeliveryStep,
    'outlook-delivery-step': OutlookDeliveryStep,
    'box-delivery-step': BoxDeliveryStep,
    'slack-delivery-step': SlackDeliveryStep,
    'teams-delivery-step': TeamsDeliveryStep,
    'zohosign-delivery-step': ZohoSignDeliveryStep,
    'zohomail-delivery-step': ZohoMailDeliveryStep,
    'conversion-setup-step': ConversionSetupStep,
    'email-smtp-delivery-step': EmailSmtpDeliveryStep,
    'amazon-email-delivery-step': AmazonEmailDeliveryStep,
    'ready-step': ReadyStep,
  },

  async beforeRouteLeave(to, from, next) {
    if (this.processIsEdited) {
      const response = await this.showUnsavedChangesModal();
      if (!response) return;
    }

    next();
  },
  props: {
    processId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDeliveryCreating: false,
    };
  },
  computed: {
    ...mapState(useProcessesStore, ['processIsEdited', 'deliveries', 'process']),
    ...mapWritableState(useProcessStepsStore, ['isStepCreating', 'currentStep', 'currentSubStep', 'steps']),

    styleForEditor() {
      if (this.currentStep === 0 && this.currentSubStep === 0 && !this.isStepCreating) {
        return { paddingBottom: 0, paddingTop: '10px', overflow: 'hidden' };
      }
      if (this.currentStep === ((this.steps?.length || 0) - 1) && !this.isStepCreating) {
        return {};
      }
      return { paddingBottom: '80px' };
    },
  },
  watch: {
    process: {
      handler() {
        if (process && !this.steps.length) {
          this.steps = [
            createDelivery('Conversion', { template: process }),
            createDelivery('Ready'),
          ];
          this.readDeliveries({ processId: this.processId });
        }
      },
      immediate: true,
    },

    deliveries: {
      handler(deliveries) {
        if (deliveries?.length) {
          deliveries
            .forEach((delivery, index) => {
              this.steps.splice(index + 1, 0, createDelivery(delivery.type, delivery));
            });
        }
      },
    },
  },

  beforeDestroy() {
    this.destroySteps();
  },

  methods: {
    ...mapActions(useProcessesStore, ['readDeliveries', 'deleteDelivery']),
    ...mapActions(useProcessStepsStore, [
      'navigateNextSubstep',
      'navigatePrevious',
      'navigateNext',
      'addNewStep',
      'deleteStep',
      'destroySteps',
    ]),

    create(delivery) {
      const createdDelivery = createDelivery(delivery.type, delivery.payload);
      this.addNewStep(createdDelivery);
    },

    async remove({ deliveryId, index }) {
      const isConfirm = await new Promise((resolve) => {
        this.$modal.show(
          Confirm,
          {
            title: 'Delete delivery',
            message: 'Delete the selected delivery?',
            onSubmit: () => { resolve(true); },
            submitBtnText: 'Delete',
            showCloseBtn: true,
          },
          {
            draggable: '.modal-handler',
            clickToClose: true,
            width: '455px',
            height: 'auto',
            adaptive: true,
          },
          {
            closed: () => { resolve(false); },
          },
        );
      });

      if (!isConfirm) return;

      this.deleteDelivery({ processId: this.processId, deliveryId });

      this.deleteStep(index);
    },

    showUnsavedChangesModal() {
      return new Promise((resolve) => {
        this.$modal.show(
          UnsavedChanges,
          {
            onLeave: () => { resolve(true); },
            onSave: async () => {
              if (this.$refs.currentStep?.saveBeforeLeave) {
                const resp = await this.$refs.currentStep.saveBeforeLeave();
                resolve(resp);
                return;
              }
              if (this.$refs.currentDynamicStep?.[0]?.save) {
                const resp = await this.$refs.currentDynamicStep[0].save();
                resolve(resp);
                return;
              }
              if (this.$refs.createDeliveryStep?.createBeforeLeave) {
                const resp = await this.$refs.createDeliveryStep.createBeforeLeave();
                resolve(resp);
                return;
              }
              console.warn('Save method not implemented');
              resolve(false);
            },
          },
          {
            draggable: '.modal-handler',
            width: '440px',
            height: 'auto',
            adaptive: true,
          },
          { closed: () => { resolve(false); } },
        );
      });
    },
  },
};
</script>

<style lang="scss" module>
@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/derived-variables';
@import '~bulma/sass/utilities/mixins';

.stepper {
  &:global(.column.is-narrow) {
    width: 295px;

    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
}

.content {
  @media (max-width: 768px) {
    &:global(.column) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
