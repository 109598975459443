<script>
import axios from 'axios';
import FileSaver from 'file-saver';

const Statuses = Object.freeze(
  {
    waiting: 1, uploaded: 2, uploading: 3, failed: 4, existing: 5,
  },
);

export default {
  name: 'UploadFileBase',

  props: {
    existingFile: { type: String, default: null },
    downloadUrl: { type: String, default: null },
    uploadUrl: { type: String, required: true },
    deleteUrl: { type: String, required: true },
  },

  data() {
    return {
      currentPercent: 0,
      currentStatus: Statuses.waiting,
      selectedFileName: null,
      uploadedFile: null,
    };
  },

  computed: {
    isFailed() { return this.currentStatus === Statuses.failed; },
    isWaiting() { return this.currentStatus === Statuses.waiting; },
    isExisting() { return this.currentStatus === Statuses.existing; },
    isUploaded() { return this.currentStatus === Statuses.uploaded; },
    isUploading() { return this.currentStatus === Statuses.uploading; },
  },

  watch: {
    existingFile() {
      if (this.existingFile) this.currentStatus = Statuses.existing;
      else this.currentStatus = Statuses.waiting;
    },
  },

  mounted() {
    [this.uploadComponent] = this.$children.filter((child) => child.status);

    this.uploadComponent.$on('change', this.selectedFileChanged);
    this.uploadComponent.$on('delete', this.deleteSelectedFile);
    this.uploadComponent.$on('obtain', this.downloadUploadedFile);
  },

  methods: {
    reset() {
      this.currentStatus = 1;
      this.selectedFileName = null;
      this.currentPercent = 0;
      this.uploadedFile = null;
    },

    selectedFileChanged(files) {
      if (files.length === 0) return;

      const xhr = new XMLHttpRequest();

      xhr.upload.onloadstart = () => {
        this.currentStatus = Statuses.uploading;
        this.currentPercent = 0;
      };

      xhr.upload.onprogress = (e) => {
        this.currentPercent = Math.floor((e.loaded * 100) / e.total);
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            this.currentStatus = Statuses.uploaded;
            try {
              this.uploadedFile = JSON.parse(xhr.responseText);
            } catch {
              this.uploadedFile = xhr.responseText;
            }
            this.$emit('fileUploaded', xhr.responseText, this.selectedFileName);
          } else {
            this.currentStatus = Statuses.failed;
          }
        }
      };

      xhr.open('POST', this.uploadUrl, true);

      const [selectedFile] = files;
      const formData = new FormData();
      formData.append('file', selectedFile);

      this.selectedFileName = selectedFile.name;

      xhr.send(formData);
    },

    deleteSelectedFile() {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 204 || xhr.status === 200) {
            this.reset();
            this.$emit('fileDeleted');
          }
        }
      };

      xhr.open('DELETE', `${this.deleteUrl}/${this.uploadedFile}`, true);
      xhr.send();
    },

    downloadUploadedFile() {
      axios.get(this.downloadUrl, { responseType: 'blob' })
        .then((r) => FileSaver.saveAs(new Blob([r.data]), this.existingFile));
    },
  },

  render() {
    return this.$scopedSlots.default({
      status: {
        isFailed: this.isFailed,
        isWaiting: this.isWaiting,
        isExisting: this.isExisting,
        isUploaded: this.isUploaded,
        isUploading: this.isUploading,

        currentPercent: this.currentPercent,
        selectedFileName: this.selectedFileName === null
          ? this.existingFile
          : this.selectedFileName,
      },
    });
  },
};
</script>
