<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div
        v-if="form.account"
        class="content"
      >
        <account-info
          :name="form.account"
          @reconect="reconnect"
          @sign-out="signoutPopup"
        />

        <div class="field is-expanded">
          <label class="label">Library</label>
          <div
            class="control select is-fullwidth"
            :class="{ 'is-danger': isInvalid($v.form.library) }"
          >
            <select v-model="form.library">
              <option
                v-for="option in spLibraries"
                :key="option.key"
                :value="option.key"
              >
                {{ option.value }}
              </option>
            </select>
          </div>
          <p
            v-if="isInvalid($v.form.library) && !$v.form.filename.library"
            class="help is-danger"
          >
            Please choose a library
          </p>
          <p
            v-if="librariesLoadingError"
            class="help is-danger"
          >
            {{ librariesLoadingError }}
          </p>
        </div>
        <label class="label">Folder</label>
        <div class="field has-addons">
          <tokens
            v-model="$v.form.folder.$model"
            class="control is-expanded"
            :z-index="10"
            position="is-top-left"
          >
            <input
              v-model="$v.form.folder.$model"
              class="input"
              type="text"
              :class="{ 'is-danger': isInvalid($v.form.folder) }"
            >
            <p
              v-if="isInvalid($v.form.folder) && !$v.form.folder.matchFolderName"
              class="help is-danger"
            >
              Please enter a name that doesn't include any special
              (<span class="has-background-light is-family-code">" * : &lt; > ? / \ |</span>)
              characters or end with "."
            </p>
          </tokens>
        </div>
      </div>

      <div
        v-else
        class="field has-addons"
      >
        <div class="control is-expanded">
          <input
            v-model="$v.form.sharePointUrl.$model"
            class="input"
            type="text"
            :class="{ 'is-danger': isInvalid($v.form.sharePointUrl) }"
            placeholder="Site URL e.g. https://yourdomain.sharepoint.com/sites/docs"
          >
          <p
            v-if="isInvalid($v.form.sharePointUrl) && !$v.form.sharePointUrl.matchSiteUrl"
            class="help level-left is-danger"
          >
            Please fill SharePoint site URL and click "Connect"
          </p>
        </div>
        <div class="control">
          <div class="button is-primary">
            <a
              class="button is-primary"
              @click="signinPopup"
            >Connect</a>
          </div>
        </div>
      </div>
    </template>
  </step-layout>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import { mapState, mapActions } from 'pinia';
import { useProcessesStore, useSettingsStore } from '@/stores/index.js';
import OAuthClient from '../../services/oauth-client';
import DynamicStep from '@/components/wizard/dynamic-step.vue';
import { matchSiteUrl, matchFolderName } from '@/utils/validators.js';
import DeliveriesNames from '../deliveries-names';
import Tokens from '@/components/tokens.vue';
import AccountInfo from '../../components/delivery/account-info.vue';
import { apiEndpoint } from '@/utils/url-manager.js';

export default {
  name: 'SharepointDeliveryStep',
  components: {
    Tokens,
    AccountInfo,
  },
  extends: DynamicStep,
  data() {
    return {
      form: {
        id: null,
        account: null,
        library: null,
        folder: null,
        sharePointUrl: null,
      },
      cacheId: null,
      oauthClient: null,

      authorize: null,
      clientId: null,
      scope: null,
      redirectUrl: null,
      logout: null,
      spLibraries: null,
      librariesLoadingError: null,
    };
  },

  computed: mapState(useSettingsStore, ['getProfile']),

  created() {
    this.oauthClient = new OAuthClient();
  },

  validations() {
    const defaultValidation = {
      form: {
        account: { required },
        library: { required },
        folder: { matchFolderName },
        sharePointUrl: { required, matchSiteUrl },
      },
    };

    return defaultValidation;
  },

  async mounted() {
    const endpoint = apiEndpoint;

    const settings = (await axios.get(`${endpoint}auth/providers/${DeliveriesNames.SharePoint}`)).data;

    this.authorize = settings.authorizeEndpoint;
    this.clientId = settings.clientId;
    this.scope = settings.scope;
    this.redirectUrl = settings.redirectUrl;
    this.logout = settings.logoutEndpoint;
    this.spLibraries = null;
  },

  methods: {
    ...mapActions(useProcessesStore, ['createOrUpdateDelivery']),

    async signinPopup() {
      const endpoint = apiEndpoint;

      await this.signoutPopup();

      const url = this.getAuthorizeUrl();

      const func = async (event) => {
        if (event.data !== Object(event.data) && event.data && event.data.indexOf('?code=') !== -1) {
          const code = event.data.slice(6);
          const response = await axios.get(`${endpoint}auth/connect?type=${DeliveriesNames.SharePoint}&code=${code}`);

          this.form.account = response.data.account;
          this.cacheId = response.data.cacheId;

          await this.getLibrariesList();

          return true;
        }

        return false;
      };

      await this.oauthClient.showPopup(url, func.bind(this));
    },

    async signoutPopup() {
      const url = this.getLogoutUrl();

      await this.oauthClient.showPopup(url, async (event) => {
        // kvv: when will it be a regular time event then data will be "unchanged"
        // when it will be callback after post logout redirect - data will be null
        if (!event.data) {
          this.form.account = null;

          return true;
        }

        return false;
      });
    },

    async reconnect() {
      await this.signoutPopup();
      await this.signinPopup();
    },
    async submitForm() {
      const { data } = await this.createOrUpdateDelivery({
        processId: this.processId,
        deliveryName: DeliveriesNames.SharePoint,
        deliveryData: { ...this.form, cacheId: this.cacheId },
      });

      return data;
    },
    setData() {
      if (this.payload) {
        this.form.id = this.payload.id;
        this.form.account = this.payload.account;
        this.form.library = this.payload.library;
        this.form.folder = this.payload.folder;
        this.form.sharePointUrl = this.payload.sharePointUrl;
      }

      this.getLibrariesList();
    },

    getTenantUrl() {
      const url = new URL(this.form.sharePointUrl);

      return url.host.replace('sharepoint', 'onmicrosoft');
    },

    getAuthorizeUrl() {
      const authorizeUrl = this.authorize.replace('{tenant}', this.getTenantUrl());

      return `${authorizeUrl}?client_id=${this.clientId}&scope=${this.scope}&response_type=code&redirect_uri=${this.redirectUrl}`;
    },

    getLogoutUrl() {
      const logoutUrl = this.logout.replace('{tenant}', this.getTenantUrl());

      return `${logoutUrl}?client_id=${this.clientId}&post_logout_redirect_uri=${this.redirectUrl}`;
    },

    async getLibrariesList() {
      const spUrl = this.form.sharePointUrl;
      const deliveryId = this.form.id;

      // kvv: if spUrl is null - then we came here before "Connect" call.
      if (spUrl || deliveryId) {
        const userId = this.getProfile.sub;

        const params = {
          siteUrl: spUrl,
          userId,
          cacheId: this.cacheId,
          deliveryId,
        };

        const url = `${apiEndpoint}documents/processes/${this.processId}/delivery/sharepoint/libraries`;
        axios.get(url, { params })
          .then((response) => {
            this.spLibraries = response.data;
          })
          .catch((err) => {
            if (err.response.status === 400) {
              this.librariesLoadingError = err.response.data;
            } else {
              console.error(err);
            }
          });
      }
    },
  },
};
</script>