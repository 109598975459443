<template>
  <div
    :class="[
      $style.designer,
      { [$style.designer__edit]: editor },
      { [$style.fullscreen]: fullscreen },
    ]"
  >
    <template-testing-panel
      :side-panel-name="processTestPanel"
      :file-name="fileName"
      :process-id="processId"
      :google-drive-id="form.googleDriveId"
      :html-source="form.htmlSource"
      :input-type="inputType"
      :need-update="async () => { await parseTokens(); }"
      :tags-updated="(tags) => { $emit('set-validate-value', { tags }); }"
    />
    <nav
      :class="[$style.navbar, 'navbar']"
      style="flex-shrink: 1;"
    >
      <div
        id="navbarBasicExample"
        :class="[$style['navbar-menu'], 'navbar-menu']"
        style="flex-shrink: 1; justify-content: space-between;"
      >
        <save-button
          :style="{ marginRight: '7px', alignSelf: 'center' }"
          :loading="loading"
          @save-next="submit(true)"
          @save="submit"
        />

        <resize-group
          v-show="form.from"
          :loading="loading"
        >
          <button
            v-show="canBeEdited"
            :disabled="loading"
            :class="['button is-white', $style.control, $style['control_mobile-hide']]"
            @click="toogleMode"
          >
            <span class="icon">
              <font-awesome-icon
                :icon="['fal', 'pen-to-square']"
              />
            </span>
            <span :class="$style.control__text">Edit online</span>
          </button>

          <button
            :disabled="loading"
            type="button"
            :class="['button is-white', $style.control]"
            @click="showTemplateTestingPanel"
          >
            <span class="icon is-small">
              <font-awesome-icon :icon="['fal', 'flask']" />
            </span>
            <span :class="$style.control__text">Test template</span>
          </button>

          <button
            v-if="isEditable"
            :disabled="loading"
            type="button"
            :class="['button is-white', $style.control]"
            @click="showTokensModal"
          >
            <span class="icon is-small">
              <font-awesome-icon :icon="['fal', 'brackets-curly']" />
            </span>
            <span :class="$style.control__text">Tokens</span>
          </button>

          <button
            :disabled="loading"
            :class="['button is-white', $style.control]"
            @click="() => {
              $refs.fileInput.value = null;
              $refs.fileInput.click();
            }"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fal', 'upload']" />
            </span>
            <span :class="$style.control__text">Upload</span>
          </button>

          <button
            :disabled="loading"
            :class="['button is-white', $style.control]"
            @click="download"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fal', 'download']" />
            </span>
            <span :class="$style.control__text">Download</span>
          </button>
        </resize-group>

        <div
          v-show="processMode"
          :class="$style['mode-control']"
        >
          <b-dropdown
            position="is-bottom-left"
            :mobile-modal="false"
          >
            <template #trigger>
              <button
                :class="[
                  'button is-light',
                  $style['change-mode-btn'],
                  { ['has-text-success']: processMode === 'Production' },
                ]"
              >
                {{ processMode }}
              </button>
            </template>

            <b-dropdown-item
              :paddingless="true"
              :custom="true"
            >
              <div :class="$style['mode-control__info']">
                <p :class="$style['mode-control__text']">
                  {{
                    form.processMode === 'Production'
                      ? 'You will be charged for process runs.'
                      : 'Unlimited process runs with a watermark inside each document.'
                  }}
                </p>
                <bulma-switch
                  v-model="formProcessMode"
                  :class="$style['switch-mode']"
                  :captions="['Production', 'Testing']"
                  optional-switch-class="is-success"
                >
                  Template mode
                </bulma-switch>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div
          v-show="editor"
          :class="$style['frame-edit__btn-group']"
        >
          <button
            :disabled="loading"
            :title="fullscreen ? 'Exit full screen' : 'Full screen'"
            :class="[$style['frame-edit__fullscr-btn'], 'button is-white']"
            @click="toggleFullscreen"
          >
            <span class="icon">
              <font-awesome-icon
                v-if="fullscreen"
                :icon="['fal', 'compress']"
              />
              <font-awesome-icon
                v-if="!fullscreen"
                :icon="['fal', 'expand']"
              />
            </span>
          </button>

          <button
            :disabled="loading"
            title="Exit template editor"
            class="button is-white"
            @click="toogleMode"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fal', 'xmark']" />
            </span>
          </button>
        </div>

        <input
          ref="fileInput"
          type="file"
          style="display: none;"
          @change="uploadFile"
        >

        <!-- temporary hide help button -->

        <!-- <div
          class="navbar-end is-marginless"
          style="flex-shrink: 0;"
        >
          <div class="navbar-item">
            <v-popover
              placement="right"
              offset="16"
            >
              <button
                ref="helpButton"
                class="button is-white tooltip-target b3"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fal', 'circle-info']" />
                </span>
                <span>Help</span>
              </button>
              <template slot="popover">
                <div class="box">
                  Learn more about {{ typeName }} templates in
                  <a
                    :href="documentationLink"
                    target="_blank"
                  >the documentation</a>
                </div>
              </template>
            </v-popover>
          </div>
        </div> -->
      </div>
    </nav>

    <div
      v-if="loading"
      class="is-flex is-loading"
      style="flex-grow: 1;"
    />

    <div
      v-show="!loading"
      id="editor"
      :class="$style.editor"
      @mouseout="designerMouseOutHandler"
      @mouseover="designerMouseOverHandler"
    >
      <div
        v-show="!editor && !isDropReady && dragCount === 0"
        :class="$style['frame__btn-group']"
      >
        <button
          v-if="canBeEdited"
          :disabled="loading"
          :class="['button is-dark', $style.frame__btn]"
          @click="toogleMode"
        >
          <span class="icon">
            <font-awesome-icon
              :icon="['fal', 'pen-to-square']"
            />
          </span>
          <span>Edit online</span>
        </button>
        <button
          v-if="preview"
          :disabled="loading"
          :class="['button is-dark', $style.frame__btn]"
          @click="() => {
            $refs.fileInput.value = null;
            $refs.fileInput.click();
          }"
        >
          <span class="icon">
            <font-awesome-icon :icon="['fal', 'upload']" />
          </span>
          <span>Upload new</span>
        </button>
      </div>

      <div
        v-show="!editor && (isShowDropMessage || dragCount !== 0)"
        :class="$style['drop-info']"
      >
        <font-awesome-icon
          :class="$style['drop-info__icon']"
          :icon="['fal', 'file-arrow-up']"
        />
        <span :class="$style['drop-info__text']">Drop a file here.</span>
      </div>

      <div
        v-show="!editor && (isDropReady || dragCount !== 0)"
        :class="$style['drop-area']"
        @dragover.stop.prevent
        @dragenter.stop.prevent="areaDragEnterHandler"
        @dragleave.stop.prevent="isShowDropMessage = false;"
        @drop.stop.prevent="dropFile"
      />

      <html-content-editor
        v-if="form.htmlSource"
        v-model="formHtmlSource"
        @load="loading = false"
      />

      <google-drive-editor
        v-else
        :file-id="form.googleDriveId"
        :content-url="previewUrl"
        :mode="mode"
        :is-full-screen="fullscreen"
        :type="inputType"
        @loading="(s) => loading = s"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import {
  useProcessStepsStore,
  useProcessesStore,
  useSidePanelStore,
  useTokensStore,
  useTemplatesStore,
} from '@/stores/index.js';

import { accumulateTags } from '@/utils/index.js';
import TagsModalComponent from './tags-modal.vue';
import GoogleDriveEditor from './google-drive-editor.vue';
import HtmlContentEditor from './html-content-editor.vue';
import ResizeGroup from './resize-group.vue';
import SaveButton from '../../components/save-button.vue';
import { findDifference } from './tokens-functions.js';
import { BulmaSwitchComponent as BulmaSwitch } from '@/components/basic/index.js';
import Alert from '@/components/modals/alert.vue';
import TemplateTestingPanel from './template-testing/template-testing-panel.vue';

export default {
  name: 'ConversionStepDesigner',
  components: {
    HtmlContentEditor,
    GoogleDriveEditor,
    ResizeGroup,
    BulmaSwitch,
    SaveButton,
    TemplateTestingPanel,
  },
  props: {
    $v: {
      type: Object,
      default: () => null,
    },
    processId: {
      type: String,
      default: '',
    },
    previewUrl: {
      type: String,
      default: '',
    },
    templateName: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      mode: 'initial',
      fullscreen: false,
      typeChanged: false,
      isShowDropMessage: false,
      isDropReady: true,
      isFocusWindow: true,
      processTestPanel: 'TemplateTesting',
      dragCount: 0,
    };
  },
  computed: {
    ...mapState(useTokensStore, ['tokens']),
    ...mapState(useProcessesStore, ['processTestingMode']),
    ...mapState(useTemplatesStore, ['testTemplate']),
    ...mapWritableState(useProcessStepsStore, ['tagsBuffer']),

    formHtmlSource: {
      get() { return this.form.htmlSource; },
      set(value) { this.$emit('set-validate-value', { htmlSource: value }); },
    },

    formProcessMode: {
      get() { return this.form.processMode; },
      set(value) { this.$emit('set-validate-value', { processMode: value }); },
    },

    fileName() {
      const { outputName } = this.form;
      const outputType = this.form.outputType.toLowerCase();

      return `${outputName}.${outputType}`;
    },

    fileId() {
      return this.form.googleDriveId;
    },

    inputType() {
      return this.form.from;
    },

    canBeEdited() {
      return this.preview && this.isEditable && !this.isHtml && !this.isPdf;
    },

    /** buttonsShow
     * this.form.from
     *
     */

    typeName() {
      return this.inputType === 'PDF' ? 'Fillable PDF' : this.inputType;
    },

    documentationLink() {
      if (this.isDocx) {
        return 'https://plumsail.com/docs/documents/v1.x/document-generation/docx/index.html';
      } if (this.isXlsx) {
        return 'https://plumsail.com/docs/documents/v1.x/document-generation/xlsx/index.html';
      } if (this.isPptx) {
        return 'https://plumsail.com/docs/documents/v1.x/document-generation/pptx/index.html';
      } if (this.isPdf) {
        return 'https://plumsail.com/docs/documents/v1.x/document-generation/fillable-pdf/index.html';
      } if (this.isHtml) {
        return 'https://plumsail.com/docs/documents/v1.x/document-generation/html/index.html';
      }
      return null;
    },
    isDocx() {
      return this.inputType === 'DOCX' || this.inputType === 'DOCM';
    },
    isXlsx() {
      return this.inputType === 'XLSX';
    },
    isPptx() {
      return this.inputType === 'PPTX';
    },
    isPdf() {
      return this.inputType === 'PDF';
    },
    isHtml() {
      return this.inputType === 'HTML';
    },
    isEditable() {
      return this.isDocx || this.isXlsx || this.isPptx || this.isPdf || this.isHtml;
    },
    preview() {
      return this.mode === 'preview' || this.mode === 'initial';
    },
    editor() {
      return this.mode === 'editor';
    },
    processMode() {
      return this.form.processMode;
    },
  },

  watch: {
    isFocusWindow(value) {
      if (!value && !document.hasFocus()) {
        this.isDropReady = true;
      }
    },
  },

  mounted() {
    document.body.addEventListener('dragenter', this.bodyDragenterHandler);
    document.body.addEventListener('dragleave', this.bodyDragLeaveHandler);
    document.body.addEventListener('dragover', this.bodyDragoverHandler);
    document.body.addEventListener('drop', this.bodyDropHandler);

    window.addEventListener('focus', this.windowFocusHandler);
    window.addEventListener('blur', this.windowBlurHandler);

    sessionStorage.removeItem(this.processId);

    if (this.inputType !== 'HTML') {
      this.$emit('set-value', { googleDriveId: '' });
      this.$emit('resetEditor');
    }

    if (!localStorage.getItem('onlineEditorHelpShown')) {
      // ref helpButton commented out in the template
      // this.$refs.helpButton.click();

      localStorage.setItem('onlineEditorHelpShown', true);
    }
  },

  beforeDestroy() {
    document.body.removeEventListener('dragenter', this.bodyDragenterHandler);
    document.body.removeEventListener('dragleave', this.bodyDragLeaveHandler);
    document.body.removeEventListener('dragover', this.bodyDragoverHandler);
    document.body.removeEventListener('drop', this.bodyDropHandler);

    window.removeEventListener('focus', this.windowFocusHandler);
    window.removeEventListener('blur', this.windowBlurHandler);

    this.resetPanels();
  },

  methods: {
    ...mapActions(useSidePanelStore, ['resetPanels', 'setPanel']),
    ...mapActions(useTokensStore, ['updateTokens', 'readTokensFromTemplate', 'readTokens']),
    ...mapActions(useProcessesStore, ['updateTestProcess']),
    ...mapActions(useTemplatesStore, [
      'uploadTemplateFromEditor',
      'createTemplateFromHtml',
      'uploadTemplateFromLink',
      'readTemplateUrl',
      'copyTemplate',
    ]),

    showTemplateTestingPanel() {
      this.setPanel(this.processTestPanel);
    },

    async showTokensModal() {
      this.$modal.show(
        TagsModalComponent,
        {
          fileId: this.fileId,
          processId: this.processId,
          loading: !this.tokensParsed,
          documentType: this.inputType,
          needUpdate: async () => {
            const result = await this.parseTokens();
            return result;
          },
          tagsUpdated: (tags) => this.$emit('tagsUpdated', tags),
          tagsTypeChanged: () => { this.typeChanged = true; },
        },
        {
          height: '600px', clickToClose: false, draggable: '.modal-handler', adaptive: true,
        },
      );
    },

    dropFile(event) {
      this.isDropReady = false;
      this.isShowDropMessage = false;
      const file = event.dataTransfer.files[0];
      this.upload(file);
    },

    uploadFile(event) {
      const file = event.target.files[0];
      this.upload(file);
    },

    async upload(file) {
      if (this.invalidType(file)) {
        const types = ['XLSX', 'PPTX', 'HTML', 'DOCX', 'PDF'].filter((x) => x !== this.inputType);
        this.$modal.show(
          Alert,
          {
            title: 'Unable to upload',
            message: `Unable to upload this file as a template. Your process supports only ${this.inputType} files as templates. Please create a new process to handle ${types[0]}, ${types[1]}, ${types[2]}, or ${types[3]}.`,
          },
          {
            draggable: '.modal-handler',
            clickToClose: true,
            width: '455px',
            height: 'auto',
            adaptive: true,
          },
        );
        this.isDropReady = true;
        return;
      }

      if (file && this.inputType !== 'HTML') {
        this.loading = true;

        const { data } = await this.uploadTemplateFromEditor({ file });

        await this.onFileUploaded(data);
      }

      if (file && this.inputType === 'HTML') {
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');

        this.htmlTemplateName = file.name;

        reader.onload = (evt) => this.$emit('fileUploaded', evt.target.result);
      }
    },

    invalidType(uploadedFile) {
      let uploadedType = '';
      switch (uploadedFile.type) {
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          uploadedType = 'pptx';
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.ms-word.document.macroEnabled.12':
          uploadedType = 'docx';
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          uploadedType = 'xlsx';
          break;
        default: {
          const splitName = uploadedFile.name.split(/[\s.]+/);
          uploadedType = splitName[splitName.length - 1];
        }
      }

      return this.inputType.toLowerCase() !== uploadedType.toLowerCase();
    },

    async download() {
      if (this.inputType !== 'HTML') {
        window.open((await this.readTemplateUrl({ fileId: this.fileId })).data, { target: '_blank' });
      } else {
        const blob = new Blob([this.form.htmlSource], {
          type: 'text/html',
        });

        const name = this.htmlTemplateName || this.templateName;

        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = name;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },

    async onFileUploaded(response) {
      this.$emit('fileUploaded', response);
      this.loading = false;
    },

    async toogleMode() {
      if (this.fullscreen && !this.preview) {
        this.fullscreen = !this.fullscreen;
      }

      if (!this.preview) {
        this.loading = true;

        const { data } = await this.copyTemplate({ fileId: this.fileId });

        this.$emit('set-value', { googleDriveId: data });
      }

      const oldId = this.form.googleDriveId;
      this.$emit('set-value', { googleDriveId: '' });
      this.$emit('set-value', { googleDriveId: oldId });

      this.mode = this.preview ? 'editor' : 'preview';

      if (this.editor) {
        this.isShowDropMessage = false;
        this.isDropReady = false;
        this.isFocusWindow = true;
        this.dragCount = 0;
      }
    },

    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },

    async updateTemplate() {
      const templateId = await this.uploadTemplateIfContentUpdated();
      this.$emit('set-validate-value', { templateId });
    },

    async submit(next = false) {
      this.loading = true;

      await this.updateTemplate();

      const processData = {
        json: this.testTemplate,
        createMode: this.processTestingMode,
      };

      await this.updateTestProcess({ id: this.processId, processData });

      sessionStorage.removeItem(this.processId);

      if (!this.typeChanged) {
        const tags = await this.parseTokens(true);
        const tree = this.collectNodes(tags);
        const preparedTags = accumulateTags(tree);
        this.$emit('tagsUpdated', preparedTags);
      }

      this.typeChanged = false;

      if (next) {
        this.$emit('submit-and-next');
        return;
      }

      this.$emit('submit');
      this.loading = false;
    },

    async save() {
      this.loading = true;
      await this.updateTemplate();
      this.$emit('save');
      this.$v.form.$reset();
      this.loading = false;
    },
    /**
         * Upload content of Google editor to BE and returns templateId in our system
         */
    async uploadTemplateUsingGoogleEditor() {
      const { data: link } = await this.readTemplateUrl({ fileId: this.fileId });

      const { data } = await this.uploadTemplateFromLink({ link });

      return data;
    },

    /**
         * Upload content of text editor to BE and returns templateId in our system
         */
    async uploadTemplateUsingHtmlEditor() {
      return (await this.createTemplateFromHtml(
        {
          name: this.htmlTemplateName,
          templateData: this.form.htmlSource,
        },
      )).data;
    },

    /**
         * Make uploading of content, if it has been changed
         * Upload content of text editor, if for editing used text editor
         * Upload content of google editor, is for editing used google editor
         */
    async uploadTemplateIfContentUpdated() {
      if (this.form.htmlSource) {
        return this.uploadTemplateUsingHtmlEditor();
      }
      if (this.$v.form.googleDriveId.$dirty) {
        const response = await this.uploadTemplateUsingGoogleEditor();
        return response;
      }
      return null;
    },

    async parseTokens(updateTags = false) {
      let tokens = [];
      const templateId = await this.uploadTemplateIfContentUpdated();

      if (templateId) {
        const { data, error } = await this.readTokensFromTemplate({ templateId, updateTags });

        if (error?.message) {
          this.$modal.show(
            Alert,
            {
              title: 'Document reading error',
              message: error?.message,
            },
            {
              draggable: '.modal-handler',
              clickToClose: true,
              width: '455px',
              height: 'auto',
              adaptive: true,
            },
          );

          this.loading = false;
          return;
        }

        tokens = data;
      } else {
        tokens = (await this.readTokens({ processId: this.processId })).data;
      }

      const savedTokens = JSON.parse(sessionStorage.getItem(this.processId)) || this.tokens;
      const difference = findDifference(tokens, savedTokens);

      await this.updateTokens({ processId: this.processId, tokensData: difference });

      this.tagsBuffer = difference;

      return difference;
    },

    collectNodes(nodes, currentTree = []) {
      const tree = currentTree;

      const tags = [];

      nodes
        .slice()
        .sort((left, right) => left.label.split('.').length - right.label.split('.').length)
        .forEach((node) => {
          const path = node.label.split('.');

          for (let i = 1; i < path.length; i += 1) {
            const parent = path.slice(0, i).join('.');
            const parentTag = tags.find((t) => t.label === parent);

            if (parentTag) {
              if (parentTag.meta.type !== 'object') {
                parentTag.meta.type = 'collection';
              }
            } else {
              tags.push({
                label: parent,
                meta: { type: node.meta.type === 'object' ? 'object' : 'collection' },
              });
            }
          }

          tags.push({ label: path.join('.'), meta: node.meta });
        });

      tags
        .map((node) => ({ path: node.label.split('.'), meta: node.meta }))
        .forEach(({ path, meta }) => {
          let currentLevel = tree;

          path.forEach((part) => {
            const existingPath = currentLevel.find((x) => x.name === part);

            if (existingPath) {
              currentLevel = existingPath.children;
            } else {
              const newPart = {
                name: part, meta, children: [], path,
              };

              currentLevel.push(newPart);
              currentLevel = newPart.children;
            }
          });
        });

      return tree;
    },

    bodyDropHandler(event) {
      if (this.editor) return;
      event.preventDefault();
    },

    bodyDragenterHandler(event) {
      if (this.editor) return;
      event.preventDefault();
      this.dragCount += 1;
      event.dataTransfer.dropEffect = 'none';
    },

    bodyDragoverHandler(event) {
      if (this.editor) return;
      event.preventDefault();
      event.dataTransfer.dropEffect = 'none';
    },

    bodyDragLeaveHandler(event) {
      if (this.editor) return;
      event.preventDefault();
      this.dragCount -= 1;
    },

    areaDragEnterHandler() {
      if (this.editor) return;
      this.isShowDropMessage = true;
      this.isDropReady = true;
    },

    windowBlurHandler() {
      if (this.editor) {
        setTimeout(() => {
          if (document.activeElement.tagName === 'IFRAME') {
            this.$v.form.$touch();
          }
        });
        return;
      }
      this.isFocusWindow = false;
    },

    windowFocusHandler() {
      if (this.editor) return;
      this.isDropReady = false;
      this.isFocusWindow = true;
    },

    designerMouseOutHandler() {
      if ((!this.isFocusWindow && !document.hasFocus()) || this.editor) return;
      this.isDropReady = true;
    },

    designerMouseOverHandler() {
      if ((!this.isFocusWindow && !document.hasFocus()) || this.editor) return;
      this.isDropReady = false;
    },
  },
};
</script>

<style lang="scss" module>
.editor {
  position: relative;
  display: flex;
  flex-grow: 1;

  & > * {
    flex: 1 1 auto;
  }
}

.designer {
  display: flex;
  flex: 1;
  flex-flow: column;
  width: 100%;
  height: 100%;

  &.fullscreen {
    position: absolute;
    left: 0;
    top: 45px;
    bottom: 0;
    height: auto;
    z-index: 996;
    padding: 10px 10px 0;
  }

  .navbar {
    display: inline-flex;
    flex-direction: row;
    min-width: 100%;
    min-height: 3.5rem;
  }
}

.navbar-menu {
  display: inline-flex;
  flex-direction: row;
  min-width: 100%;
}

.drop-area {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 19
}

.drop-info {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding-top: 133px;
  border: 1px dashed #DBDBDB;
  background-color: #F5F5F5;
  z-index: 18;

  &__icon {
    font-size: 28px;
    margin-right: 8px;
  }

  &__text {
    font-size: 16px;
  }
}

.change-mode-btn {
  height: auto;
  padding: 6px 7px;
  line-height: 1;
}

.mode-control {
  align-self: center;

  &__info {
    min-width: 300px;
    padding: 17px 19px;
  }

  &__text {
    margin-bottom: 12px;
    font-size: 16px;
  }

  :global(.dropdown-menu) {
    padding-top: 0;
  }

  :global(.label):not(:last-child) {
    margin-bottom: 0;
  }
}

.frame {
  &__btn-group {
    position: absolute;
    top: 130px;
    left:50%;
    display: flex;
    transform:translateX(-50%);
  }

  &__btn {
    &:global(.button.is-dark) {

      background-color: rgba(0, 0, 0, .75);

      &:hover,
      &:focus-within {
        background-color: rgba(64, 64, 64, .75);
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

.frame-edit {
  &__btn-group {
    display: flex;
    width: 98px;
    height: 100%;
    margin-left: 14px;
    background: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 0px 0px;
    overflow: hidden;

    :global(.button) {
      font-size: 20px;
      padding: 0;
      flex: 1 1 auto;
      height: 100%;
      border-radius: 0;

      &:first-child {
        padding-left: 3px;
      }

      &:last-child {
        padding-right: 3px;
      }

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 1200px) {
  .frame-edit {
    &__btn-group {
      width: 50px;
      :global(.button) {
        padding-right: 0;
      }
    }
    &__fullscr-btn {
      display: none;
    }
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .designer {
    &__edit {
      position: absolute;
      left: 0;
      top: 45px;
      bottom: 0;
      height: auto;
      z-index: 996;
      padding: 10px 10px 0;
    }
  }
}

@media (max-width: 1024px) {
  .navbar-menu {
    padding-bottom: 0;
    box-shadow: none;
  }
}

@media (max-width: 768px) {
  .drop-info {
    padding-top: 0;
    align-items: center;
  }

  .editor {
    min-height: calc(150vw - 120px);
  }

  .control {
    &:global(.button) {
      padding-left: 10px;
      padding-right: 10px;

      :global(.icon):first-child:not(:last-child) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &_mobile-hide {
      display: none;
    }

    &__text {
      display: none;
    }
  }

  .frame__btn-group {
    display: none;
  }

  .mode-control {
    display: none;
  }
}

@media (max-width: 576px) {
  .editor {
    min-height: calc(150vw - 100px);
  }
}
</style>
