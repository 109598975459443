<template>
  <ul v-if="nodes">
    <li v-for="node in nodes" :key="node.name">
      <slot :node="node">
        {{ node.name }}
      </slot>
      <recursive-item :nodes="node.children">
        <template slot-scope="props">
          <slot :node="props.node">
            {{ props.node.name }}
          </slot>
        </template>
      </recursive-item>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'RecursiveItem',
  props: {
    nodes: { default: null, type: Array },
  },
};
</script>
