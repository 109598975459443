<template>
  <div class="columns is-marginless is-mobile">
    <div class="column is-paddingless">
      {{ node.name }}
      <span
        v-if="node.meta.description"
        class="has-text-grey-lighter"
      >- {{ node.meta.description }}</span>
    </div>
    <div class="column is-paddingless is-narrow">
      <span
        class="icon is-clickable"
        title="Copy"
        @click="copyPath(node)"
      >
        <font-awesome-icon :icon="['fal', 'copy']" />
      </span>
    </div>
    <div class="column is-paddingless is-narrow is-fixed has-text-primary">
      <type-selector
        :node="node"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import { openNotification } from '@/components/notification-service';

import TypeSelector from './type-selector';

export default {

  components: { TypeSelector },
  props: {
    node: { required: true, type: Object },
  },

  methods: {
    copyPath(node) {
      const text = `{{${node.path ? node.path.join('.') : node.name}}}`;

      if (window.clipboardData != null) {
        window.clipboardData.setData('Text', text);
      } else {
        const $temp = document.createElement('input');
        document.body.append($temp);
        $temp.value = text;
        $temp.select();
        document.execCommand('copy');
        $temp.remove();
      }

      openNotification({ message: 'copied', type: 'notification', duration: 1000 });
    },
  },
};
</script>

<style scoped>
.is-fixed {
    width: 100px;
}
</style>
