export default {
  OneDrive: 'OneDrive',
  SharePoint: 'SharePoint',
  Dropbox: 'Dropbox',
  GoogleDrive: 'GoogleDrive',
  Webhook: 'Webhook',
  Email: 'Email',
  DocuSign: 'DocuSign',
  DocuSignSandbox: 'DocuSignSandbox',
  XodoSign: 'XodoSign',
  DropboxSign: 'DropboxSign',
  AdobeSign: 'AdobeSign',
  Gmail: 'Gmail',
  SignNow: 'SignNow',
  Outlook: 'Outlook',
  Box: 'Box',
  Slack: 'Slack',
  Teams: 'Teams',
  ZohoSign: 'ZohoSign',
  EmailSmtp: 'EmailSmtp',
  ZohoMail: 'ZohoMail',
  AmazonEmail: 'AmazonEmail',
};
