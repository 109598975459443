var render, staticRenderFns
import script from "./transition-expand.vue?vue&type=script&lang=js&"
export * from "./transition-expand.vue?vue&type=script&lang=js&"
import style0 from "./transition-expand.vue?vue&type=style&index=0&id=5f3ce295&prod&scoped=true&lang=css&"
import style1 from "./transition-expand.vue?vue&type=style&index=1&id=5f3ce295&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f3ce295",
  null
  
)

export default component.exports