<template>
  <div class="field">
    <draggable-wrapper
      :ordered-array="draggableArray"
      @reorder="updateRecipients"
    >
      <div
        v-for="(v, index) in recipients"
        :key="index"
        :class="$style.fields"
      >
        <div
          class="control drag-area"
          title="Drag to reorder recipients."
        >
          <button
            type="button"
            :class="[$style.moveBtn, 'button is-white']"
          >
            <span class="icon">
              <font-awesome-icon :icon="[ 'fas', 'grip-vertical' ]" />
            </span>
          </button>
        </div>

        <slot
          :item="v"
          :index="index"
          :recipientTypes="types"
        />

        <div class="control">
          <button
            :class="[$style.removeBtn, 'button is-white']"
            type="button"
            title="Delete"
            @click="deleteRecipient(index)"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fal', 'trash-can']" />
            </span>
          </button>
        </div>
      </div>
    </draggable-wrapper>
  </div>
</template>

<script>
import DraggableWrapper from '@/components/basic/draggable/draggable-wrapper.vue';

export default {
  name: 'Recipients',
  components: { DraggableWrapper },
  props: {
    recipients: {
      type: Object,
      default: () => ({}),
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      draggableArray: this.mapRecipientsForm(),
    };
  },
  watch: {
    recipients() {
      this.draggableArray = this.mapRecipientsForm();
    },
  },
  methods: {
    deleteRecipient(index) {
      return this.$emit('deleteRecipient', index);
    },
    mapRecipientsForm() {
      return Object.values(this.recipients).map((x) => x.$model);
    },
    updateRecipients(recipients) {
      return this.$emit('updateRecipients', recipients);
    },
  },
};
</script>

<style lang="scss" module>
.fields{
  display: flex;
  &:not(:last-child) {
    margin-bottom: 12px;

    @media (max-width: 1200px) {
      margin-bottom: 16px;
    }

    @media (max-width: 768px) {
      margin-bottom: 12px;
    }

    @media (max-width: 576px) {
      margin-bottom: 16px;
    }
  }
}
.removeBtn {
  margin-right: -7px;

  &:hover, &:focus, &:active {
    background-color: transparent !important;
  }
}

.moveBtn {
  margin-left: -7px;
  cursor: move;

  &:hover, &:focus, &:active {
    background-color: transparent !important;
  }
}
</style>
