export const componentName = 'signnow-delivery-step';
export const type = 'signNow';
export const name = 'Send using signNow';
export const icon = 'service-signnow';
export const description = 'Send the result document to signNow for signing.';
export const fullDescription = 'Send the result document to signNow for signing. Learn more in the <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/signnow.html" target="_blank">the documentation.</a>';

export const options = {
  type, name, icon, description, fullDescription,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: componentName,
    propsData: options,
  },
};
