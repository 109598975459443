export const type = 'Email';
export const name = 'Send email';
export const icon = 'service-email';
export const description = 'Send the result document as an attachment by email.';
export const fullDescription = 'Send the result document as an attachment by email.';

export const options = {
  type, name, icon, description, fullDescription,
};

export const component = {
  title: type,
  description,
  fullDescription,
  settings: {
    type: 'email-delivery-step',
    propsData: options,
  },
};
