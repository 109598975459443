import { createSkeleton, createPropsData } from './step-skeleton-factory';
import { options } from '../ready/ready-step-options';

export function createReady(payload) {
  return {
    ...createSkeleton(),
    settings: {
      type: 'ready-step',
      propsData: createPropsData(options, payload),
    },
  };
}
