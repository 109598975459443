<template>
  <ul
    class="stepper"
    :class="`is-${size}`"
  >
    <li
      v-for="(step, index) in steps"
      :key="step.index"
      :class="[`is-${size}`, {
        'is-active': step.isActive && !isStepCreating,
        'is-completed': step.isFinished && !isStepCreating,
        'is-invalid': step.isInvalid && !isStepCreating,
      }, 'step']"
    >
      <div
        class="level is-marginless is-clickable is-mobile"
        :class="$style.step"
        @click="select(index)"
      >
        <span
          class="icon is-rounded is-bordered"
          :class="`is-${size}`"
        >
          <Icon
            :name="step.settings.propsData.icon"
            :width="step.settings.propsData.icon_width"
            :height="step.settings.propsData.icon_height"
            :translate="step.settings.propsData.translate || null"
          />
        </span>

        <span
          class="level-item"
          :style="{ width: `${step.settings.propsData.name_width}px` }"
        >
          {{ step.settings.propsData.name }}
        </span>

        <font-awesome-icon
          v-if="!['ready-step', 'conversion-setup-step'].includes(step.settings.type)"
          :icon="['fal', 'trash-can']"
          :class="$style.deleteStep"
          @click.stop="deleteStep(step, index)"
        />
      </div>

      <transition-expand>
        <ul
          v-if="step.isActive && !isStepCreating && step.settings.propsData.sub_steps"
          class="item sub-steps"
        >
          <li
            v-for="(subStep, subStepIndex) in step.settings.propsData.sub_steps"
            :key="subStepIndex"
            :class="[
              'is-clickable', { 'has-text-weight-bold': currentSubStep === subStepIndex },
            ]"
            style="font-size: 0.9rem;"
            @click="selectSubStep(subStepIndex)"
          >
            {{ subStep }}
          </li>
        </ul>
      </transition-expand>

      <div
        class="step-content-wrapper"
        :class="[
          `is-${size}`, { 'is-empty': index !== steps.length - 2 },
        ]"
      >
        <p
          v-if="index === steps.length - 2"
          class="level is-marginless is-clickable is-mobile"
          :class="{ 'is-active': isStepCreating }"
          @click="addStep(index)"
        >
          <span
            class="icon is-rounded is-bordered is-small"
            style="margin-left: -2rem;"
          >
            <font-awesome-icon
              :icon="['far', 'plus']"
              size="xs"
            />
          </span>

          <span
            class="level-item"
            style="margin: 0; margin-left: -0.5rem; padding-left: 2rem; line-height: 0.75rem;"
          >
            Add delivery
          </span>
        </p>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useProcessesStore, useProcessStepsStore } from '@/stores/index.js';
import TransitionExpand from './transition-expand.vue';
import Icon from '@/components/icon.vue';

export default {
  name: 'Stepper',
  components: {
    Icon,
    TransitionExpand,
  },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    confirmCallback: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      subStepId: 0,
    };
  },
  computed: {
    ...mapState(useProcessStepsStore, ['steps', 'isStepCreating', 'currentSubStep']),
  },

  methods: {
    ...mapActions(useProcessesStore, ['deleteDelivery']),
    ...mapActions(useProcessStepsStore, ['selectSubstep', 'selectStep', 'createStep']),

    select(stepIndex) {
      this.subStepId = 0;
      this.selectStep({ stepIndex, confirm: this.confirmCallback });
    },

    selectSubStep(subStepId) {
      this.subStepId = subStepId;
      this.selectSubstep(subStepId);
    },

    addStep(stepId) {
      this.createStep(stepId);
    },

    deleteStep(step, index) {
      this.$emit('delete-step', {
        deliveryId: step.settings.propsData.payload.id,
        index,
      });
    },
  },
};
</script>

<style lang="scss" module>
.step {
  .deleteStep {
    position: absolute;
    right: 9px;
    opacity: 0;
    transition: 0.6s;
    pointer-events: none;
  }

  @media (min-width: 769px) {
    &:hover {
      .deleteStep {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
}
</style>
