import { createSkeleton, createPropsData } from './step-skeleton-factory';
import { options } from '../conversion/conversion-setup-step-options';

export function createConversion(payload) {
  return {
    ...createSkeleton(true),
    settings: {
      type: 'conversion-setup-step',
      propsData: createPropsData(options, payload),
    },
  };
}
