<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div class="field">
        <label class="label level is-mobile">
          <span class="level-left">To</span>
          <div class="level-right">
            <span
              :class="[ 'tag', 'level-item', 'is-clickable', { 'is-primary' : isCcVisible } ]"
              @click="switchCc"
            >
              Cc
            </span>
            <span
              :class="[ 'tag', 'level-item', 'is-clickable', { 'is-primary' : isBccVisible } ]"
              @click="switchBcc"
            >
              Bcc
            </span>
          </div>
        </label>

        <tokens
          v-model="$v.form.to.$model"
          class="control"
        >
          <tags-input
            v-model="$v.form.to.$model"
            placeholder="Add new recipient"
            :class="{ 'is-danger': isInvalid($v.form.to) }"
            :add-tags-on-comma="true"
            :add-tags-on-blur="true"
          />
          <p
            v-if="isInvalid($v.form.to) && !$v.form.to.required"
            class="help is-danger"
          >
            | This field must be filled
          </p>
          <p
            v-if="isInvalid($v.form.to) && $v.form.to.required && !$v.form.to.emails"
            class="help is-danger"
          >
            | Please check that each entry is a valid email
          </p>
        </tokens>
      </div>

      <div
        v-if="isCcVisible"
        class="field"
      >
        <label class="label">Cc</label>
        <tokens
          v-model="$v.form.cc.$model"
          class="control"
        >
          <tags-input
            v-model="$v.form.cc.$model"
            placeholder="Add new recipient"
            :class="{ 'is-danger': isInvalid($v.form.cc) }"
            :add-tags-on-comma="true"
            :add-tags-on-blur="true"
          />
          <p
            v-if="isInvalid($v.form.cc) && !$v.form.cc.emails"
            class="help is-danger"
          >
            | This field should be a valid email address
          </p>
        </tokens>
      </div>

      <div
        v-if="isBccVisible"
        class="field"
      >
        <label class="label">Bcc</label>
        <tokens
          v-model="$v.form.bcc.$model"
          class="control"
        >
          <tags-input
            v-model="$v.form.bcc.$model"
            placeholder="Add new recipient"
            :class="{ 'is-danger': isInvalid($v.form.bcc) }"
            :add-tags-on-comma="true"
            :add-tags-on-blur="true"
          />
          <p
            v-if="isInvalid($v.form.bcc) && !$v.form.bcc.emails"
            class="help is-danger"
          >
            | This field should be a valid email address
          </p>
        </tokens>
      </div>

      <div class="field">
        <label class="label">Subject</label>
        <tokens
          v-model="$v.form.subject.$model"
          class="control"
        >
          <input
            v-model="$v.form.subject.$model"
            type="text"
            class="input"
            :class="{ 'is-danger': isInvalid($v.form.subject) }"
          >
          <p
            v-if="isInvalid($v.form.subject) && !$v.form.subject.required"
            class="help is-danger"
          >
            | This field must be filled
          </p>
        </tokens>
      </div>

      <email-editor
        v-if="initialized"
        v-model="$v.form.content.$model"
        one-line="true"
      />
      <p
        v-if="isInvalid($v.form.content) && !$v.form.content.required"
        class="help is-danger"
      >
        | The content must be specified
      </p>

      <advanced-settings>
        <div class="field">
          <label class="label">Display name</label>
          <tokens class="control">
            <input
              v-model="$v.form.displayName.$model"
              type="text"
              class="input"
              placeholder="Default: Plumsail Documents Delivery"
            >
          </tokens>
        </div>
        <div class="field">
          <label class="label">Reply-to address</label>
          <tokens
            v-model="$v.form.replyTo.$model"
            class="control"
            position="is-top-left"
          >
            <input
              v-model="$v.form.replyTo.$model"
              type="text"
              class="input"
              :placeholder="userEmail"
            >
          </tokens>
        </div>
      </advanced-settings>
    </template>
  </step-layout>
</template>

<script>
import VoerroTagsInput from '@voerro/vue-tagsinput';
import { required, email, helpers } from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'pinia';
import { useProcessesStore, useSettingsStore } from '@/stores/index.js';

import DynamicStep from '@/components/wizard/dynamic-step.vue';
import EmailEditorComponent from '@/components/rich-text-editor/index.vue';
import advancedSettings from '../../components/delivery/advancedSettings/advanced-settings.vue';
import Tokens from '@/components/tokens.vue';

const token = helpers.regex('token', /{{.*}}/);

export default {
  name: 'EmailDeliveryStep',
  components: {
    'email-editor': EmailEditorComponent,
    'tags-input': VoerroTagsInput,
    advancedSettings,
    Tokens,
  },
  extends: DynamicStep,
  data() {
    return {
      initialized: false,
      form: {
        id: null,
        to: [],
        cc: null,
        bcc: null,
        subject: null,
        content: null,
        replyTo: null,
        displayName: null,
      },

      isAdvanceVisible: false,
      userEmail: null,
    };
  },

  computed: {
    ...mapState(useSettingsStore, ['getProfile']),

    isCcVisible() {
      return this.form.cc !== null;
    },

    isBccVisible() {
      return this.form.bcc !== null;
    },
  },

  watch: {
    '$v.form.to.$model': {
      handler(value, previos) {
        if (value.value === previos.value) {
          this.$v.form.to.$reset();
        }
      },
    },

    '$v.form.cc.$model': {
      handler(value, previos) {
        if (value && !previos) {
          this.$v.form.cc.$reset();
        }

        if (value && previos) {
          if (value.value === previos.value) {
            this.$v.form.cc.$reset();
          }
        }
      },
    },

    '$v.form.bcc.$model': {
      handler(value, previos) {
        if (value && !previos) {
          this.$v.form.bcc.$reset();
        }

        if (value && previos) {
          if (value.value === previos.value) {
            this.$v.form.bcc.$reset();
          }
        }
      },
    },
  },

  mounted() {
    this.userEmail = `Default: ${this.getProfile.email}`;
  },
  methods: {
    ...mapActions(useProcessesStore, ['createOrUpdateDelivery']),
    switchCc() {
      this.form.cc = this.isCcVisible ? null : [];
    },
    switchBcc() {
      this.form.bcc = this.isBccVisible ? null : [];
    },
    async submitForm() {
      const dto = {
        ...this.form,
        to: this.form.to.map((r) => r.value).join(','),
        cc: this.form.cc ? this.form.cc.map((r) => r.value).join(',') : null,
        bcc: this.form.bcc ? this.form.bcc.map((r) => r.value).join(',') : null,
      };

      const { data } = await this.createOrUpdateDelivery(
        {
          processId: this.processId,
          deliveryName: 'email',
          deliveryData: dto,
        },
      );

      return data;
    },
    setData() {
      this.initialized = true;

      this.form.id = this.payload ? this.payload.id : null;

      this.form.to = this.payload
        ? this.payload.to.split(',').map((value) => ({
          key: '',
          value,
        }))
        : [];

      this.form.cc = this.payload && this.payload.cc
        ? this.payload.cc.split(',').map((value) => ({
          key: '',
          value,
        }))
        : null;

      this.form.bcc = this.payload && this.payload.bcc
        ? this.payload.bcc.split(',').map((value) => ({
          key: '',
          value,
        }))
        : null;

      this.form.subject = this.payload ? this.payload.subject : null;
      this.form.content = this.payload ? this.payload.content : '';

      this.form.replyTo = this.payload ? this.payload.replyTo : null;
      this.form.displayName = this.payload ? this.payload.displayName : null;

      if (!!this.form.displayName || !!this.form.replyTo) {
        this.isAdvanceVisible = true;
      }
    },
  },

  validations() {
    const defaultValidation = {
      form: {
        to: {
          required,
          $each: {
            emails(entry) {
              return email(entry.value) || token(entry.value);
            },
          },
        },
        subject: { required },
        content: { required },
        replyTo: {},
        displayName: {},
      },
    };

    if (this.isCcVisible) {
      defaultValidation.form = {
        ...defaultValidation.form,
        cc: {
          $each: {
            emails(entry) {
              return email(entry.value) || token(entry.value);
            },
          },
        },
      };
    }

    if (this.isBccVisible) {
      defaultValidation.form = {
        ...defaultValidation.form,
        bcc: {
          $each: {
            emails(entry) {
              return email(entry.value) || token(entry.value);
            },
          },
        },
      };
    }

    return defaultValidation;
  },
};
</script>
