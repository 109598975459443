<template>
  <button
    type="button"
    :class="[
      $style.button,
      'button is-white',
    ]"
    @click="$emit('click')"
  >
    <span
      v-if="icon"
      :class="[$style.button__icon, iconClass]"
    >
      <font-awesome-icon :icon="[ 'fal', icon ]" />
    </span>
    <span>
      {{ title }}
    </span>
  </button>
</template>
<script>
export default {
  name: 'TextButton',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    iconClass: {
      type: [String, Array],
      default: '',
    },
  },
};
</script>
<style lang="scss" module>
.button {
  padding-left: 0;

  &:hover, &:focus, &:active {
    background-color: transparent !important;
    outline: none !important;
  }

  &__icon {
    margin-right: 4px;
  }
}
</style>
