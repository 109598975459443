<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div class="content">
        <div v-if="form.account">
          <account-info
            :name="form.account"
            @reconect="reconnect"
            @sign-out="signoutPopup"
          />

          <div class="field">
            <label class="label">Root folder</label>
            <div
              class="control select is-fullwidth"
              :class="{ 'is-loading': loading }"
              position="is-top-left"
            >
              <select
                v-model="form.selectedFolder"
                :disabled="loading"
              >
                <option :value="null">
                  Personal folder
                </option>
                <option
                  v-for="folder in form.folders"
                  :key="folder.id"
                  :value="folder"
                >
                  {{ folder.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="field">
            <label class="label">Subfolder</label>
            <Tokens
              v-model="$v.form.folder.$model"
              class="control"
              position="is-top-left"
            >
              <input
                v-model="$v.form.folder.$model"
                class="input"
                type="text"
                :class="{ 'is-danger': isInvalid($v.form.folder) }"
              >
              <p
                v-if="isInvalid($v.form.folder)"
                class="help is-danger"
              >
                Please enter a name that doesn't include any special
                (<span class="has-background-light is-family-code">" * : &lt; > ? \ |</span>)
                characters or end with "."
              </p>
            </Tokens>
          </div>
        </div>

        <div
          v-else
          class="content"
        >
          <a @click="signinPopup">Connect to Dropbox</a>
        </div>
      </div>
    </template>
  </step-layout>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import { matchFolderName } from '@/utils/validators.js';
import BaseStep from '@/components/deliveries/base-delivery-step.vue';
import Tokens from '@/components/tokens.vue';
import DeliveriesNames from '../deliveries-names';
import OAuthClient from '../../services/oauth-client';
import AccountInfo from '../../components/delivery/account-info';
import { apiEndpoint } from '@/utils/url-manager.js';

export default {
  name: 'DropboxDeliveryStep',
  components: {
    Tokens,
    AccountInfo,
  },
  extends: BaseStep,
  params: ['processId'],
  data() {
    return {
      form: {
        id: null,
        account: null,
        folder: null,
        accessToken: null,
        folders: [],
        selectedFolder: null,
      },
      cacheId: null,
      oauthClient: null,

      authorize: null,
      clientId: null,
      scope: null,
      redirectUrl: null,
      logout: null,
      loading: true,
    };
  },

  created() {
    this.oauthClient = new OAuthClient();
  },

  validations() {
    const defaultValidation = {
      form: {
        account: { required },
        folder: { matchFolderName },
        selectedFolder: {},
      },
    };

    return defaultValidation;
  },

  async mounted() {
    const settings = (await axios.get(`${apiEndpoint}auth/providers/${DeliveriesNames.Dropbox}`)).data;

    this.authorize = settings.authorizeEndpoint;
    this.clientId = settings.clientId;
    this.scope = settings.scope;
    this.redirectUrl = settings.redirectUrl;
    this.logout = settings.logoutEndpoint;

    this.loading = true;
    await this.setFolders();
    this.loading = false;
  },

  methods: {
    async signinPopup() {
      await this.signoutPopup();

      const params = { client_id: this.clientId, redirect_uri: this.redirectUrl, token_access_type: 'offline' };
      const accessData = await this.getAccessData(
        this.authorize, params, DeliveriesNames.Dropbox, this.oauthClient, true,
      );
      if (accessData) {
        this.form.account = accessData.account;
        this.cacheId = accessData.cacheId;

        await this.setFolders();
      }
    },

    async signoutPopup() {
      if (!this.form.account) {
        return;
      }

      try {
        await axios.get(`${apiEndpoint}documents/processes/dropbox/${this.processId}/logout?cacheId=${this.cacheId || ''}&deliveryId=${this.form.id}`);
      } catch (e) {
        console.log(e);
      }

      this.form.account = null;
    },

    async reconnect() {
      await this.signinPopup();
    },

    async submitForm() {
      this.form.rootFolderId = this.form.selectedFolder?.id || null;
      this.form.rootFolderName = this.form.selectedFolder?.name || null;

      return (await this.updateDeliverySettings(
        this.processId,
        { ...this.form, cacheId: this.cacheId }, DeliveriesNames.Dropbox,
      )).data;
    },

    setData() {
      if (this.payload) {
        this.form.id = this.payload.id;
        this.form.account = this.payload.account;
        this.form.folder = this.payload.folder;

        if (this.payload.rootFolderId) {
          this.form.selectedFolder = {
            id: this.payload.rootFolderId,
            name: this.payload.rootFolderName,
          };
        }
      }
    },

    async setFolders() {
      if (this.form.account) {
        try {
          const response = await axios.get(`${apiEndpoint}documents/processes/dropbox/${this.processId}/folders/team/root?cacheId=${this.cacheId || ''}&deliveryId=${this.form.id}`)
            .catch((error) => {
              const { message } = error.response.data.error;
              if (message && message.includes('invalid_access_token')) {
                this.form.account = null;
                this.signinPopup();
              }
            });

          response?.data.map((f) => this.form.folders.push(f));
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
};
</script>