export const componentName = 'adobesign-delivery-step';
export const type = 'Adobe Sign';
export const title = 'Adobe Sign';
export const name = 'Send using Adobe Sign';
export const icon = 'service-adobesign';
export const description = 'Send the result document to Adobe Sign for signing.';
export const fullDescription = 'Send the result document to Adobe Sign for signing. Learn more in <a href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/deliveries/adobe-sign.html" target="_blank">the documentation.</a>';


export const options = {
  type, name, icon, description, fullDescription,
};

export const component = {
  title,
  description,
  fullDescription,
  settings: {
    type: componentName,
    propsData: options,
  },
};
