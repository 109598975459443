<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div class="content">
        <div class="field">
          <label class="label">POST URL</label>
          <tokens
            v-model="$v.form.Url.$model"
            class="control is-expanded"
            :max-height="100"
          >
            <input
              v-model="$v.form.Url.$model"
              class="input"
              type="text"
              :class="{ 'is-danger': isInvalid($v.form.Url) }"
              placeholder="Webhook URL e.g. https://yourdomain.com/50c95d6d-75d2-482f-8254-0b2557007c5c"
            >
            <p
              v-if="isInvalid($v.form.Url) && !$v.form.Url.matchSiteUrl"
              class="help level-left is-danger"
            >
              Please fill Webhook URL
            </p>
          </tokens>
        </div>

        <advanced-settings :is-visible="isAdvanceVisible">
          <div>
            <div
              v-for="(v, index) in $v.form.headers.$each.$iter"
              :key="index"
              :class="$style.fields"
            >
              <div :class="$style.fields__tokens">
                <tokens
                  v-model="v.name.$model"
                  :class="[$style.fields__field, 'control is-expanded']"
                  position="is-bottom-right"
                >
                  <input
                    v-model="v.name.$model"
                    class="input"
                    type="text"
                    placeholder="Header name"
                  >
                </tokens>
                <tokens
                  v-model="v.value.$model"
                  :class="[$style.fields__field, 'control is-expanded']"
                >
                  <input
                    v-model="v.value.$model"
                    class="input"
                    type="text"
                    placeholder="Header value"
                  >
                </tokens>
              </div>
              <div class="control">
                <button
                  :class="[$style.removeBtn, 'button is-white']"
                  type="button"
                  title="Delete"
                  @click="deleteHeader(index)"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fal', 'trash-can']" /></span>
                </button>
              </div>
            </div>
          </div>

          <text-button
            title="Add header"
            class="mb-3"
            icon="plus"
            @click="addHeader"
          />

          <div class="field">
            <label class="label">JSON data</label>
            <tokens
              v-model="$v.form.json.$model"
              :z-index="10"
            >
              <json-viewer
                v-model="$v.form.json.$model"
                placeholder="Place your JSON content here"
              />
              <p>
                <a
                  href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/tokens-in-process-fields.html"
                  target="_blank"
                >Use tokens</a> to reference data from you input data
              </p>
            </tokens>
          </div>
        </advanced-settings>
      </div>
    </template>
  </step-layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'pinia';
import { useProcessesStore } from '@/stores/index.js';
import DeliveriesNames from '../deliveries-names';
import DynamicStep from '@/components/wizard/dynamic-step.vue';
import { matchSiteUrl } from '@/utils/validators.js';
import JsonViewerComponent from '@/components/json-viewer/index.vue';
import advancedSettings from '../../components/delivery/advancedSettings/advanced-settings.vue';
import textButton from '../../components/text-button.vue';
import Tokens from '@/components/tokens.vue';
import Confirm from '@/components/modals/confirm.vue';

export default {
  name: 'WebhookDeliveryStep', // the new delivery extends the dynamic step
  components: {
    jsonViewer: JsonViewerComponent,
    advancedSettings,
    Tokens,
    textButton,
  },
  extends: DynamicStep,

  data() {
    return {
      form: {
        id: null,
        Url: '',
        headers: [
          {
            name: '',
            value: '',
          },
        ],
        json: '',
      },
      isAdvanceVisible: false,
    };
  },

  watch: {
    'form.headers': function (newVal) {
      if (newVal.length === 0) {
        this.form.headers.push({ name: '', value: '' });
      }
    },
  },

  methods: {
    ...mapActions(useProcessesStore, ['createOrUpdateDelivery']),

    setData() {
      if (this.payload) {
        this.form.id = this.payload.id;
        this.form.Url = this.payload.url;
        this.form.headers = this.payload.headers.map((value) => ({ ...value }));
        this.form.json = this.payload.json;

        if (
          (this.form.headers && this.form.headers.length > 0 && this.form.headers[0].name)
                    || (this.form.json && this.form.json !== '')
        ) {
          this.isAdvanceVisible = true;
        }

        if (this.form.headers && this.form.headers.length === 0) {
          this.form.headers.push({ name: '', value: '' });
        }
      }
    },

    deleteHeader(index) {
      this.$modal.show(
        Confirm,
        {
          title: 'Delete header',
          message: 'Are you sure you want to delete this the header?',
          onSubmit: () => {
            this.form.headers.splice(index, 1);
          },
          submitBtnText: 'Delete',
          showCloseBtn: true,
        },
        {
          draggable: '.modal-handler',
          clickToClose: true,
          width: '455px',
          height: 'auto',
          adaptive: true,
        },
      );
    },

    addHeader() {
      this.form.headers.push({ name: '', value: '' });
    },

    async submitForm() {
      const form = { ...this.form };

      form.headers = form.headers.filter((el) => !!el.name);

      const { data } = await this.createOrUpdateDelivery({
        processId: this.processId,
        deliveryName: DeliveriesNames.Webhook,
        deliveryData: { ...form, cacheId: this.cacheId },
      });

      return data;
    },
  },

  validations() {
    const defaultValidation = {
      form: {
        Url: { required, matchSiteUrl },
        headers: {
          $each: {
            name: {},
            value: {},
          },
        },
        json: {},
      },
    };

    return defaultValidation;
  },
};
</script>

<style lang="scss" module>
.fields {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &__tokens {
    display: flex;
    gap: 8px;
    flex: 1 1 auto;
  }

  &__field {
    flex: 1 1 auto;
  }

  @media (max-width: 576px) {
    &__tokens {
      flex-direction: column;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.removeBtn {
  margin-right: -7px;

  &:hover, &:focus, &:active {
    background-color: transparent !important;
  }
}
</style>
