import axios from 'axios';
import { randomString } from '@/utils/index.js';
import { apiEndpoint } from '@/utils/url-manager.js';

const api = apiEndpoint;
export default {
  methods: {
    getZohoAccessData(authPath, queryParams, environmentType, client, withState, service) {
      return new Promise((resolve) => {
        let url = `${authPath}?`;
        if (queryParams) {
          const params = ['response_type=code'];
          Object.keys(queryParams).forEach((key) => {
            params.push(`${key}=${queryParams[key]}`);
          });

          url += params.join('&');
        }

        const state = randomString(10);
        client.showPopup(withState ? `${url}&state=${state}` : url, async (event) => {
          if (event.data !== Object(event.data) && event.data && event.data.indexOf('code=') !== -1) {
            const eventUrl = new URL(`${event.origin}/${event.data}`);
            let allowed = true;
            if (withState) {
              allowed = state === eventUrl.searchParams.get('state');
            }

            if (allowed) {
              const location = eventUrl.searchParams.get('location');
              const eventData = `code=${eventUrl.searchParams.get('code')}&state=${eventUrl.searchParams.get('state')}&location=${location}`;
              const response = await axios.get(`${api}documents/processes/zoho${service}/auth/connect?type=${environmentType}&${eventData}`);
              resolve({ ...response.data, location });
            }
            return true;
          }
          return false;
        });
      });
    },
  },
};
