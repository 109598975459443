<template>
  <div :class="$style.wrapper">
    <p :class="$style.description">
      Use
      <a
        href="https://www.make.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Make
      </a> to integrate your process with hundreds of services.
      Select template below or find examples in
      <a
        href="https://plumsail.com/docs/documents/v1.x/getting-started/use-from-make.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        the documentation.
      </a>
    </p>
    <div
      v-if="loading"
      :class="$style.loader"
    >
      <spinner
        :size="35"
        :duration="1000"
      />
    </div>
    <iframe
      v-show="!loading"
      ref="iframe"
      title="Make-frame"
      :class="$style.frame"
      :src="`data:text/html;charset=utf-8,${script}</script>${style}`"
    />
  </div>
</template>

<script>
import Spinner from '@/components/spinner.vue';

export default {
  name: 'MakeTab',
  components: { Spinner },
  data() {
    return {
      script: '<script type="text/javascript" src="https://www.make.com/en/embed-builder/api/templates/list?lang=en&mode=list&templates=10748&templates=10745&templates=10746&templates=10747&templates=10751&templates=10750&templates=10752&templates=10753&partner=true">',
      style: '<style>.make-embed-wrapper{max-width: 100% !important}</style>',
      loading: true,
    };
  },

  mounted() {
    this.$refs.iframe.addEventListener('load', () => {
      this.loading = false;
    });
  },
};
</script>
<style lang="scss" module>
.loader {
  padding: 50px;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.description {
  margin-bottom: 1em;
}
.frame {
  flex: 1 0 auto;
  overflow: auto;

  @media (max-width: 768px) {
    min-height: 1600px;
  }
  @media (max-width: 576px) {
    min-height: 1900px;
  }
}
</style>
