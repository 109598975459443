<template>
  <div class="field is-grouped wrapper">
    <div class="control with-label">
      <input
        :id="id"
        v-model="checked.$model"
        class="switch is-rounded"
        type="checkbox"
      >
      <label
        :for="id"
        class="advanced-settings-switch"
      >{{ labelText }}</label>
    </div>

    <div class="control">
      <div class="field is-grouped">
        <div class="control">
          <input
            v-if="checked.$model"
            v-model="number.$model"
            class="input is-small"
            type="number"
            min="1"
            value="1"
            step="1/"
          >
        </div>
        <div
          v-if="checked.$model && !!numberLabelText"
          class="control"
        >
          <span>{{ numberLabelText }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdvancedSettingsItemNumber',
  props: {
    id: {
      type: String,
      required: true,
    },
    checked: {
      type: Object,
      required: true,
    },
    tooltipMsg: {
      type: String,
    },
    labelText: {
      type: String,
    },
    number: {
      type: Object,
    },
    numberLabelText: {
      type: String,
    },
  },
  watch: {
    'number.$model': function handler(newVal) {
      if (!newVal) {
        this.number.$model = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
    .wrapper {
      align-items: center;
    }
    input.is-small{
        width: 65px;
    }
    .with-label{
        flex-basis: 230px;
    }
    label.advanced-settings-switch {
      padding-top: 0;
    }
    .field.is-grouped {
      align-items: center;
    }
    .field.is-grouped > .control {
      flex-shrink: 1;
    }
</style>
