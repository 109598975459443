<template>
  <div class="field">
    <text-button
      :class="$style.btn"
      :icon-class="[$style.btn__icon, { [$style.btn__icon_open]: isAdvanceVisible} ]"
      title="Advanced"
      icon="chevron-down"
      @click="toggleAdvanced"
    />
    <div v-if="isAdvanceVisible">
      <slot />
    </div>
  </div>
</template>

<script>
import textButton from '@/views/documents/processes/components/text-button.vue';

export default {
  name: 'AdvancedSettings',
  components: { textButton },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isAdvanceVisible: this.isVisible,
    };
  },
  watch: {
    isVisible: {
      handler(newVal) {
        this.isAdvanceVisible = newVal;
      },
    },
  },
  methods: {
    toggleAdvanced() {
      this.isAdvanceVisible = !this.isAdvanceVisible;
    },
  },
};
</script>
<style lang="scss" module>
.btn {
  font-weight: 600;
  color: #000000 !important;

  &__icon {
    margin-right: 6px;
    font-size: 14px;
    line-height: 14px;

    &_open {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
}
</style>
