<script>
import { mapWritableState, mapActions } from 'pinia';
import { useProcessesStore, useProcessStepsStore } from '@/stores/index.js';
import UnsavedChanges from '../modals/UnsavedChanges.vue';

export default {
  props: {
    subSteps: { type: Array, default: () => [] },
    subStep: { type: Number, default: 0 },
    payload: { type: Object, default: null },
    stepId: { type: [Number, String], required: true },
    name: { type: String, required: true },
    icon: { type: [String, Array], required: true },
  },

  computed: {
    ...mapWritableState(useProcessStepsStore, ['validateStep']),
    ...mapWritableState(useProcessesStore, ['processIsEdited']),
  },

  watch: {
    '$v.form.$anyDirty': {
      handler(dirty) {
        this.processIsEdited = dirty;
      },
    },
    validateStep(value) {
      if (value === this.stepId && value !== null) {
        this.checkValidity();
        this.validateStep = null;
      }
    },
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },

  methods: {
    ...mapActions(useProcessStepsStore, ['validityChecked']),

    onValidityChecked(status) {
      const isValid = !status.errors;

      this.validityChecked({
        isInvalid: !isValid,
        isFinished: isValid,
      });

      return isValid;
    },
    preventNav(event) {
      if (!this.$v.form.$anyDirty) {
        return;
      }

      event.preventDefault();
      event.returnValue = '';
    },

    async reset() {
      await this.setData();
      this.$v.form.$reset();
    },

    isInvalid(element) {
      return element.$error && this.$v.form.$dirty;
    },

    async checkValidity() {
      if (this.$v.form.$anyDirty || this.$v.form.$invalid) {
        const response = await this.showUnsavedChangesModal();
        if (!response) return;
        if (response === 'reset') {
          this.reset();
        } else {
          this.$v.form.$reset();
        }
      }
      this.validateStep = null;
      this.onValidityChecked({ errors: this.$v.form.$error });
    },

    waitForValidation() {
      return new Promise((resolve) => {
        this.$watch(() => !this.$v.form.$pending, (isNotPending) => {
          if (isNotPending) {
            resolve(!this.$v.form.$invalid);
          }
        }, { immediate: true });
      });
    },

    async submit() {
      this.$v.form.$touch();

      if (!await this.waitForValidation()) return false;

      this.$v.form.$reset();

      if (!this.submitForm) {
        throw new Error('The implementation has to have submitForm method');
      }

      const data = await this.submitForm();
      this.$emit('submit', data);
      return true;
    },

    async submitAndNext() {
      const isSubmit = await this.submit();
      if (!isSubmit) return;
      if (this.subStep < this.subSteps.length - 1) {
        this.$emit('navigateNextSubstep');
      } else this.$emit('navigateNext');
    },

    showUnsavedChangesModal() {
      return new Promise((resolve) => {
        this.$modal.show(
          UnsavedChanges,
          {
            onLeave: () => { resolve('reset'); },
            onSave: async () => {
              const isSubmited = await this.saveBeforeLeave();
              if (isSubmited) {
                resolve('saved');
              } else {
                resolve(false);
              }
            },
          },
          {
            draggable: '.modal-handler',
            clickToClose: true,
            width: '440px',
            height: 'auto',
            adaptive: true,
          },
          { closed: () => { resolve(false); } },
        );
      });
    },

    async saveBeforeLeave() {
      try {
        if (this.$refs.step?.submit) {
          await this.$refs.step.submit();
        } else {
          await this.submit();
        }
        return true;
      } catch {
        return false;
      }
    },
  },
};
</script>
