<template>
    <step-layout
      :name="headerName"
      :form-id="form.id"
      :full-description="fullDescription"
      @cancel="cancel"
      @delete-step="deleteStep"
      @submit="submit"
      @save="save"
    >
      <template #content>
        <div
          class="content"
        >
          <b-button @click="openSettings">
            <font-awesome-icon
              :icon="['fal', 'gears']"
              style="margin-left: -5px;"
            />
            App settings
          </b-button>
        </div>

        <div
          v-if="appSettings.accessKey"
          class="content"
        >
          <div class="field">
            <label for="From" class="label">From</label>
            <tokens
              v-model="$v.form.from.$model"
              class="control"
            >
              <input
                placeholder="Email address that is verified in Amazon SES"
                id="From"
                v-model="$v.form.from.$model"
                class="input"
                type="text"
                :class="{ 'is-danger': isInvalid($v.form.from) }"
              >
              <p
                v-if="isInvalid($v.form.from) && !$v.form.from.required"
                class="help is-danger"
              >
                This field must be filled
              </p>
              <p
                v-if="isInvalid($v.form.from) && $v.form.from.required && !$v.form.from.isInvalid"
                class="help is-danger"
              >
                Please check that is a valid email
              </p>
            </tokens>
          </div>

          <div class="field">
            <label for="Recipient" class="label">To</label>
            <tokens
              v-model="$v.form.to.$model"
              class="control"
            >
              <tags-input
                v-model="$v.form.to.$model"
                input-id="Recipient"
                placeholder="Add new recipient"
                :class="{ 'is-danger': isInvalid($v.form.to) }"
                add-tags-on-comma="add-tags-on-comma"
                add-tags-on-blur="add-tags-on-blur"
              />
              <p
                v-if="isInvalid($v.form.to) && !$v.form.to.required"
                class="help is-danger"
              >
                This field must be filled
              </p>
              <p
                v-if="isInvalid($v.form.to) && $v.form.to.required && !$v.form.to.emails"
                class="help is-danger"
              >
                Please check that each entry is a valid email
              </p>
            </tokens>
          </div>

          <div class="field">
            <label for="Subject" class="label">Subject</label>
            <tokens
              v-model="$v.form.subject.$model"
              class="control"
            >
              <input
                id="Subject"
                v-model="$v.form.subject.$model"
                class="input"
                type="text"
                :class="{ 'is-danger': isInvalid($v.form.subject) }"
              >
              <p
                v-if="isInvalid($v.form.subject) && !$v.form.subject.required"
                class="help is-danger"
              >
                This field must be filled
              </p>
            </tokens>
          </div>
          <email-editor
            v-model="$v.form.content.$model"
            one-line="oneLine"
          />
          <p
            v-if="isInvalid($v.form.content) && !$v.form.content.required"
            class="help is-danger"
          >
            The content must be specified
          </p>
          <advanced-settings :is-visible="isAdvanceVisible">
            <div class="field">
              <label for="DisplayName" class="label">Display name</label>
              <tokens
                v-model="$v.form.displayName.$model"
                class="control"
                position="is-top-left"
              >
                <input
                  id="DisplayName"
                  v-model="$v.form.displayName.$model"
                  class="input"
                  type="text"
                  :placeholder="userName"
                >
              </tokens>
            </div>
            <div class="field">
              <label for="Reply" class="label">Reply-to address</label>
              <tokens
                v-model="$v.form.replyTo.$model"
                class="control"
                position="is-top-left"
              >
                <input
                  id="Reply"
                  v-model="$v.form.replyTo.$model"
                  class="input"
                  type="text"
                  :placeholder="userEmail"
                >
              </tokens>
            </div>
          </advanced-settings>
        </div>
      </template>
    </step-layout>
  </template>

  <script>
  import { required, email, helpers } from 'vuelidate/lib/validators';
  import { mapState } from 'pinia';
  import TagsInput from '@voerro/vue-tagsinput';
  import { useSettingsStore } from '@/stores/index.js';
  import EmailEditor from '@/components/rich-text-editor/index.vue';
  import Tokens from '@/components/tokens.vue';
  import BaseStep from '@/components/deliveries/base-delivery-step.vue';
  import advancedSettings from '../../components/delivery/advancedSettings/advanced-settings.vue';
  import appSettingsModal from './amazon-settings-modal.vue';
  import DeliveriesNames from '../deliveries-names';

  const token = helpers.regex('token', /{{.*}}/);

  export default {
    name: 'AmazonEmailDeliveryStep',
    components: {
      EmailEditor,
      TagsInput,
      advancedSettings,
      Tokens,
    },
    extends: BaseStep,
    params: ['processId'],
    data() {
      return {
        form: {
          id: null,
          from: null,
          to: [],
          subject: null,
          content: null,
          replyTo: null,
          displayName: null,
          encryptedSecret: null,
        },
        appSettings: {
          accessKey: null,
          secretKey: null,
          region: null,
        },
        isAdvanceVisible: false,
      };
    },
    validations() {
      const defaultValidation = {
        form: {
          from: { 
            required,  
            isInvalid(entry) {
                return email(entry) || token(entry);
              },
          },
          to: {
            required,
            $each: {
              emails(entry) {
                return email(entry.value) || token(entry.value);
              },
            },
          },
          subject: { required },
          content: { required },
          replyTo: {},
          displayName: {},
        },
      };

      return defaultValidation;
    },

    computed: {
      ...mapState(useSettingsStore, ['getProfile']),
    },

    watch: {
      'form.displayName': {
        handler(value) {
          if (value && value.value !== '') {
            this.isAdvanceVisible = true;
          }
        },
      },

      '$v.form.to.$model': {
        handler(value, previos) {
          if (value.value === previos.value) {
            this.$v.form.to.$reset();
          }
        },
      },
    },

    mounted() {
      this.userName = this.getProfile.name;
      this.userEmail = this.getProfile.email;
    },

    methods: {
      openSettings() {
        this.$modal.show(
          appSettingsModal,
          {
            settings: this.appSettings,
            updateSettings: (value) => {
              this.appSettings = value;
            },
          },
          {
            clickToClose: false,
            adaptive: true,
            width: '440px',
            height: 'auto',
            draggable: '.modal-handler',
          },
        );
      },
      setData() {
        if (this.payload) {
          this.appSettings.accessKey = this.payload.accessKey || null;
          this.appSettings.secretKey = this.payload.secretKey || null;
          this.appSettings.region = this.payload.region || null;

          this.form.id = this.payload.id || null;
          this.form.from = this.payload.from || null;
          this.form.subject = this.payload.subject || null;
          this.form.content = this.payload.content || null;
          this.form.replyTo = this.payload.replyTo || null;
          this.form.displayName = this.payload.displayName || null;
          this.form.to = this.payload.to?.split(',').map((value) => ({
            key: '',
            value,
          })) || [];
        }
      },

      async submitForm() {
        if (!this.form.replyTo){
          this.form.replyTo = this.form.from;
        }

        const dto = {
          ...this.form,
          ...this.appSettings,
          to: this.form.to.map((r) => r.value).join(','),
        };

        const { data } = await this.updateDeliverySettings(
          this.processId,
          dto,
          DeliveriesNames.AmazonEmail,
        );
        return data;
      },
    },
  };
  </script>
