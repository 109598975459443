<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div class="content">
        <label class="label">Dropbox Sign API Key</label>
        <div class="field">
          <div class="control is-expanded">
            <p :class="[$style.control, 'control has-icons-right']">
              <input
                id="api-key"
                v-model="$v.form.apiKey.$model"
                class="input"
                :type="showKey ? 'text' : 'password'"
                :class="{ 'is-danger': isInvalid($v.form.apiKey) }"
              >

              <span
                class="icon is-clickable is-right"
                @mousedown="showApiKey(true)"
                @mouseup="showApiKey(false)"
                @mouseleave="showApiKey(false)"
              >
                <font-awesome-icon :icon="[ 'fal', 'eye' ]" />
              </span>
            </p>
            <p
              v-if="isInvalid($v.form.apiKey)"
              class="help level-left is-danger"
            >
              | Please fill API key
            </p>
          </div>
        </div>

        <label class="label">Title</label>
        <div class="field">
          <tokens
            v-model="$v.form.title.$model"
            class="control is-expanded"
          >
            <input
              v-model="$v.form.title.$model"
              type="text"
              class="input"
              :class="{ 'is-danger': isInvalid($v.form.title) }"
            >
            <p
              v-if="isInvalid($v.form.title)"
              class="help level-left is-danger"
            >
              | Please fill title
            </p>
          </tokens>
        </div>

        <label class="label">Message for signers</label>
        <div class="field">
          <tokens
            v-model="$v.form.emailMessage.$model"
            class="control is-expanded"
          >
            <textarea
              v-model="$v.form.emailMessage.$model"
              :class="[
                'input',
                $style.input,
                { 'is-danger': isInvalid($v.form.emailMessage) }
              ]"
              :rows="4"
            />
            <p
              v-if="isInvalid($v.form.emailMessage)"
              class="help level-left is-danger"
            >
              Please fill email message
            </p>
          </tokens>
        </div>
        <label class="label">Recipients</label>
        <div class="field recipients">
          <recipients
            :recipients="$v.form.recipients.$each.$iter"
            :types="recipientTypes"
            @updateRecipients="updateRecipients"
            @deleteRecipient="deleteRecipient"
          >
            <template #default="{item, index, recipientTypes}">
              <recipient-item
                :item="item"
                :index="index"
                :recipient-types="recipientTypes"
              />
            </template>
          </recipients>
        </div>

        <text-button
          title="Add recipient"
          icon="plus"
          @click="addRecipient"
        />

        <advanced-settings>
          <advanced-settings-item-empty
            id="sequentialSigning"
            :item="$v.form.sequentialSigning"
            :tooltip-msg="tooltipMsg"
            label-text="Sequential signing"
          />
          <advanced-settings-item-empty
            id="useTextTags"
            :item="$v.form.useTextTags"
            label-text="Use text tags"
          />
          <advanced-settings-item-empty
            id="testMode"
            :item="$v.form.testMode"
            label-text="Test Dropbox Sign mode"
          />
        </advanced-settings>
      </div>
    </template>
  </step-layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import DeliveriesNames from '../deliveries-names';
import { matchEmailOrTemplateOrListOfEmails } from '@/utils/validators.js';
import BaseStep from '@/components/deliveries/base-delivery-step.vue';
import recipients from '../../components/delivery/recipients';
import recipientItem from '../../components/delivery/recipient-item';
import advancedSettings from '../../components/delivery/advancedSettings/advanced-settings';
import advancedSettingsItemEmpty from '../../components/delivery/advancedSettings/advanced-settings-item-empty';
import textButton from '../../components/text-button.vue';
import Tokens from '@/components/tokens.vue';
import Confirm from '@/components/modals/confirm.vue';

export default {
  name: 'DropboxsignDeliveryStep',
  components: {
    recipients,
    recipientItem,
    advancedSettings,
    advancedSettingsItemEmpty,
    Tokens,
    textButton,
  },

  extends: BaseStep,
  params: ['processId'],
  data() {
    return {
      form: {
        id: null,
        account: null,
        title: null,
        apiKey: null,
        emailMessage: null,
        recipients: [
          {
            name: '',
            email: '',
            type: 1,
            order: 1,
          },
        ],
        sequentialSigning: false,
        testMode: false,
        useTextTags: false,
      },
      tooltipMsg: 'By default recipients sign documents parallelly. Enable this option to use sequential signing. Then drag and drop recipients above to change their order',
      cacheId: null,
      recipientTypes: [
        { id: 1, name: 'Needs to sign' },
        { id: 2, name: 'Receives a copy' },
      ],
      showKey: false,
    };
  },
  watch: {
    'form.recipients': function (newVal) {
      if (newVal.length === 0) {
        this.form.recipients.push({
          name: '', email: '', type: 1, order: 1,
        });
      } else {
        for (let index = 0; index < this.form.recipients.length; index += 1) {
          this.form.recipients[index].order = index + 1;
        }
      }
    },
  },

  validations() {
    const defaultValidation = {
      form: {
        title: { required },
        emailMessage: { required },
        recipients: {
          $each: {
            name: { required },
            email: { matchEmailOrTemplateOrListOfEmails },
            type: {},
            order: {},
          },
        },
        sequentialSigning: {},
        testMode: {},
        useTextTags: {},
        apiKey: { required },
      },
      recipientTypes: [],
    };

    return defaultValidation;
  },

  methods: {
    async submitForm() {
      return (await this.updateDeliverySettings(
        this.processId,
        { ...this.form, cacheId: this.cacheId }, DeliveriesNames.DropboxSign,
      )).data;
    },

    setData() {
      this.clearRecipients();
      if (this.payload) {
        this.form = {
          ...this.payload,
          recipients: this.payload.recipients.map((value) => ({ ...value })),
        };
      }
    },

    clearRecipients() {
      if (this.$v.form.recipients.$invalid && this.form.recipients.length > 0) {
        Object.keys(this.$v.form.recipients.$each).forEach((i) => {
          if (
            this.$v.form.recipients.$each.$iter[i]
            && this.$v.form.recipients.$each.$iter[i].$invalid
          ) {
            this.form.recipients.splice(i, 1);
          }
        });

        this.$v.form.$reset();
      }
    },

    showApiKey(val) {
      this.showKey = val;
    },

    addRecipient() {
      this.form.recipients.push({
        name: '', email: '', type: 1, order: 1,
      });
    },

    deleteRecipient(index) {
      this.$modal.show(
        Confirm,
        {
          title: 'Delete recipient',
          message: 'Are you sure you want to delete this the recipient?',
          onSubmit: () => {
            this.form.recipients.splice(index, 1);
          },
          submitBtnText: 'Delete',
          showCloseBtn: true,
        },
        {
          draggable: '.modal-handler',
          clickToClose: true,
          width: '455px',
          height: 'auto',
          adaptive: true,
        },
      );
    },

    updateRecipients(newRecipients) {
      this.form.recipients = newRecipients;
    },
  },
};
</script>

<style lang="scss" module>
textarea.input {
  height: 150px;
}

.control {
  :global(.icon.is-clickable) {
    pointer-events: initial;
  }
}
</style>
