<template>
    <textarea v-model="value"></textarea>
</template>

<script>
import CodeMirror from 'codemirror/lib/codemirror';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/scroll/simplescrollbars';

export default {
    name: 'html-content-editor',

    props: {
        value: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            skipEmit: false,
            codeMirror: null,
        }
    },

    mounted() {
        this.codeMirror = CodeMirror.fromTextArea(this.$el, {
            mode: 'text/html',
            indentUnit: 4,
            tabSize: 4,
            indentWithTabs: false,
            lineNumbers: false,
            autofocus: true,
            lineWrapping: true,
            autoRefresh: true,
            scrollbarStyle: 'simple',
        });

        this.codeMirror.on('change', cm => {
            this.skipEmit = true;
            this.$emit('input', cm.getValue());
        });

        var charWidth = this.codeMirror.defaultCharWidth(), basePadding = 4;

        this.codeMirror.on("renderLine", function(cm, line, elt) {
            var off = CodeMirror.countColumn(line.text, null, cm.getOption("tabSize")) * charWidth;
            elt.style.textIndent = "-" + off + "px";
            elt.style.paddingLeft = (basePadding + off) + "px";
        });
        this.codeMirror.refresh();

        this.$emit('load');
    },

    watch: {
        value(value) {
            if (!this.skipEmit) {
                this.codeMirror.setValue(value);
            }

            this.skipEmit = false
        }
    }
}
</script>

<style>
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/addon/scroll/simplescrollbars.css';

#editor>.CodeMirror {
    border: 1px solid #eee;
    height: 500px;
    width: 500px;
}

.CodeMirror-wrap pre {
    word-break: break-word;
}
</style>
