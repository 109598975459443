import { createEmail } from './step-email-factory';
import { createOneDrive } from './step-onedrive-factory';
import { createDropbox } from './step-dropbox-factory';
import { createReady } from './step-ready-factory';
import { createConversion } from './step-conversion-factory';
import { createSharePoint } from './step-sharepoint-factory';
import { createWebHook } from './step-webhook-factory';
import { createGoogleDrive } from './step-googledrive-factory';
import { createDocuSign } from './step-docusign-factory';
import { createXodoSign } from './step-xodosign-factory';
import { createSignNow } from './step-signnow-factory';
import { createDropboxSign } from './step-dropboxsign-factory';
import { createAdobeSign } from './step-adobesign-factory';
import { createGmail } from './step-gmail-factory';
import { createOutlook } from './step-outlook-factory';
import { createBox } from './step-box-factory';
import { createSlack } from './step-slack-factory';
import { createTeams } from './step-teams-factory';
import { createZohoSign } from './step-zohosign-factory';
import { createEmailSmtp } from './step-email-smtp-factory';
import { createZohoMail } from './step-zohomail-factory';
import { createAmazonEmail } from './step-amazon-email-factory';
import DeliveriesNames from '../deliveries-names';

export function createDelivery(type, payload) {
  switch (type) {
    case 'Conversion':
      return createConversion(payload);
    case DeliveriesNames.Email:
      return createEmail(payload);
    case DeliveriesNames.OneDrive:
      return createOneDrive(payload);
    case DeliveriesNames.Dropbox:
      return createDropbox(payload);
    case 'Ready':
      return createReady(payload);
    case DeliveriesNames.SharePoint:
      return createSharePoint(payload);
    case DeliveriesNames.Webhook:
      return createWebHook(payload);
    case DeliveriesNames.GoogleDrive:
      return createGoogleDrive(payload);
    case DeliveriesNames.DocuSign:
      return createDocuSign(payload);
    case DeliveriesNames.XodoSign:
      return createXodoSign(payload);
    case DeliveriesNames.DropboxSign:
      return createDropboxSign(payload);
    case DeliveriesNames.AdobeSign:
      return createAdobeSign(payload);
    case DeliveriesNames.Gmail:
      return createGmail(payload);
    case DeliveriesNames.SignNow:
      return createSignNow(payload);
    case DeliveriesNames.Outlook:
      return createOutlook(payload);
    case DeliveriesNames.Box:
      return createBox(payload);
    case DeliveriesNames.Slack:
      return createSlack(payload);
    case DeliveriesNames.Teams:
      return createTeams(payload);
    case DeliveriesNames.ZohoSign:
      return createZohoSign(payload);
    case DeliveriesNames.EmailSmtp:
      return createEmailSmtp(payload);
    case DeliveriesNames.ZohoMail:
      return createZohoMail(payload);
      case DeliveriesNames.AmazonEmail:
        return createAmazonEmail(payload);
    default: {
      console.error('The type of delivery is not listed!');
      return null;
    }
  }
}
