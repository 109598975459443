<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <Confirm
    :title="!loading ? 'SMTP server config' : 'Testing...'"
    :close-after-submit="false"
    show-close-btn
    :message="true"
    :on-submit="submit"
    :submit-btn-type="!loading ? 'is-primary' : 'is-primary is-loading'"
    @close="$emit('close')"
  >
    <form
      :class="$style.form"
      @submit.prevent="submit"
    >
      <b-field
        label="Email address"
        label-for="email"
      >
        <b-input
          id="email"
          v-model.trim="form.account"
          :placeholder="account"
          :disabled="loading"
        />
      </b-field>
      <b-field
        :type="invalidSmtp ? 'is-danger' : ''"
        label="SMTP server"
        label-for="smtp"
      >
        <b-input
          id="smtp"
          v-model.trim="form.host"
          :disabled="loading"
        />
        <p
          v-if="invalidSmtp"
          class="help is-danger"
        >
          This field must be filled
        </p>
      </b-field>
      <b-field
        label="Port"
        label-for="port"
      >
        <b-input
          id="port"
          v-model.trim="form.port"
          type="number"
          :disabled="loading"
          placeholder="587"
        />
      </b-field>
      <bulma-switch
        v-model="secureConnection"
        :captions="[true, false]"
        :show-captions="false"
        switch-id="secureConnection"
        :disabled="loading"
      >
        Use secure connection (TLS)
      </bulma-switch>
      <bulma-switch
        v-model="form.explicitTls"
        :captions="[true, false]"
        :show-captions="false"
        :disabled="!secureConnection"
        switch-id="explicitTLS"
      >
        Use explicit TLS
      </bulma-switch>
      <b-field
        label="User name"
        label-for="user"
      >
        <b-input
          id="user"
          v-model.trim="form.user"
          :placeholder="account"
          :disabled="loading"
        />
      </b-field>
      <b-field
        :type="invalidPassword ? 'is-danger' : ''"
        label="Password"
        label-for="password"
      >
        <div class="control has-icons-right">
          <input
            id="password"
            v-model="form.password"
            :type="viewingPassword ? 'text' : 'password'"
            :class="['input', 'control', 'has-icons-right', invalidPassword ? 'is-danger' : '']"
          >
          <span
            type="button"
            class="icon is-clickable is-right"
            @mousedown="showPassword(true)"
            @mouseup="showPassword(false)"
            @mouseleave="showPassword(false)"
          >
            <font-awesome-icon
              :icon="['fal', 'eye']"
              style="color: #000000;"
            />
          </span>
        </div>
        <p
          v-if="invalidPassword"
          class="help is-danger"
        >
          This field must be filled
        </p>
      </b-field>
      <div
        v-if="connectingError"
        class="message is-danger"
      >
        <div class="message-body">
          Unable to connect to SMTP server: {{ connectingError }}
        </div>
      </div>
    </form>
  </Confirm>
</template>
<script>
import { mapActions } from 'pinia';
import { useProcessesStore } from '@/stores/index.js';
import Confirm from '@/components/modals/confirm.vue';
import { BulmaSwitchComponent as BulmaSwitch } from '@/components/basic/index.js';
import { required } from '@/utils/validators.js';

export default {
  name: 'SmtpSettingsModal',
  components: { Confirm, BulmaSwitch },
  props: {
    account: { type: String, required: true },
    config: { type: Object, required: true },
    updateConfig: { type: Function, required: true },
  },

  data() {
    return {
      form: {
        account: null,
        user: null,
        password: null,
        host: null,
        port: null,
        ssl: true,
        explicitTls: true,
      },
      loading: false,
      connectingError: null,
      viewingPassword: false,
    };
  },
  validations: {
    form: {
      host: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    secureConnection: {
      get() { return this.form.ssl; },
      set(value) {
        if (!value) this.form.explicitTls = false;
        this.form.ssl = value;
      },
    },

    invalidSmtp() {
      return this.$v.form.host.$error && this.$v.form.$dirty;
    },
    invalidPassword() {
      return this.$v.form.password.$error && this.$v.form.$dirty;
    },
  },
  beforeMount() {
    this.form = { ...this.config };
  },
  methods: {
    ...mapActions(useProcessesStore, { checkConnect: 'checkSmtpSettings' }),

    async submit() {
      this.connectingError = null;

      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loading = true;
      const currentConfig = {
        ...this.form,
        account: this.form.account || this.account,
        port: this.form.port || 587,
        user: this.form.user || this.account,
      };
      const { error } = await this.checkConnect(currentConfig);
      this.loading = false;

      if (error) {
        this.connectingError = error.message;
        return;
      }

      this.updateConfig(currentConfig);
      this.$emit('close');
    },
    showPassword(val) {
      this.viewingPassword = val;
    },
  },
};
</script>
<style lang="scss" module>
.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
}
</style>
