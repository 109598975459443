<template>
  <div
    class="content"
    style="display: flex; flex: 1; flex-flow: column"
  >
    <p>
      Use
      <a
        href="https://make.powerautomate.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Power Automate</a> to integrate your process with hundreds of services.
      Select template below or find examples in
      <a
        href="https://plumsail.com/docs/documents/v1.x/getting-started/use-from-flow.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        the documentation.
      </a>
    </p>
    <div
      v-if="loading"
      :class="$style.loader"
    >
      <spinner
        :size="35"
        :duration="1000"
      />
    </div>
    <iframe
      v-show="!loading"
      ref="iframe"
      title="Power automate frame"
      src="https://flow.microsoft.com/en-us/widgets/templates/?q=Plumsail%20Documents%20process&amp;pagesize=10&amp;destination=details"
      style="flex: 1"
      :class="$style.frame"
    />
  </div>
</template>

<script>
import Spinner from '@/components/spinner.vue';

export default {
  name: 'PowerAutomateTab',
  components: { Spinner },
  data() {
    return {
      loading: true,
    };
  },

  mounted() {
    this.$refs.iframe.addEventListener('load', () => {
      this.loading = false;
    });
  },
};
</script>

<style lang="scss" module>
.loader {
  padding: 50px;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.frame {
  min-height: 725px;

  @media (max-width: 1240px) {
    min-height: 1170px;
  }

  @media (max-width: 1080px) {
    min-height: 2080px;
  }

  @media (max-width: 1023px) {
    min-height: 1170px;
  }

  @media (max-width: 926px) {
    min-height: 2080px;
  }
}
</style>
