/* eslint-disable camelcase */
export const type = 'Conversion';
export const name = 'Configure template';
export const icon = ['fas', 'brackets-curly'];
export const icon_attrs = 'bold lg';
export const icon_create_attrs = 'xlg';
export const icon_width = 20;
export const icon_height = 20;
export const sub_steps = ['Editor', 'Settings'];

export const options = {
  type,
  name,
  icon,
  icon_attrs,
  icon_create_attrs,
  icon_width,
  icon_height,
  sub_steps,
};
