<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div
        v-if="form.account"
        class="content"
      >
        <account-info
          :name="form.account"
          @reconect="reconnect"
          @sign-out="signoutPopup"
        />

        <div class="field">
          <label class="label">Email subject</label>
          <tokens
            v-model="$v.form.emailSubject.$model"
            class="control is-expanded"
          >
            <input
              v-model="$v.form.emailSubject.$model"
              class="input"
              type="text"
              :class="{ 'is-danger': isInvalid($v.form.emailSubject) }"
            >
            <p
              v-if="isInvalid($v.form.emailSubject)"
              class="help level-left is-danger"
            >
              Please fill email subject
            </p>
          </tokens>
        </div>

        <div class="field">
          <label class="label">Email message</label>
          <tokens
            v-model="$v.form.emailMessage.$model"
            class="control is-expanded"
          >
            <textarea
              v-model="$v.form.emailMessage.$model"
              :class="[
                'input',
                $style.input,
                { 'is-danger': isInvalid($v.form.emailMessage) }
              ]"
              :rows="4"
            />
            <p
              v-if="isInvalid($v.form.emailMessage)"
              class="help level-left is-danger"
            >
              Please fill email message
            </p>
          </tokens>
        </div>

        <label class="label">Recipients</label>
        <div class="field recipients">
          <recipients
            :recipients="$v.form.recipients.$each.$iter"
            :types="recipientTypes"
            @updateRecipients="updateRecipients"
            @deleteRecipient="deleteRecipient"
          >
            <template #default="{item, index, recipientTypes}">
              <recipient-item
                :item="item"
                :index="index"
                :recipient-types="recipientTypes"
              />
            </template>
          </recipients>
        </div>

        <text-button
          title="Add recipient"
          icon="plus"
          @click="addRecipient"
        />

        <advanced-settings>
          <advanced-settings-item-empty
            id="sequentialSigning"
            :item="$v.form.sequentialSigning"
            :tooltip-msg="tooltipMsg"
            label-text="Sequential signing"
          />
          <advanced-settings-item-number
            id="specifyExpiration"
            :checked="$v.form.specifyExpiration"
            label-text="Specify expiration"
            :number="$v.form.specifyExpirationCount"
            number-label-text="days"
          />
          <advanced-settings-item-number
            id="sendReminders"
            :checked="$v.form.sendReminders"
            :label-text="$v.form.sendReminders.$model ? 'Send reminders every' : 'Send reminders'"
            :number="$v.form.sendRemindersCount"
            number-label-text="days"
          />
        </advanced-settings>
      </div>

      <div
        v-else
        class="content"
      >
        <div
          class="dropdown"
          :class="{ 'is-active': dropdownActive }"
        >
          <div class="dropdown-trigger">
            <button
              class="is-white has-text-primary button"
              type="button"
              @click="dropdownActive = true"
              @blur="dropdownActive = false"
            >
              <span>Connect to DocuSign</span><span class="icon is-small">
                <font-awesome-icon :icon="[ 'fal', 'angle-down' ]" />
              </span>
            </button>
          </div>

          <div class="dropdown-menu">
            <div class="dropdown-content">
              <a
                class="dropdown-item"
                @mousedown="environmentSelectChange('Production')"
              >
                Production
              </a>
              <a
                class="dropdown-item"
                @mousedown="environmentSelectChange('Sandbox')"
              >
                Sandbox
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </step-layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { matchEmailOrTemplateOrListOfEmails } from '@/utils/validators.js';
import Tokens from '@/components/tokens.vue';
import BaseStep from '@/components/deliveries/base-delivery-step.vue';
import recipients from '@/views/documents/processes/components/delivery/recipients.vue';
import DeliveriesNames from '../deliveries-names';
import OAuthClient from '../../services/oauth-client';
import recipientItem from '../../components/delivery/recipient-item';
import advancedSettings from '../../components/delivery/advancedSettings/advanced-settings';
import advancedSettingsItemEmpty from '../../components/delivery/advancedSettings/advanced-settings-item-empty';
import advancedSettingsItemNumber from '../../components/delivery/advancedSettings/advanced-settings-item-number';
import AccountInfo from '../../components/delivery/account-info';
import textButton from '../../components/text-button.vue';
import Confirm from '@/components/modals/confirm.vue';

export default {
  name: 'DocusignDeliveryStep',
  components: {
    recipients,
    recipientItem,
    advancedSettings,
    advancedSettingsItemEmpty,
    advancedSettingsItemNumber,
    Tokens,
    AccountInfo,
    textButton,
  },
  extends: BaseStep,
  params: ['processId'],
  data() {
    return {
      dropdownActive: false,
      form: {
        id: null,
        account: null,
        folder: null,
        accessToken: null,
        emailSubject: null,
        emailMessage: null,
        recipients: [
          {
            name: '',
            email: '',
            type: 1,
            order: 1,
          },
        ],
        sequentialSigning: false,
        allowFillPdf: null,
        specifyExpiration: false,
        specifyExpirationCount: 7,
        sendReminders: false,
        sendRemindersCount: 1,
        environment: '',
      },
      tooltipMsg: 'By default recipients sign documents parallelly. Enable this option to use sequential signing. Then drag and drop recipients above to change their order',
      cacheId: null,
      oauthClient: null,

      authorize: null,
      clientId: null,
      scope: null,
      redirectUrl: null,
      logout: null,
      recipientTypes: [
        { id: 1, name: 'Needs to sign' },
        { id: 2, name: 'In person signer' },
        { id: 3, name: 'Receives a copy' },
        { id: 4, name: 'Needs to view' },
        { id: 6, name: 'Allow to edit' },
      ],
    };
  },

  watch: {
    'form.recipients': function (newVal) {
      if (newVal.length === 0) {
        this.form.recipients.push({
          name: '', email: '', type: 1, order: 1,
        });
      } else {
        for (let index = 0; index < this.form.recipients.length; index += 1) {
          this.form.recipients[index].order = index + 1;
        }
      }
    },
  },

  created() {
    this.oauthClient = new OAuthClient();
  },

  validations() {
    const defaultValidation = {
      form: {
        emailSubject: { required },
        emailMessage: { required },
        recipients: {
          $each: {
            name: { required },
            email: { matchEmailOrTemplateOrListOfEmails },
            type: {},
            order: {},
          },
        },
        sequentialSigning: {},
        allowFillPdf: {},
        specifyExpiration: {},
        specifyExpirationCount: {},
        sendReminders: {},
        sendRemindersCount: {},
        environment: {},
      },
      recipientTypes: [],
    };

    return defaultValidation;
  },

  methods: {
    async environmentSelectChange(event) {
      this.form.environment = event;

      await this.setEnvironmentSettings();

      this.signinPopup();
    },

    async setEnvironmentSettings() {
      const environmentType = this.IsProduction()
        ? DeliveriesNames.DocuSign
        : DeliveriesNames.DocuSignSandbox;

      const settings = await this.getSettings(environmentType);

      this.authorize = settings.authorizeEndpoint;
      this.clientId = settings.clientId;
      this.scope = settings.scope;
      this.redirectUrl = settings.redirectUrl;
      this.logout = settings.logoutEndpoint;
    },

    IsProduction() {
      return this.form.environment === 'Production';
    },

    async signinPopup() {
      await this.signoutPopup();

      if (!this.authorize || !this.redirectUrl) {
        await this.setEnvironmentSettings();
      }

      const environmentUrl = this.IsProduction()
        ? 'https://account.docusign.com'
        : this.authorize;

      const environmentType = this.IsProduction()
        ? DeliveriesNames.DocuSign
        : DeliveriesNames.DocuSignSandbox;

      const params = {
        prompt: 'login', client_id: this.clientId, scope: this.scope, redirect_uri: this.redirectUrl,
      };
      const accessData = await this.getAccessData(
        environmentUrl, params, environmentType, this.oauthClient, true,
      );
      if (accessData) {
        this.form.account = accessData.account;
        this.form.accessToken = accessData.accessToken;
        this.cacheId = accessData.cacheId;
      }
    },

    async signoutPopup() {
      if (!this.form.account) {
        return;
      }

      this.form.account = null;
    },

    async reconnect() {
      await this.signinPopup();
    },

    async submitForm() {
      return (await this.updateDeliverySettings(
        this.processId, { ...this.form, cacheId: this.cacheId }, DeliveriesNames.DocuSign,
      )).data;
    },

    setData() {
      this.clearRecipients();
      if (this.payload) {
        this.form = {
          ...this.payload,
          recipients: this.payload.recipients.map((value) => ({ ...value })),
        };
      }
    },

    clearRecipients() {
      if (this.$v.form.recipients.$invalid && this.form.recipients.length > 0) {
        Object.keys(this.$v.form.recipients.$each).forEach((i) => {
          if (
            this.$v.form.recipients.$each.$iter[i]
            && this.$v.form.recipients.$each.$iter[i].$invalid
          ) {
            this.form.recipients.splice(i, 1);
          }
        });

        this.$v.form.$reset();
      }
    },

    addRecipient() {
      this.form.recipients.push({
        name: '', email: '', type: 1, order: 1,
      });
    },

    deleteRecipient(index) {
      this.$modal.show(
        Confirm,
        {
          title: 'Delete recipient',
          message: 'Are you sure you want to delete this the recipient?',
          onSubmit: () => {
            this.form.recipients.splice(index, 1);
          },
          submitBtnText: 'Delete',
          showCloseBtn: true,
        },
        {
          draggable: '.modal-handler',
          clickToClose: true,
          width: '455px',
          height: 'auto',
          adaptive: true,
        },
      );
    },

    updateRecipients(newRecipients) {
      this.form.recipients = newRecipients;
    },
  },
};
</script>

<style lang="scss" module>
textarea.input {
  height: 150px;
}
</style>
