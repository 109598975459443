<template>
  <div :class="$style.fields">
    <Tokens
      v-if="item.name"
      v-model="item.name.$model"
      class="control is-expanded"
      position="is-top-left"
    >
      <input
        v-model="item.name.$model"
        class="input"
        type="text"
        :placeholder="getNamePlaceholder"
        :class="{ 'is-danger': item.name.$error }"
      >
    </Tokens>

    <Tokens
      v-if="item.email"
      v-model="item.email.$model"
      :class="[$style.fields__email, 'control is-expanded']"
      position="is-top-left"
    >
      <input
        v-model="item.email.$model"
        type="text"
        placeholder="Email"
        :class="['input', { 'is-danger': item.email.$error }]"
      >
    </Tokens>

    <div
      class="control"
    >
      <div class="select is-fullwidth">
        <select
          v-model="item.type.$model"
        >
          <option
            v-for="option in recipientTypes"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import Tokens from '@/components/tokens.vue';

export default {
  name: 'RecipientItem',
  components: {
    Tokens,
  },
  props: {
    index: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    recipientTypes: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getNamePlaceholder() {
      return this.settings?.namePlaceholder || 'Name';
    },
  },
};
</script>
<style lang="scss" module>
.fields {
  display: flex;
  gap: 8px;
  flex-grow: 1;

  &__email {
    flex: 1 1 auto;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    flex-direction: row;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
}
</style>
