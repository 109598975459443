<template>
  <div
    v-resize:debounce="onResize"
    class="navbar-start is-marginless"
  >
    <div
      ref="visibleButtons"
      class="navbar-item is-paddingless visible-buttons"
    >
      <slot />
    </div>

    <div class="navbar-item has-dropdown is-hoverable hidden-buttons">
      <a
        v-show="!allVisible"
        class="navbar-link is-arrowless"
      >
        <font-awesome-icon :icon="[ 'fal','ellipsis' ]" />
      </a>

      <div class="navbar-dropdown">
        <div ref="hiddenButtons" />
      </div>
    </div>
  </div>
</template>

<script>
import resize from 'vue-resize-directive';

export default {
  directives: {
    resize,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() { return { allVisible: true }; },

  watch: {
    loading() {
      this.check();
    },
  },

  mounted() {
    this.check();
  },

  methods: {
    onResize() {
      this.check();
    },

    check(shrink) {
      const visible = [...this.$refs.visibleButtons.children];
      const hidden = [...this.$refs.hiddenButtons.children];

      this.allVisible = hidden.length === 0;

      const reducer = (res, curr) => {
        res.totalWidth += curr.offsetWidth;
        res.breakWidths.push(res.totalWidth);

        return res;
      };

      const breaks = [...visible, ...hidden].reduce(reducer, {
        totalWidth: 0, breakWidths: [],
      }).breakWidths;

      const availableSpace = this.$refs.visibleButtons.offsetWidth;

      const t1 = breaks[visible.length - 1];
      const t2 = breaks[visible.length];

      const shrinkFunction = () => {
        const latest = visible[visible.length - 1];

        this.$refs.visibleButtons.removeChild(latest);
        this.$refs.hiddenButtons.prepend(latest);

        this.check(true);
      };

      const growFunction = () => {
        const latest = hidden[0];

        this.$refs.hiddenButtons.removeChild(latest);
        this.$refs.visibleButtons.appendChild(latest);

        this.check(false);
      };

      if (
        shrink !== undefined && shrink && t1 > availableSpace
                || shrink === undefined && t1 > availableSpace
      ) {
        shrinkFunction();
      } else if (
        shrink !== undefined && !this.allVisible && !shrink && availableSpace > t2
                || shrink === undefined && !this.allVisible && availableSpace > t2
      ) {
        growFunction();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-item {
  position: relative;
  display: flex;
  align-items: center
}

.navbar-dropdown {
  margin: 0;
  padding: 0;
}

.navbar-dropdown > div {
  position: absolute;
  right: 0;
  top: calc(100% - 2px);
  display: flex;
  flex-flow: column;
  white-space: nowrap;

  button {
    justify-content: flex-start;
  }
}

.navbar-start {
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 1%;
    display: inline-flex;
    align-items: stretch;
}

.navbar-dropdown {
    display: block;
    visibility: hidden;
}

.navbar-item.is-active .navbar-dropdown,
.navbar-item.is-hoverable:focus .navbar-dropdown,
.navbar-item.is-hoverable:focus-within .navbar-dropdown,
.navbar-item.is-hoverable:hover .navbar-dropdown {
    visibility: visible;
}

.visible-buttons {
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
}

.hidden-buttons {
    flex-shrink: 0;
}
</style>
