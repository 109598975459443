<template>
  <div class="content">
    <start-process-panel
      :side-panel-name="sidePanel"
      :process-id="processId"
      form-id="json"
    />
    <p>
      Start process using JSON object that represents data for the template.
      Properties from this object should correspond to tokens used in your template.
    </p>
    <button
      type="button"
      class="button is-primary"
      @click="openPrewiev"
    >
      Submit JSON
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useProcessesStore, useSidePanelStore } from '@/stores/index.js';

import StartProcessPanel from '@/views/documents/processes/start-process-panel/index.vue';

export default {
  name: 'StartProcessTab',

  components: { StartProcessPanel },

  data() {
    return {
      sidePanel: 'PreviewForm',
    };
  },

  computed: {
    ...mapState(useProcessesStore, ['processId']),
  },

  methods: {
    ...mapActions(useSidePanelStore, ['setPanel']),

    openPrewiev() {
      this.setPanel(this.sidePanel);
    },
  },
};
</script>
