<template>
  <div
    class="content"
    style="display: flex; flex: 1; flex-flow: column"
  >
    <p>
      Use
      <a
        href="https://zapier.com/apps/plumsail-documents/integrations"
        target="_blank"
        rel="noopener noreferrer"
      >
        Zapier
      </a>
      to integrate your process with hundreds of services. Select template
      below or find examples in
      <a
        href="https://plumsail.com/docs/documents/v1.x/getting-started/use-from-zapier.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        the documentation.
      </a>
    </p>
    <div
      v-if="loading"
      :class="$style.loader"
    >
      <spinner
        :size="35"
        :duration="1000"
      />
    </div>
    <div
      v-else
      class="columns"
    >
      <div
        id="container"
        class="column"
      >
        <zapier-app-directory
          app="plumsail-documents"
          link-target="new-tab"
          theme="light"
          :applimit.prop="0"
          :zaplimit.prop="10"
          introcopy="hide"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/spinner.vue';

export default {
  name: 'ZapierTab',
  components: { Spinner },
  data() {
    return {
      resources: [
        'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js',
        'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css',
      ],
      loading: true,
    };
  },

  async created() {
    await this.loadResources(this.resources);
    this.loading = false;
  },

  methods: {
    loadResources(resources) {
      return Promise.all(resources.map((src) => {
        if (src.endsWith('.css')) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = src;
          const cssPromise = new Promise((resolve) => {
            link.addEventListener('load', resolve);
          });
          document.head.append(link);

          return cssPromise;
        }

        const script = document.createElement('script');
        script.type = 'module';
        script.src = src;
        const scriptPromise = new Promise((resolve) => {
          script.addEventListener('load', resolve);
        });
        document.head.append(script);
        return scriptPromise;
      }));
    },
  },
};
</script>
<style lang="scss" module>
.loader {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
}
</style>
