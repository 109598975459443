var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"stepper",class:`is-${_vm.size}`},_vm._l((_vm.steps),function(step,index){return _c('li',{key:step.index,class:[`is-${_vm.size}`, {
      'is-active': step.isActive && !_vm.isStepCreating,
      'is-completed': step.isFinished && !_vm.isStepCreating,
      'is-invalid': step.isInvalid && !_vm.isStepCreating,
    }, 'step']},[_c('div',{staticClass:"level is-marginless is-clickable is-mobile",class:_vm.$style.step,on:{"click":function($event){return _vm.select(index)}}},[_c('span',{staticClass:"icon is-rounded is-bordered",class:`is-${_vm.size}`},[_c('Icon',{attrs:{"name":step.settings.propsData.icon,"width":step.settings.propsData.icon_width,"height":step.settings.propsData.icon_height,"translate":step.settings.propsData.translate || null}})],1),_c('span',{staticClass:"level-item",style:({ width: `${step.settings.propsData.name_width}px` })},[_vm._v(" "+_vm._s(step.settings.propsData.name)+" ")]),(!['ready-step', 'conversion-setup-step'].includes(step.settings.type))?_c('font-awesome-icon',{class:_vm.$style.deleteStep,attrs:{"icon":['fal', 'trash-can']},on:{"click":function($event){$event.stopPropagation();return _vm.deleteStep(step, index)}}}):_vm._e()],1),_c('transition-expand',[(step.isActive && !_vm.isStepCreating && step.settings.propsData.sub_steps)?_c('ul',{staticClass:"item sub-steps"},_vm._l((step.settings.propsData.sub_steps),function(subStep,subStepIndex){return _c('li',{key:subStepIndex,class:[
            'is-clickable', { 'has-text-weight-bold': _vm.currentSubStep === subStepIndex },
          ],staticStyle:{"font-size":"0.9rem"},on:{"click":function($event){return _vm.selectSubStep(subStepIndex)}}},[_vm._v(" "+_vm._s(subStep)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"step-content-wrapper",class:[
        `is-${_vm.size}`, { 'is-empty': index !== _vm.steps.length - 2 },
      ]},[(index === _vm.steps.length - 2)?_c('p',{staticClass:"level is-marginless is-clickable is-mobile",class:{ 'is-active': _vm.isStepCreating },on:{"click":function($event){return _vm.addStep(index)}}},[_c('span',{staticClass:"icon is-rounded is-bordered is-small",staticStyle:{"margin-left":"-2rem"}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'plus'],"size":"xs"}})],1),_c('span',{staticClass:"level-item",staticStyle:{"margin":"0","margin-left":"-0.5rem","padding-left":"2rem","line-height":"0.75rem"}},[_vm._v(" Add delivery ")])]):_vm._e()])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }