<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div
        v-if="form.account"
        class="content"
      >
        <account-info
          :name="form.account"
          @reconect="reconnect"
          @sign-out="signoutPopup"
        />

        <div class="field">
          <label class="label">Folder</label>
          <tokens
            v-model="$v.form.folder.$model"
            class="control"
            position="is-top-left"
          >
            <input
              v-model="$v.form.folder.$model"
              class="input"
              type="text"
              :class="{ 'is-danger': isInvalid($v.form.folder) }"
            >
            <p
              v-if="isInvalid($v.form.folder)"
              class="help is-danger"
            >
              Please enter a name that doesn't include any special
              (<span class="has-background-light is-family-code">" * : &lt; > ? \ |</span>)
              characters or end with "."
            </p>
          </tokens>
        </div>
      </div>

      <div
        v-else
        class="content"
      >
        <a @click="signinPopup">Connect to Google Drive</a>
      </div>
    </template>
  </step-layout>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import Tokens from '@/components/tokens.vue';
import BaseStep from '@/components/deliveries/base-delivery-step.vue';
import OAuthClient from '../../services/oauth-client';
import DeliveriesNames from '../deliveries-names';
import AccountInfo from '../../components/delivery/account-info';
import { apiEndpoint } from '@/utils/url-manager.js';

const matchFolderName = (v) => RegExp(/^$|^(?:(?:{{.*}.*}?)|(?:[^<>:?*"\\\|]))+[^\.<>:?*"\\\|]$/g).test(v);

export default {
  name: 'GoogledriveDeliveryStep',
  components: {
    Tokens,
    AccountInfo,
  },
  extends: BaseStep,
  params: ['processId'],
  data() {
    return {
      form: {
        id: null,
        account: null,
        folder: null,
        accessToken: null,
      },
      cacheId: null,
      oauthClient: null,

      authorize: null,
      clientId: null,
      scope: null,
      redirectUrl: null,
      logout: null,
    };
  },

  created() {
    this.oauthClient = new OAuthClient();
  },

  validations() {
    const defaultValidation = {
      form: {
        account: { required },
        folder: { matchFolderName },
      },
    };

    return defaultValidation;
  },

  async mounted() {
    const endpoint = apiEndpoint;

    const settings = (await axios.get(`${endpoint}auth/providers/${DeliveriesNames.GoogleDrive}`)).data;

    this.authorize = settings.authorizeEndpoint;
    this.clientId = settings.clientId;
    this.scope = settings.scope;
    this.redirectUrl = settings.redirectUrl;
    this.logout = settings.logoutEndpoint;
  },

  methods: {
    async signinPopup() {
      await this.signoutPopup();

      const params = {
        prompt: 'consent', access_type: 'offline', client_id: this.clientId, scope: this.scope, redirect_uri: this.redirectUrl,
      };
      const accessData = await this.getAccessData(
        this.authorize, params, DeliveriesNames.GoogleDrive, this.oauthClient, true,
      );
      if (accessData) {
        this.form.account = accessData.account;
        this.form.accessToken = accessData.accessToken;
        this.cacheId = accessData.cacheId;
      }
    },

    async signoutPopup() {
      const endpoint = apiEndpoint;

      if (!this.form.account) {
        return;
      }

      let token = this.form.accessToken;

      if (this.form.id) {
        token = (await axios.get(`${endpoint}auth/access?processId=${this.processId}&deliveryId=${this.form.id}`)).data;
      }

      const instance = axios.create({
        headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      });

      await instance.post(`https://oauth2.googleapis.com/revoke?token=${token}`);

      this.form.account = null;
    },

    async reconnect() {
      await this.signinPopup();
    },
    async submitForm() {
      return (await this.updateDeliverySettings(
        this.processId, { ...this.form, cacheId: this.cacheId }, DeliveriesNames.GoogleDrive,
      )).data;
    },
    setData() {
      if (this.payload) {
        this.form.id = this.payload.id;
        this.form.account = this.payload.account;
        this.form.folder = this.payload.folder;
      }
    },
  },
};
</script>