<template>
  <div style="display: flex; flex-flow: column; height: 100%">
    <div
      :class="[classes, $style.tabs]"
    >
      <ul>
        <li
          v-for="tab in tabs"
          :key="tab"
          :class="{'is-active': activeTab === tab}"
          @click="switchTab(tab)"
        >
          <a><slot :name="tabHeadSlotName(tab)">{{ tab }}</slot></a>
        </li>
      </ul>
    </div>
    <slot :name="tabPanelSlotName" />
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    classes: { default: 'tabs' },
    tabs: Array,
    initialTab: { default: null },
  },
  data() {
    return {
      activeTab: null,
    };
  },
  computed: {
    tabPanelSlotName() {
      return `tab-panel-${this.activeTab}`;
    },
  },
  watch: {
    activeTab(newTab) {
      this.$emit('tabChanged', newTab);
    },
  },
  mounted() {
    this.activeTab = this.initialTab || this.tabs[0];
  },
  methods: {
    tabHeadSlotName(tabName) {
      return `tab-head-${tabName}`;
    },

    switchTab(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>

<style lang="scss" module>
.tabs {
  flex-shrink: 0;

  &::-webkit-scrollbar {
  width: 10px;
  height: 10px;;
  }

  &::-webkit-scrollbar-track {
  background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
  border: 3px solid #fff;
  border-radius: 10px;
  background-color: #C0C0C0;
  }
}
</style>
