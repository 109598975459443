<template>
  <step-layout
    :name="headerName"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div
        v-if="form.account"
        class="content"
      >
        <account-info
          :name="form.account"
          @reconect="reconnect"
          @sign-out="signoutPopup"
        />

        <div class="field">
          <label for="Recipient" class="label">To</label>
          <tokens
            v-model="$v.form.to.$model"
            class="control"
          >
            <tags-input
              v-model="$v.form.to.$model"
              input-id="Recipient"
              placeholder="Add new recipient"
              :class="{ 'is-danger': isInvalid($v.form.to) }"
              add-tags-on-comma="add-tags-on-comma"
              add-tags-on-blur="add-tags-on-blur"
            />
            <p
              v-if="isInvalid($v.form.to) && !$v.form.to.required"
              class="help is-danger"
            >
              This field must be filled
            </p>
            <p
              v-if="isInvalid($v.form.to) && $v.form.to.required && !$v.form.to.emails"
              class="help is-danger"
            >
              Please check that each entry is a valid email
            </p>
          </tokens>
        </div>

        <div class="field">
          <label for="Subject" class="label">Subject</label>
          <tokens
            v-model="$v.form.subject.$model"
            class="control"
          >
            <input
              id="Subject"
              v-model="$v.form.subject.$model"
              class="input"
              type="text"
              :class="{ 'is-danger': isInvalid($v.form.subject) }"
            >
            <p
              v-if="isInvalid($v.form.subject) && !$v.form.subject.required"
              class="help is-danger"
            >
              This field must be filled
            </p>
          </tokens>
        </div>
        <email-editor
          v-model="$v.form.content.$model"
          one-line="oneLine"
        />
        <p
          v-if="isInvalid($v.form.content) && !$v.form.content.required"
          class="help is-danger"
        >
          The content must be specified
        </p>
        <advanced-settings :is-visible="isAdvanceVisible">
          <div class="field">
            <label for="DisplayName" class="label">Display name</label>
            <tokens
              v-model="$v.form.displayName.$model"
              class="control"
              position="is-top-left"
            >
              <input
                id="DisplayName"
                v-model="$v.form.displayName.$model"
                class="input"
                type="text"
                :placeholder="form.accountName"
              >
            </tokens>
          </div>
          <div class="field">
            <label for="Reply" class="label">Reply-to address</label>
            <tokens
              v-model="$v.form.replyTo.$model"
              class="control"
              position="is-top-left"
            >
              <input
                id="Reply"
                v-model="$v.form.replyTo.$model"
                class="input"
                type="text"
                :placeholder="form.account"
              >
            </tokens>
          </div>
        </advanced-settings>
      </div>

      <div
        v-else
        class="content"
      >
        <button
          type="button"
          :class="$style.linkButton"
          @click="signinPopup"
        >
          Connect to Zoho Mail
        </button>
      </div>
    </template>
  </step-layout>
</template>

<script>
import { required, email, helpers } from 'vuelidate/lib/validators';
import TagsInput from '@voerro/vue-tagsinput';
import EmailEditor from '@/components/rich-text-editor/index.vue';
import Tokens from '@/components/tokens.vue';
import BaseStep from '@/components/deliveries/base-delivery-step.vue';
import advancedSettings from '../../components/delivery/advancedSettings/advanced-settings.vue';
import AccountInfo from '../../components/delivery/account-info.vue';
import DeliveriesNames from '../deliveries-names';
import OAuthClient from '../../services/oauth-client';
import zohoAccessDataMixin from '../../mixins/zoho-access-data-mixin';

const token = helpers.regex('token', /{{.*}}/);

export default {
  name: 'ZohomailDeliveryStep',
  components: {
    EmailEditor,
    TagsInput,
    advancedSettings,
    Tokens,
    AccountInfo,
  },
  extends: BaseStep,
  params: ['processId'],
  mixins: [zohoAccessDataMixin],
  data() {
    return {
      form: {
        id: null,
        account: null,
        accessToken: null,
        to: [],
        subject: null,
        content: null,
        replyTo: null,
        displayName: null,
        accountName: null,
        location: null,
      },
      cacheId: null,
      oauthClient: null,

      authorize: null,
      clientId: null,
      scope: null,
      redirectUrl: null,
      logout: null,
      isAdvanceVisible: false,
    };
  },

  validations() {
    const defaultValidation = {
      form: {
        to: {
          required,
          $each: {
            emails(entry) {
              return email(entry.value) || token(entry.value);
            },
          },
        },
        subject: { required },
        content: { required },
        replyTo: {},
        displayName: {},
      },
    };

    return defaultValidation;
  },

  watch: {
    'form.displayName': {
      handler(value) {
        if (value && value.value !== '') {
          this.isAdvanceVisible = true;
        }
      },
    },

    '$v.form.to.$model': {
      handler(value, previos) {
        if (value.value === previos.value) {
          this.$v.form.to.$reset();
        }
      },
    },
  },

  created() {
    this.oauthClient = new OAuthClient();
  },

  async mounted() {
    const settings = await this.getSettings(DeliveriesNames.ZohoMail);

    this.authorize = settings.authorizeEndpoint;
    this.clientId = settings.clientId;
    this.scope = settings.scope;
    this.redirectUrl = settings.redirectUrl;
    this.logout = settings.logoutEndpoint;
  },

  methods: {
    setData() {
      this.initialized = true;

      if (this.payload) {
        this.form.account = this.payload.account;
        this.form.id = this.payload.id;

        this.form.to = this.payload.to ? this.payload.to.split(',').map((value) => ({
          key: '',
          value,
        })) : [];

        this.form.subject = this.payload.subject;
        this.form.content = this.payload.content;

        this.form.replyTo = this.payload.replyTo;
        this.form.displayName = this.payload.displayName;
        this.form.location = this.payload.location;

        if (!!this.form.displayName || !!this.form.replyTo) {
          this.isAdvanceVisible = true;
        }
      }
    },

    async signinPopup() {
      await this.signoutPopup();
      const params = {
        prompt: 'consent', access_type: 'offline', client_id: this.clientId, scope: this.scope, redirect_uri: this.redirectUrl,
      };

      const accessData = await this.getZohoAccessData(
        this.authorize,
        params,
        DeliveriesNames.ZohoMail,
        this.oauthClient,
        true,
        'mail',
      );

      if (accessData) {
        this.form.account = accessData.account;
        this.form.accountName = accessData.name;
        this.form.location = accessData.location;
        this.cacheId = accessData.cacheId;
      }
    },

    async signoutPopup() {
      if (!this.form.account) {
        return;
      }
      /**
       * todo signout
       * post https://accounts.zoho.com/oauth/v2/token/revoke?token={refresh_token}
       * note: If the refresh token is invalid, the revoke token request
       *       will not be executed and you will receive an HTTP status code 400.
       */
      this.form.account = null;
    },

    async submitForm() {
      const dto = {
        ...this.form,
        to: this.form.to.map((r) => r.value).join(','),
      };

      const { data } = await this.updateDeliverySettings(
        this.processId,
        { ...dto, cacheId: this.cacheId },
        DeliveriesNames.ZohoMail,
      );
      return data;
    },

    async reconnect() {
      await this.signinPopup();
    },
  },
};
</script>
<style lang="scss" module>
.linkButton {
  margin: 0;
  padding: 0;
  border: none;
  font-size: inherit;
  color: var(--current-color);
  background-color: transparent;
  cursor: pointer;
}
</style>
