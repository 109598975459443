<template>
  <div>
    <div class="content">
      <p>
        Start the process by sending POST web request to the URL below. Review
        <a
          href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/start-process-rest-api.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          the documentation
        </a> for more examples.
      </p>
      <p class="subtitle">
        POST request example
      </p>
    </div>
    <tabs
      :tabs="scripts"
      initial-tab="curl"
      classes="tabbed"
    >
      <template slot="tab-head-powershell">
        PowerShell
      </template>
      <template slot="tab-panel-powershell">
        <pre
          class="message"
          style="white-space: pre-wrap"
        ><a
          ref="test1"
          style="float: right"
          @click="copyRequest"
        ><font-awesome-icon :icon="['fal', 'copy']" /></a><span>{{ powershell }}</span></pre>
      </template>
      <template slot="tab-head-curl">
        cURL
      </template>
      <template slot="tab-panel-curl">
        <pre
          class="message"
          style="white-space: pre-wrap"
        ><a
          ref="test2"
          style="float: right"
          @click="copyRequest"
        ><font-awesome-icon :icon="['fal', 'copy']" /></a><span>{{ curl }}</span></pre>
      </template>
    </tabs>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useProcessesStore, useTemplatesStore } from '@/stores/index.js';
import TabsComponent from '@/components/tabs.vue';
import { curlScript, powerShellScript } from '../script';
import { openNotification } from '@/components/notification-service.js';
import { workersApiEndpoint } from '@/utils/url-manager.js';

String.prototype.format = function (placeholders) {
  let s = this;
  for (const propertyName in placeholders) {
    const re = new RegExp(`{${propertyName}}`, 'gm');
    s = s.replace(re, placeholders[propertyName]);
  }
  return s;
};

export default {
  name: 'RestTab',

  components: {
    tabs: TabsComponent,
  },
  props: {
    processId: { type: String, required: true },
  },

  data() {
    return {
      scripts: ['curl', 'powershell'],
      curl: null,
      powershell: null,
    };
  },
  computed: {
    ...mapState(useTemplatesStore, ['testTemplate']),
    ...mapState(useProcessesStore, ['shortUserId']),
  },

  async mounted() {
    if (!this.shortUserId) await this.updateShortId();
    const url = `${workersApiEndpoint}v2/processes/${this.shortUserId}/${this.processId}/start`;

    this.curl = curlScript.format({ json: this.testTemplate, url });
    this.powershell = powerShellScript.format({ json: this.testTemplate, url });
  },

  methods: {
    ...mapActions(useProcessesStore, { updateShortId: 'updateShortUserId' }),

    checkValidity() {
      return this.onValidityChecked({ errors: false });
    },

    copyRequest() {
      const $temp = document.createElement('textarea');
      $temp.innerHTML = this.$refs.test1 ? this.powershell : this.curl;
      document.body.appendChild($temp);
      $temp.select();
      document.execCommand('copy');
      document.body.removeChild($temp);

      openNotification({
        message: 'copied',
        type: 'notification',
        duration: 1000,
      });
    },
  },
};
</script>

<style lang="scss">
$border-size: 24px;

.tabbed {
  overflow: hidden;
  transition: border 250ms ease;

  & ul {
    float: left;
    padding-left: $border-size;

    & li {
        float: left;
        padding: 10px calc(($border-size / 2) + 2px) 8px;
        background-color: whitesmoke;
        margin-right: $border-size;
        z-index: 2;
        position: relative;
        cursor: pointer;
        transition: all 250ms ease;

        &:before, &:after {
            content: " ";
            position: absolute;
            top: 0;
            height: 100%;
            width: $border-size;
            background-color: whitesmoke;
            transition: all 250ms ease;
        }

        &:before {
            right: calc($border-size / -2);
            transform: skew(30deg, 0deg);
            box-shadow: rgba(0,0,0,.1) 3px 2px 5px, inset rgba(255,255,255,.09) -1px 0;
        }

        &:after {
            left: calc($border-size / -2);
            transform: skew(-30deg, 0deg);
            box-shadow: rgba(0,0,0,.1) -3px 2px 5px, inset rgba(255,255,255,.09) 1px 0;
        }

        &:hover, &:hover:before, &:hover:after {
            background-color: hsl(0, 0%, 85%);
        }

        &.is-active {
            z-index: 3;
        }

        &.is-active, &.is-active:before, &.is-active:after {
            background-color: hsl(0, 0%, 90%);
        }
    }
  }
}
</style>
