<template>
  <div class="content">
    <p>
      Paste here the JSON object that represents data for the template.
      Properties from this object should correspond to tokens used in your template.
    </p>

    <slot name="messages" />

    <JsonViewer
      v-model="$v.form.json.$model"
      placeholder="Place your JSON content here"
    />

    <p
      v-if="$v.form.json.$error && !$v.form.json.json && $v.form.json.required"
      class="help is-danger"
    >
      Please check your JSON input. It seems to be invalid
    </p>

    <p
      v-if="$v.form.json.$error && !$v.form.json.required"
      class="help is-danger"
    >
      The JSON input is missing
    </p>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useProcessesStore, useTemplatesStore } from '@/stores/index.js';
import { required, jsonValidator } from '@/utils/validators.js';
import JsonViewer from '@/components/json-viewer/index.vue';

export default {
  components: {
    JsonViewer,
  },
  props: {
    actionIndicator: { type: [String, null], default: null },
    googleDriveId: { type: String, default: null },
    htmlSource: { type: String, default: null },
    fileId: { type: String, default: null },
  },

  data() {
    return {
      form: {
        json: '',
        createMode: '',
      },
    };
  },
  computed: {
    ...mapState(useProcessesStore, ['processTestingMode', 'processId']),
    ...mapWritableState(useTemplatesStore, ['testTemplate']),
  },
  validations: {
    form: {
      json: { required, jsonValidator },
    },
  },
  watch: {
    actionIndicator(value) {
      if (value === 'create') this.create();
      if (value === 'format') this.formatInput();
      if (value === 'cancel') this.$emit('canceledTesting');
      this.$emit('update:actionIndicator', null);
    },
  },
  created() {
    this.form.json = this.testTemplate;
    this.form.createMode = this.processTestingMode;
  },
  methods: {
    ...mapActions(useTemplatesStore, ['createTestTemplate']),
    formatInput() {
      this.$v.form.json.$touch();

      if (!this.$v.form.json.jsonValidator) {
        return;
      }

      const string = JSON.parse(this.form.json);
      this.form.json = JSON.stringify(string, null, 2);
    },
    async create() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      const templateData = {
        googleDriveId: this.googleDriveId,
        htmlSource: this.htmlSource,
        json: this.$v.form.json.$model,
        createMode: this.processTestingMode,
      };

      this.$emit('startTesting');

      const { data, error } = await this.createTestTemplate({
        processId: this.processId,
        templateData,
      });

      if (data) {
        this.testTemplate = this.form.json;
        this.$emit('succeedTesting', data.link);
      } else {
        this.$emit('failedTesting', error?.message || 'Process is invalid');
      }
    },
  },
};
</script>
