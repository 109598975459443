<template>
  <step-layout
    :name="name"
    :form-id="form.id"
    :full-description="fullDescription"
    @cancel="cancel"
    @delete-step="deleteStep"
    @submit="submit"
    @save="save"
  >
    <template #content>
      <div class="content">
        <div v-if="form.team">
          <account-info
            :name="form.team"
            @reconect="reconnect"
            @sign-out="signoutPopup"
          />

          <div class="field">
            <label class="label">Channels</label>
            <Tokens
              v-model="$v.form.channels.$model"
              class="control"
              position="is-top-left"
            >
              <input
                v-model="$v.form.channels.$model"
                class="input"
                type="text"
                :class="{ 'is-danger': isInvalid($v.form.channels) }"
              >
            </Tokens>
          </div>

          <div class="field">
            <label class="label">Message</label>
            <Tokens
              v-model="$v.form.message.$model"
              class="control"
              position="is-top-left"
            >
              <textarea
                v-model="$v.form.message.$model"
                class="textarea"
              />
            </Tokens>
          </div>
        </div>
        <div
          v-else
          class="content"
        >
          <a @click="signinPopup">Connect to Slack</a>
        </div>
      </div>
    </template>
  </step-layout>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import Tokens from '@/components/tokens.vue';
import BaseStep from '@/components/deliveries/base-delivery-step.vue';
import OAuthClient from '../../services/oauth-client';
import DeliveriesNames from '../deliveries-names';
import AccountInfo from '../../components/delivery/account-info';
import { apiEndpoint } from '@/utils/url-manager.js';

export default {
  name: 'SlackDeliveryStep',
  components: { Tokens, AccountInfo },
  extends: BaseStep,
  params: ['processId'],
  data() {
    return {
      form: {
        id: null,
        team: null,
        channels: null,
        message: null,
      },
      cacheId: null,
      oauthClient: null,

      authorize: null,
      clientId: null,
      scope: null,
      redirectUrl: null,
    };
  },

  created() {
    this.oauthClient = new OAuthClient();
  },

  validations() {
    return {
      form: {
        team: { },
        channels: { required },
        message: {},
      },
    };
  },

  async mounted() {
    const endpoint = apiEndpoint;

    const settings = (await axios.get(`${endpoint}auth/providers/${DeliveriesNames.Slack}`)).data;

    this.authorize = settings.authorizeEndpoint;
    this.clientId = settings.clientId;
    this.scope = settings.scope;
    this.redirectUrl = settings.redirectUrl;
  },

  methods: {
    async signinPopup() {
      await this.signoutPopup();

      const params = {
        prompt: 'login', client_id: this.clientId, redirect_uri: this.redirectUrl, scope: this.scope,
      };
      const accessData = await this.getAccessData(this.authorize, params, DeliveriesNames.Slack, this.oauthClient, true);
      if (accessData) {
        this.form.team = accessData.name;
        this.cacheId = accessData.cacheId;
      }
    },

    async signoutPopup() {
      if (!this.form.team) {
        return;
      }
      this.form.team = null;
      let params = this.cacheId ? `cacheId=${this.cacheId}` : '';
      if (params !== '' && this.form.id) {
        params = `${params}&deliveryId=${this.form.id}`;
      } else if (this.form.id) {
        params = `deliveryId=${this.form.id}`;
      }

      await this.$http.get(`${apiEndpoint}documents/processes/slack/logout/${this.processId}?${params}`);
    },

    async reconnect() {
      await this.signinPopup();
    },

    async submitForm() {
      return (await this.updateDeliverySettings(this.processId, { ...this.form, cacheId: this.cacheId }, DeliveriesNames.Slack)).data;
    },

    setData() {
      if (this.payload) {
        this.form.id = this.payload.id;
        this.form.team = this.payload.team;
        this.form.channels = this.payload.channels;
        this.form.message = this.payload.message;
      }
    },
  },
};
</script>