<template>
  <div
    v-if="fileId"
    :class="$style.editor"
  >
    <vue-friendly-iframe
      v-if="mode === 'editor'"
      :class="{ [$style.fullscreen]: isFullScreen }"
      :src="editorUrl"
      @iframe-load="$emit('loading', false)"
    />

    <vue-friendly-iframe
      v-if="mode === 'preview' || mode === 'initial'"
      :src="previewUrl"
      @iframe-load="$emit('loading', false)"
    />
  </div>
</template>

<script>
export default {
  name: 'GoogleDriveEditor',
  props: {
    type: {
      type: String,
      default: '',
    },
    fileId: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: '',
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    previewUrl() {
      if (this.fileId) return `https://docs.google.com/file/d/${this.fileId}/preview`;

      return '';
    },
    editorUrl() {
      const editor = ({
        DOCX: 'document',
        PPTX: 'presentation',
        XLSX: 'spreadsheets',
      })[this.type];

      if (this.fileId) return `https://docs.google.com/${editor}/d/${this.fileId}/edit`;

      return '';
    },
  },
};
</script>

<style lang="scss" module>
.editor {
  :global(.vue-friendly-iframe) {
    height: 100%;
  }

  iframe {
    // need to overwrite inline styles from vue-friendly-iframe
    // stylelint-disable-next-line declaration-no-important
    border: 1px solid #d3d3d3 !important;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
  }
}

  .fullscreen {
    line-height: 0;

    * {
      line-height: 1.5;
    }
  }
</style>
